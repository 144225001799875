import React from 'react'
import Portal from '../../../../component/portal'
import Login from '../../../../component/login'
import { i18n } from '../../../../common/util'
import { AppContext } from '../../../../config/context'
import { MName } from '../../../../config'

class HomePanel extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            showLoginModal: false
        }
    }

    showLoginModal = () => {
        this.setState({
            showLoginModal: true
        })
    }

    closeLoginModal = () => {
        this.setState({
            showLoginModal: false
        })
    }

    render() {
        const { isLogin, displayName, lang } = this.context.user
        const { showLoginModal } = this.state
        return <div className={isLogin === 1 ? 'home-panel-container login' : 'home-panel-container'}>
            <div className="home-top-avatar"></div>
            {
                isLogin === 1
                    ? <p className="home-top-tip login">{i18n('Hi，' + displayName, lang)}</p>
                    : <p className="home-top-tip">{i18n('Hi，欢迎来到' + MName, lang)}</p>
            }
            {
                isLogin === 1
                    ? <div className='home-login-tip'>{i18n('更多精彩游戏，尽在' + MName, lang)}</div>
                    : <button className="home-top-button" onClick={this.showLoginModal}>登录/注册</button>
            }
            {
                showLoginModal && <Login close={this.closeLoginModal}></Login>
            }
        </div>
    }
}

HomePanel.contextType = AppContext
export default Portal(HomePanel, 'home-panel-container')