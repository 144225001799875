import { DomainPostfixWithDot } from '../../config'

const envOnload = () => {
    if (window.ST && window.ST.webTJ) {
        window._sr = function (action, category, label, value, element_id, event) {
            const event_property = {
                action,
                category,
                label,
                value: value
                    ? typeof value === 'string'
                        ? value
                        : Array.isArray(value)
                            ? JSON.stringify(value)
                            : JSON.stringify([value])
                    : '',
                element_id: element_id || ''
            }
            // if (action === 'click') {
            ST.webTJ.reportEvent(event_property, event && event.nativeEvent ? event.nativeEvent : event)
            // } else {
            //     ST.webTJ.customEvent(action, event_property)
            // }
        }
        const queue = window._srq
        if (queue.length) {
            queue.forEach(e => {
                _sr(e.action, e.category, e.label, e.value, e.element_id, e.event)
            })
            queue.length = 0
        }
    } else {
        console.error('[Error]: Data ss module load failed.')
    }
}

const initReportEnv = page_name => {
    window.century_setting = {
        product_id: 350005,
        os_type: 1,
        web_version: 'v1.0.0',
        channel_id: function getChannelId() {
            return localStorage.getItem('_gcck')
        },
        pid: function getPid() {
            return localStorage.getItem('_gcck')
        },
        uid: function getUid() {
            return localStorage.getItem('_gick') || ''
        },
        extend_fields: function getExtField() {
            return encodeURIComponent(JSON.stringify({
                website: location.host.indexOf(DomainPostfixWithDot) > 0 ? '香港' : '大陆',
                uid: localStorage.getItem('_guck') || '',
                ...location.pathname === '/pubg' && { product_type: 'pubg官方盛典活动' }
            }))
        },
        page_name
    }

    if (document.readyState === 'complete') {
        return envOnload()
    }

    const original = document.onreadystatechange
    document.onreadystatechange = function onreadystatechange() {
        original && original()
        if (document.readyState === 'complete') {
            envOnload()
            document.onreadystatechange = null
        }
    }
}

export default initReportEnv