import { httpGet } from '../../common/util'

export const getBagRecord = (type, pageNum, pageSize) => {
    return httpGet({
        url: '/user/coalbattery/record/page',
        data: {
            type,
            pageNum,
            pageSize
        },
        auth: true
    })
}

export const getPocketItems = (pageNum, pageSize, params) => {
    return httpGet({
        url: '/order/bag/list',
        data: {
            pageNum,
            pageSize,
            ...params
        },
        auth: true
    })
}

export const getPocketRecord = (pageNum, pageSize, type) => {
    return httpGet({
        url: '/order/bag/record',
        data: {
            pageNum,
            pageSize,
            type
        },
        auth: true
    })
}

export const getPocketInfo = () => {
    return httpGet({
        url: '/order/bag/statisticsRecycleBags',
        auth: true
    })
}

export const getPocketDetail = subOrderId => {
    return httpGet({
        url: '/order/bag/detail',
        data: {
            subOrderId
        },
        auth: true
    })
}

export const getDicByName = protocolNo => {
    return httpGet({
        url: '/goods/dict/detail',
        data: {
            protocolNo
        }
    })
}
