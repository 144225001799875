import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, throttle, getImgUrl, getLevelClassName, getLevelDisplayName, hbsBoolean } from '../../common/util'
import { getPocketItems } from './service'
import ee, { EVENT_TIP } from '../../common/util/event'
import Recycle from '../recycle'
import RecycleHelp from './recycleHelp'
import './pocket.scss'
import './pocketDetail.scss'
import './pocketRecycle.scss'

const PageSize = 20
const Tabs = [
    { name: '全部', value: 0 },
    { name: '神话', value: 5 },
    { name: '传说', value: 4 },
    { name: '史诗', value: 3 },
    { name: '精品', value: 2 },
    { name: '普通', value: 1 }
]
const MaxSelectNum = 50
const getQueryParams = tabIndex => {
    const ret = { bagStatus: 2, recycleBag: 1 }
    const { value } = Tabs[tabIndex]
    if (value) {
        ret.borderColors = [value]
    }
    return ret
}
const ScrollerELId = 'pocket-recycle-scroller'

class PocketRecycle extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tabIndex: 0,
            page: 1,
            list: [],
            selected: {},
            selectedCount: 0,
            selectedAll: false,
            ready: false,
            totalBattery: '0.00',
            selectedList: [],
            showConfirmModal: false,
            showRecycleHelp: false
        }
        this.hasMore = false
        this.loadFlag = false
    }

    componentDidMount() {
        this.initRecycleList(this.state.tabIndex)
    }

    initRecycleList = tabIndex => {
        getPocketItems(1, PageSize, getQueryParams(tabIndex)).then(({ pages, records }) => {
            const dataExist = Array.isArray(records) && records.length
            this.setState({
                page: 1,
                list: dataExist ? records : [],
                ready: true
            }, () => {
                this.hasMoreEffect(pages > 1)
                dataExist && (document.getElementById(ScrollerELId).scrollTop = 0)
            })
        }).catch(e => {
            if (e.code !== 401) {
                this.setState({
                    page: 1,
                    list: [],
                    ready: true
                })
                this.hasMoreEffect(false)
                ee.emit(EVENT_TIP, e.message)
            }
        })
    }

    hasMoreEffect = hasMore => {
        if (hasMore !== this.hasMore) {
            this.hasMore = hasMore
            const el = document.getElementById(ScrollerELId)
            hasMore
                ? el.addEventListener('scroll', this.scrollListener)
                : el.removeEventListener('scroll', this.scrollListener)
        }
    }

    loadMoreData = () => {
        if (!this.hasMore || this.loadFlag) {
            return
        }
        this.loadFlag = true
        const { tabIndex, page, list } = this.state
        getPocketItems(page + 1, PageSize, getQueryParams(tabIndex)).then(({ pages, records }) => {
            this.setState({
                page: page + 1,
                list: list.concat(records)
            }, () => {
                this.loadFlag = false
            })
            this.hasMoreEffect(pages > page + 1)
        })
    }

    scrollListener = throttle(() => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById(ScrollerELId)
        if (scrollTop + clientHeight >= scrollHeight) {
            this.loadMoreData()
        }
    })

    chooseTab = (tabIndex, action, e) => {
        if (tabIndex !== this.state.tabIndex) {
            this.setState({
                tabIndex
            })
            this.initRecycleList(tabIndex)
            this.unselectAll()
        }
        _sr(action, '个人中心-物品回收', `1-${tabIndex + 1}`, '', `recycle-tab-${tabIndex + 1}`, e)
    }

    toggleSelect = (detailId, recycleBattery) => {
        const { selected, selectedCount, list, totalBattery } = this.state
        const currentState = !!selected[detailId]
        if (!currentState && selectedCount + 1 > MaxSelectNum) {
            return ee.emit(EVENT_TIP, `单次最多可选${MaxSelectNum}件`)
        }
        this.setState({
            selected: {
                ...selected,
                [detailId]: !currentState
            },
            selectedCount: currentState ? selectedCount - 1 : selectedCount + 1,
            selectedAll: !currentState && selectedCount + 1 === list.length,
            totalBattery: (currentState ? Number(totalBattery) - recycleBattery : Number(totalBattery) + recycleBattery).toFixed(2)
        })
    }

    unselectAll = () => {
        this.setState({
            selected: {},
            selectedCount: 0,
            selectedAll: false,
            totalBattery: '0.00'
        })
    }

    unselectAllByUser = e => {
        this.unselectAll()
        _sr('取消全选', '个人中心-物品回收', '2-2', '', 'recycle-cancel-all', e)
    }

    selectAll = e => {
        const { list } = this.state
        const selected = {}
        const selectedCount = Math.min(list.length, MaxSelectNum)
        let totalBattery = 0
        for (let i = 0; i < selectedCount; ++i) {
            let item = list[i]
            selected[item.subOrderId] = true
            totalBattery += item.recycleBattery
        }
        this.setState({
            selected,
            selectedCount,
            selectedAll: true,
            totalBattery: totalBattery.toFixed(2)
        })
        _sr('全选', '个人中心-物品回收', '2-1', '', 'recycle-select-all', e)
    }

    submit = e => {
        let reportList = []
        if (!this.state.selectedCount) {
            ee.emit(EVENT_TIP, '请先选择不想要的物品')
        } else {
            const { selected, list } = this.state
            const selectedList = list.filter(i => !!selected[i.subOrderId])
            this.setState({
                selectedList,
                showConfirmModal: true
            })
            reportList = selectedList.map(i => ({ goodsId: i.goodsId, goodsName: i.name, productType: i.productType }))
        }
        _sr('确认回收', '个人中心-物品回收', '2-3', reportList, 'recycle-select-submit', e)
    }

    recycleSubmit = () => {
        this.unselectAll()
        this.initRecycleList(this.state.tabIndex)
    }

    closeConfirmModal = () => {
        this.setState({
            showConfirmModal: false
        })
    }

    showRecycleHelp = () => {
        this.setState({
            showRecycleHelp: true
        })
    }

    closeRecycleHelp = () => {
        this.setState({
            showRecycleHelp: false
        })
    }

    render() {
        const { tabIndex, list, selected, ready, selectedCount, selectedAll, totalBattery, selectedList, showConfirmModal, showRecycleHelp } = this.state
        const { lang } = this.context.user
        return <div className='bag-pocket-container'>
            <div className='bag-pocket-wrapper'>
                <ul className='bag-content-tabs'>
                    {
                        Tabs.map((tab, index) => (<li className={tabIndex === index ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(index, tab.name, e)}>{i18n(tab.name, lang)}</li>))
                    }
                </ul>
                <div className={ready && list.length > 0 ? 'bag-pocket-items-window' : 'bag-pocket-items-window hidden'} id={ScrollerELId}>
                    <ul className='bag-pocket-items-ul'>
                        {
                            list.map(item => (<li className='bag-pocket-item' key={item.subOrderId} onClick={() => this.toggleSelect(item.subOrderId, item.recycleBattery)}>
                                <div className='bag-pocket-item-upper'>
                                    {
                                        hbsBoolean(item.pcMhImgUrl) && <img alt='' className='bag-pocket-item-img' src={getImgUrl(item.pcMhImgUrl)}></img>
                                    }
                                    <div className='bag-pocket-item-value'>&yen;{item.primePrice}</div>
                                    <div className={`bag-pocket-item-level ${getLevelClassName(item.borderColor)}`}>{i18n(getLevelDisplayName(item.borderColor), lang)}</div>
                                </div>
                                <div className='bag-pocket-item-lower'>
                                    <p className='bag-pocket-item-name'>{i18n(item.name, lang)}</p>
                                    <p className='bag-pocket-item-status-desc enable'>{i18n(item.recycleDate ? item.recycleDate.split(' ')[0] + ' 前可回收' : '', lang)}</p>
                                </div>
                                {
                                    selected[item.subOrderId]
                                        ? <div className='bag-pocket-item-status-wrapper'>
                                            <div className='bag-pocket-item-status battery'>
                                                <i className='status-icon'></i>
                                                {item.recycleBattery}
                                            </div>
                                        </div>
                                        : <div className='bag-pocket-item-status-wrapper'>
                                            <div className='bag-pocket-item-status recycle'>
                                                <i className='status-icon'></i>
                                                {i18n('可回收', lang)}
                                            </div>
                                        </div>
                                }
                                {
                                    !!selected[item.subOrderId] && <i className='selected-flag'></i>
                                }
                            </li>))
                        }
                    </ul>
                </div>
                <div className={ready && list.length === 0 ? 'bag-empty-wrapper pocket' : 'bag-empty-wrapper pocket hidden'}>
                    <i className='empty-icon'></i>
                    <p className='empty-txt'>{i18n('这里没有物品~', lang)}</p>
                </div>
            </div>
            <div className='bag-content-footer'>
                <button className='bag-content-footer-btn recycle' onClick={this.submit}>{i18n('确认回收', lang)}</button>
                <div className='recycle-select-all-wrapper'>
                    {
                        selectedAll
                            ? <div className='recycle-select-all cancel' onClick={this.unselectAllByUser}>
                                <i className='select-icon'></i>
                                <span className='select-txt'>{i18n('取消全选', lang)}</span>
                            </div>
                            : <div className='recycle-select-all' onClick={this.selectAll}>
                                <i className='select-icon'></i>
                                <span className='select-txt'>{i18n('全选', lang)}</span>
                            </div>
                    }
                </div>
                <p className='bag-content-footer-info'>
                    {i18n('已选物品数量', lang)}
                    <span className='highlight' style={{ marginLeft: '8px' }}>{selectedCount}</span>
                    <span className='split'>|</span>
                    {i18n('可获得电池数量', lang)}
                    <i className='battery-icon'></i>
                    <span className='highlight'>{totalBattery}</span>
                    <i className='recycle-help-icon' onClick={this.showRecycleHelp}></i>
                </p>
            </div>
            {
                showConfirmModal && <Recycle close={this.closeConfirmModal} selectedList={selectedList} submit={this.recycleSubmit}></Recycle>
            }
            {
                showRecycleHelp && <RecycleHelp close={this.closeRecycleHelp}></RecycleHelp>
            }
        </div>
    }
}

PocketRecycle.contextType = AppContext
export default PocketRecycle