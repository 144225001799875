import EventEmitter from 'wolfy87-eventemitter'

const ee = new EventEmitter()

export const EVENT_TIP = 'EVENT_TIP'
export const EVENT_USER_INVALID = 'EVENT_USER_INVALID'
export const EVENT_USER_LOGIN = 'EVENT_USER_LOGIN'
export const EVENT_USER_LOGOUT = 'EVENT_USER_LOGOUT'
export const EVENT_ALLOW_RENT = 'EVENT_ALLOW_RENT'
export const EVENT_LANG_CHANGE = 'EVENT_LANG_CHANGE'
export const EVENT_USER_BAG = 'EVENT_USER_BAG'
export const EVENT_BLINDBOX_PRELOAD = 'EVENT_BLINDBOX_PRELOAD'
export const EVENT_RECYCLE_OVER = 'EVENT_RECYCLE_OVER'
export const EVENT_USER_NOT_BOUND = 'EVENT_USER_NOT_BOUND'
export const EVENT_USER_NOT_CERTED = 'EVENT_USER_NOT_CERTED'
export const EVENT_USER_CERT = 'EVENT_USER_CERT'
export const EVENT_UC_NAV_RELOAD = 'EVENT_UC_NAV_RELOAD'

export default ee