import React from 'react'
import Portal from '../../../../component/portal'
import { getDetailImgs, getHomeList } from './service'
import { getWindowData } from '../../../../component/service'
import { getImgUrl, i18n, hbsBoolean, showDiscount, getPLink, getLinkUrl } from '../../../../common/util'
import './index.scss'
import { AppContext } from '../../../../config/context'

const HomeListPageSize = 10
const DetailCacheMap = {}

class HomeList extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            type: 0,
            index: 0,
            list: window._inject_app_data_.newest,
            detail: null
        }
    }

    loadDetail = p => {
        if (!p) {
            return
        }
        const { goodsId } = p
        let detail = DetailCacheMap[goodsId]
        if (detail) {
            return this.setState({
                detail
            })
        }
        getDetailImgs(goodsId).then(detailImgs => {
            const { name, desc, tags } = p
            detail = {
                goodsId,
                name,
                desc,
                tags,
                detailImgs
            }
            this.setState({
                detail
            })
            DetailCacheMap[goodsId] = detail
        }).catch(e => {
            console.error(e)
        })
    }

    loadList = type => {
        if (type === this.state.type) {
            return
        }
        this.setState({
            type
        })
        if (type === 2) {
            getWindowData('pc_index_m10', 1).then(list => {
                list = list.slice(0, HomeListPageSize)
                this.setState({
                    list,
                    index: 0,
                    detail: null
                })
                this.loadDetail(list[0])
            })
        } else {
            const rankType = ['rank_recentest', 'rank_sales', '', 'rank_discount'][type]
            getHomeList(rankType, 1, HomeListPageSize).then(({ records: list }) => {
                this.setState({
                    list,
                    index: 0,
                    detail: null
                })
                this.loadDetail(list[0])
            })
        }
    }

    focusItem = (index) => {
        if (index !== this.state.index) {
            this.setState({
                index,
                detail: null
            })
            this.loadDetail(this.state.list[index])
        }
    }

    componentDidMount() {
        this.loadDetail(this.state.list[0])
    }

    render() {
        const { type, index, list, detail } = this.state
        const { lang } = this.context.user
        return <div>
            <ul className="home-list-header-ul">
                <li className={type === 0 ? 'home-list-header active' : 'home-list-header'} onClick={() => this.loadList(0)}>{i18n('新品', lang)}</li>
                <li className={type === 1 ? 'home-list-header active' : 'home-list-header'} onClick={() => this.loadList(1)}>{i18n('热销', lang)}</li>
                {/* <li className={type === 2 ? 'home-list-header active' : 'home-list-header'} onClick={() => this.loadList(2)}>{i18n('预售', lang)}</li> */}
                <li className={type === 3 ? 'home-list-header active' : 'home-list-header'} onClick={() => this.loadList(3)}>{i18n('折扣', lang)}</li>
            </ul>
            <div className="home-list-wrapper">
                <ul className="home-list-ul">
                    {
                        list.map((item, i) => (<a target="_blank" href={getLinkUrl(getPLink(item))}>
                            <li className={
                                i === 0
                                    ? i === index
                                        ? 'home-list-item first active'
                                        : 'home-list-item first'
                                    : i === index
                                        ? 'home-list-item active'
                                        : 'home-list-item'
                            } onMouseEnter={() => this.focusItem(i)}>
                                <img alt="" className="cover" src={getImgUrl(item.imgUrl)}></img>
                                <div className="home-list-info">
                                    <p className="name">{i18n(item.name, lang)}</p>
                                    {
                                        hbsBoolean(item.tags) && <p className="tags">
                                            {
                                                item.tags.map((tag, ii) => (<span>{i18n(tag.name, lang)}{ii === item.tags.length - 1 ? '' : '、'}</span>))
                                            }
                                        </p>
                                    }
                                </div>
                                {
                                    hbsBoolean(item.primePrice) && <span className="discount">{showDiscount(item.salePrice, item.primePrice)}</span>
                                }
                                <div className={hbsBoolean(item.primePrice) ? 'home-list-price' : 'home-list-price noop'}>
                                    <p className="price">&yen;{item.salePrice}</p>
                                    {
                                        hbsBoolean(item.primePrice) && <p className="op">&yen;{item.primePrice}</p>
                                    }
                                </div>
                            </li>
                        </a>))
                    }
                </ul>
                {/* <div className="home-list-more">{i18n('查看更多>', lang)}</div> */}
                <div className="home-list-window">
                    <div className="home-detail-wrapper">
                        {
                            detail && <div className="home-detail-content">
                                <p className="home-detail-name">{i18n(detail.name, lang)}</p>
                                <p className="home-detail-desc">{i18n(detail.desc, lang)}</p>
                                {
                                    hbsBoolean(detail.tags) && <ul className="home-detail-tags">
                                        {
                                            detail.tags.map(tag => (<li className="home-detail-tag">{i18n(tag.name, lang)}</li>))
                                        }
                                    </ul>
                                }
                                <div>
                                    {
                                        detail.detailImgs.map(img => (<img alt='' className='home-detail-img' src={getImgUrl(img)}></img>))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

HomeList.contextType = AppContext
export default Portal(HomeList, 'home-list-container')