import { httpGet, httpPost, getReportParams } from '../../common/util'
import { OSType, OrderLink } from '../../config'

export const getRechargeOptions = () => {
    return httpGet({
        url: '/goods/config/getRechargeLevels',
        data: {
            type: 2
        }
    })
}

export const submitRecharge = (payType, rechargeAmount) => {
    return httpPost({
        url: '/order/recharge',
        dataType: 'json',
        data: {
            ...getReportParams(),
            payType,
            rechargeAmount,
            returnUrl: OrderLink,
            osType: OSType
        },
        auth: true
    })
}

export const queryRechargeResult = orderId => {
    return httpGet({
        url: '/order/rechargeResult',
        data: {
            orderId
        },
        auth: true
    })
}