import React from 'react'
import { AppContext } from '../../config/context'
import { i18n } from '../../common/util'
import './recycleHelp.scss'

class RecycleHelp extends React.Component {
    constructor() {
        super(...arguments)
    }

    render() {
        const { lang } = this.context.user
        return <div className='recycle-help-container'>
            <div className='recycle-help-modal'>
                <i className='recycle-help-close' onClick={this.props.close}></i>
                <div className='recycle-help-header'>{i18n('物品回收说明', lang)}</div>
                <div className='recycle-help-content-wrapper'>
                    <div className='recycle-help-line'>
                        <div className='label'>1.</div>
                        <p>{i18n('回收操作不可撤销；', lang)}</p>
                    </div>
                    <div className='recycle-help-line'>
                        <div className='label'>2.</div>
                        <p>{i18n('可获得电池数=购买盲盒时消耗的电池数（赠送电池不支持回收）*70%；', lang)}</p>
                    </div>
                    <div className='recycle-help-line'>
                        <div className='label'>3.</div>
                        <p>{i18n('电池仅可用于盲盒抽奖，无法提现。', lang)}</p>
                    </div>
                </div>
            </div>
        </div>
    }
}

RecycleHelp.contextType = AppContext
export default RecycleHelp