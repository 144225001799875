import React from 'react'
import Portal from '../../../../component/portal'
import './index.scss'
import { getSeckillList } from '../../../../component/service'
import { getImgUrl, formatTimeForSeckill, i18n, hbsBoolean, showDiscount, getUnitForSeckill, getPLink } from '../../../../common/util'
import { AppContext } from '../../../../config/context'

class HomeSeckill extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            show: false,
            hour: '00',
            min: '00',
            sec: '00',
            list: [],
            sa: ''
        }
        this.timer = null
        this.errorTime = 0
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
        if (this.errorTime > 3) {
            return
        }
        this.setState({
            hour: '00',
            min: '00',
            sec: '00'
        })
        getSeckillList().then(({ activityType, activityId, timestamp, now, timeTerm, timeTermUnit, goodsList, shelveFlag }) => {
            const show = shelveFlag === 1
            if (!show) {
                return this.setState({
                    show
                })
            }
            const timerInfo = getUnitForSeckill(timeTermUnit)
            const endTime = timestamp + timerInfo.value * timeTerm
            let seconds = Math.ceil((endTime - now) / 1000)
            if (seconds <= 0) {
                throw new Error('activity timeout')
            }
            this.errorTime = 0
            this.setState({
                show,
                ...formatTimeForSeckill(seconds),
                list: goodsList.slice(0, 6),
                sa: `?seacba=${activityType}-${activityId}-${timestamp.toString(16)}`
            })
            this.timer = setInterval(() => {
                --seconds
                if (seconds >= 0) {
                    this.setState(formatTimeForSeckill(seconds))
                }
                if (seconds <= 0) {
                    this.init()
                }
            }, 1000)
        }).catch(e => {
            this.tryInit(e)
        })
    }

    tryInit = e => {
        console.error(e)
        this.errorTime++
        setTimeout(() => { this.init() }, 1000)
    }

    renderItem = (item, lang, sa) => {
        return <a target='_blank' href={getPLink(item) + sa} key={item.goodsId}>
            <li className='list-product-item'>
                <div className='product-item-upper'>
                    <img alt='' className='cover' src={getImgUrl(item.mainPic)}></img>
                    <div className='product-item-mask'></div>
                    <div className='discount'>{showDiscount(item.priceAfterDiscount, item.primePrice)}</div>
                </div>
                <div className='product-item-lower'>
                    <div className='info'>
                        <span className='price'>&yen;{item.priceAfterDiscount}</span>
                        <p className='name'>{i18n(item.goodsName, lang)}</p>
                    </div>
                    <div className='ext'>
                        <span className='op'>&yen;{item.primePrice}</span>
                        {
                            hbsBoolean(item.tags) && <p className='tag'>{item.tags[0].name}</p>
                        }
                    </div>
                </div>
            </li>
        </a>
    }

    render() {
        const { show, hour, min, sec, list, sa } = this.state
        const { lang } = this.context.user
        return show ? <div>
            <h2 className="home-module-title"><i className="home-module-icon limit"></i>{i18n('限时秒杀', lang)}</h2>
            <div className='home-seckill-wrapper'>
                <div className='home-seckill-panel'>
                    <div className='counter-wrapper'>
                        <div className='counter-block'>{hour}</div>
                        <div className='counter-block'>{min}</div>
                        <div className='counter-block'>{sec}</div>
                    </div>
                </div>
                <ul className='home-seckill-ul'>
                    {
                        list.map(item => this.renderItem(item, lang, sa))
                    }
                </ul>
            </div>
        </div> : null
    }
}

HomeSeckill.contextType = AppContext
export default Portal(HomeSeckill, 'home-seckill-container')