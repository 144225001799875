import React from 'react'
import { getQRCodeImg } from './service'
import './index.scss'

class QRCode extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            qrcodeSrc: null
        }
    }

    componentDidMount() {
        this.renderToImg(this.props.src)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.renderToImg(this.props.src)
        }
    }

    renderToImg(src) {
        if (!src) {
            return this.setState({
                qrcodeSrc: null
            })
        }
        if (this.props.worked) {
            return this.setState({
                qrcodeSrc: src
            })
        }
        getQRCodeImg(src, this.props.width).then(qrcodeSrc => {
            this.setState({
                qrcodeSrc
            })
        })
    }

    render() {
        const { qrcodeSrc } = this.state
        return <div className='custom-qrcode-wrapper'>
            {
                qrcodeSrc && <img alt='' className='custom-qrcode-img' src={qrcodeSrc}></img>
            }
        </div>
    }
}

export default QRCode