import { httpGet } from '../../common/util'

export const recyclePocketItems = subOrderIds => {
    return httpGet({
        url: '/order/bag/recycle',
        data: {
            subOrderIds
        },
        auth: true
    })
}