import React from 'react'
import ReactDOM from 'react-dom'

function Portal(WrappedComponent, container) {
    return class extends React.Component {
        constructor() {
            super(...arguments)
            this.$el = document.getElementById(container)
        }

        componentWillMount() {
            this.$el.innerHTML = ''
        }

        render() {
            return ReactDOM.createPortal(
                <WrappedComponent {...this.props}></WrappedComponent>,
                this.$el
            )
        }
    }
}

export default Portal