import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, throttle, getImgUrl, getLevelClassName, getLevelDisplayName, hbsBoolean } from '../../common/util'
import { getPocketItems, getPocketInfo } from './service'
import ee, { EVENT_TIP } from '../../common/util/event'
import Recycle from '../recycle'
import PocketDetail from './pocketDetail'
import RecycleHelp from './recycleHelp'
import './pocket.scss'

const PageSize = 20
const getQueryParams = (tabIndex, status, keyword) => {
    const ret = {
        bagStatus: status
    }
    if (tabIndex === 1) {
        ret.productType = 'CDK'
    } else if (tabIndex === 2) {
        ret.productType = 'ACCOUNT'
    } else if (tabIndex === 3) {
        ret.productType = 'RECHAGRE'
    }
    if (keyword) {
        ret.prizeName = keyword
    }
    return ret
}
const getItemStatusTxt = item => {
    if (item.status === 11) {
        return (item.openDate ? item.openDate.split(' ')[0] + ' ' : '') + '已打开'
    }
    return item.recycleDate ? item.recycleDate.split(' ')[0] + ' 前可回收' : ''
}
const StatusMap = { 0: '全部', 1: '已打开', 2: '未打开' }
const ScrollerELId = 'bag-pocket-scroller'

class BagPocket extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tabIndex: 0,
            status: 0,
            page: 1,
            list: [],
            showStatusSelector: false,
            ready: false,
            recycleBagTotalCount: 0,
            recycleBagTotalAmount: 0,
            showPocketDetail: false,
            pocketDetailId: '',
            showRecycleHelp: false,
            showRecycleModal: false,
            selectedList: [],
            keyword: '',
            usingKeyword: '',
            total: 0
        }
        this.hasMore = false
        this.loadFlag = false
    }

    initPocket = (tabIndex, status, keyword) => {
        getPocketItems(1, PageSize, getQueryParams(tabIndex, status, keyword)).then(({ pages, records, total }) => {
            const dataExist = Array.isArray(records) && records.length
            this.setState({
                page: 1,
                list: dataExist ? records : [],
                total,
                ready: true
            }, () => {
                this.hasMoreEffect(pages > 1)
                dataExist && (document.getElementById(ScrollerELId).scrollTop = 0)
            })
        }).catch(e => {
            if (e.code !== 401) {
                this.setState({
                    page: 1,
                    list: [],
                    total: 0,
                    ready: true
                })
                this.hasMoreEffect(false)
                ee.emit(EVENT_TIP, e.message)
            }
        })
    }

    componentDidMount() {
        this.init()
        document.addEventListener('click', () => this.setState({ showStatusSelector: false }))
    }

    init = () => {
        getPocketInfo().then(({ recycleBagTotalCount, recycleBagTotalAmount }) => {
            this.setState({
                recycleBagTotalCount,
                recycleBagTotalAmount
            })
        })
        this.initPocket(this.state.tabIndex, this.state.status, this.state.usingKeyword)
    }

    chooseTab = (tabIndex, action, e) => {
        if (tabIndex !== this.state.tabIndex) {
            this.setState({
                tabIndex
            })
            this.initPocket(tabIndex, this.state.status, this.state.usingKeyword)
        }
        _sr(action, '个人中心-我的背包', `2-${tabIndex + 1}`, '', `pocket-tab-${tabIndex + 1}`, e)
    }

    chooseStatus = status => {
        if (status !== this.state.status) {
            this.setState({
                status
            })
            this.initPocket(this.state.tabIndex, status, this.state.usingKeyword)
        }
    }

    toggleStatusSelector = e => {
        e.stopPropagation()
        this.setState({
            showStatusSelector: !this.state.showStatusSelector
        })
    }

    hasMoreEffect = hasMore => {
        if (hasMore !== this.hasMore) {
            this.hasMore = hasMore
            const el = document.getElementById(ScrollerELId)
            hasMore
                ? el.addEventListener('scroll', this.scrollListener)
                : el.removeEventListener('scroll', this.scrollListener)
        }
    }

    loadMoreData = () => {
        if (!this.hasMore || this.loadFlag) {
            return
        }
        this.loadFlag = true
        const { tabIndex, status, usingKeyword, page, list } = this.state
        getPocketItems(page + 1, PageSize, getQueryParams(tabIndex, status, usingKeyword)).then(({ pages, records }) => {
            this.setState({
                page: page + 1,
                list: list.concat(records)
            }, () => {
                this.loadFlag = false
            })
            this.hasMoreEffect(pages > page + 1)
        })
    }

    scrollListener = throttle(() => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById(ScrollerELId)
        if (scrollTop + clientHeight >= scrollHeight) {
            this.loadMoreData()
        }
    })

    goToDetail = (pocketDetail, index, e) => {
        const { subOrderId: pocketDetailId, goodsId, name: goodsName, productType } = pocketDetail
        this.setState({
            showPocketDetail: true,
            pocketDetailId
        })
        _sr(goodsName, '个人中心-我的背包', `${2 + Math.ceil((index + 1) / 5)}-${(index % 5) + 1}`, { goodsId, goodsName, productType }, `pocket-item-${index + 1}`, e)
    }

    closePocketDetail = () => {
        this.setState({
            showPocketDetail: false
        })
    }

    showRecycleModal = selectedList => {
        this.setState({
            showRecycleModal: true,
            selectedList
        })
        this.closePocketDetail()
    }

    closeRecycleModal = () => {
        this.setState({
            showRecycleModal: false
        })
    }

    goToRecycle = e => {
        this.props.changeRealIndex(4)
        _sr('物品回收', '个人中心-我的背包', '', '', 'pocket-recycle-btn', e)
    }

    updatePocket = newStatus => {
        const { list, pocketDetailId } = this.state
        const newList = list.slice()
        const length = newList.length
        for (let i = 0; i < length; ++i) {
            let item = newList[i]
            if (item.subOrderId === pocketDetailId) {
                newList[i] = { ...item, ...newStatus }
                this.setState({
                    list: newList
                })
                break
            }
        }
    }

    showRecycleHelp = () => {
        this.setState({
            showRecycleHelp: true
        })
    }

    closeRecycleHelp = () => {
        this.setState({
            showRecycleHelp: false
        })
    }

    inputKeyword = e => {
        this.setState({
            keyword: e.target.value
        })
    }

    searchByKeyword = () => {
        const { keyword, usingKeyword } = this.state
        if (!keyword || !keyword.trim()) {
            if (usingKeyword) {
                this.cancelSearch()
            }
            return
        }
        const trimmed = keyword.trim()
        if (trimmed !== usingKeyword) {
            this.setState({
                usingKeyword: trimmed
            })
            this.initPocket(this.state.tabIndex, this.state.status, trimmed)
        }
    }

    cancelSearch = () => {
        this.setState({
            usingKeyword: ''
        })
        this.initPocket(this.state.tabIndex, this.state.status, '')
    }

    render() {
        const { tabIndex, status, list, showStatusSelector, ready, recycleBagTotalCount, recycleBagTotalAmount, showPocketDetail, pocketDetailId, showRecycleHelp, showRecycleModal, selectedList, keyword, usingKeyword, total } = this.state
        const { lang } = this.context.user
        return <div className='bag-pocket-container'>
            <div className='bag-pocket-wrapper'>
                <ul className='bag-content-tabs'>
                    <li className={tabIndex === 0 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(0, '全部', e)}>{i18n('全部', lang)}</li>
                    <li className={tabIndex === 1 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(1, 'CDK', e)}>{i18n('CDK', lang)}</li>
                    <li className={tabIndex === 2 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(2, '账号', e)}>{i18n('账号', lang)}</li>
                    <li className={tabIndex === 3 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(3, '直充', e)}>{i18n('直充', lang)}</li>
                    <li className='bag-pocket-status-selector' onClick={this.toggleStatusSelector}>
                        <span>{i18n(StatusMap[status], lang)}</span>
                        {
                            showStatusSelector && <div className='pocket-options-ul'>
                                <div className={status === 0 ? 'pocket-option active' : 'pocket-option'} onClick={() => this.chooseStatus(0)}>{i18n(StatusMap[0], lang)}</div>
                                <div className={status === 2 ? 'pocket-option active' : 'pocket-option'} onClick={() => this.chooseStatus(2)}>{i18n(StatusMap[2], lang)}</div>
                                <div className={status === 1 ? 'pocket-option active' : 'pocket-option'} onClick={() => this.chooseStatus(1)}>{i18n(StatusMap[1], lang)}</div>
                            </div>
                        }
                    </li>
                    <li className='bag-pocket-search-wrapper'>
                        <button className='bag-pocket-search-btn' onClick={this.searchByKeyword}>{i18n('搜索', lang)}</button>
                        <input type='text' name='bag-pocket-search-input' className='bag-pocket-search-input' maxLength={30} value={keyword} placeholder={i18n('请输入物品名称', lang)} onChange={this.inputKeyword}></input>
                    </li>
                </ul>
                {
                    !!usingKeyword && <div className='bag-search-result-wrapper'>
                        <span className='search-result-title'>{i18n('搜索结果：', lang)}</span>
                        <span className='search-keyword'>{usingKeyword}<i className='search-cancel' onClick={this.cancelSearch}></i></span>
                        <span className='search-result-count'>{i18n(`共${total}款`, lang)}</span>
                    </div>
                }
                <div className={ready && list.length > 0 ? 'bag-pocket-items-window' : 'bag-pocket-items-window hidden'} id={ScrollerELId}>
                    <ul className='bag-pocket-items-ul'>
                        {
                            list.map((item, i) => (<li className='bag-pocket-item' key={item.subOrderId} onClick={e => this.goToDetail(item, i, e)}>
                                <div className='bag-pocket-item-upper'>
                                    {
                                        hbsBoolean(item.pcMhImgUrl) && <img alt='' className='bag-pocket-item-img' src={getImgUrl(item.pcMhImgUrl)}></img>
                                    }
                                    <div className='bag-pocket-item-value'>&yen;{item.primePrice}</div>
                                    <div className={`bag-pocket-item-level ${getLevelClassName(item.borderColor)}`}>{i18n(getLevelDisplayName(item.borderColor), lang)}</div>
                                </div>
                                <div className='bag-pocket-item-lower'>
                                    <p className='bag-pocket-item-name'>{i18n(item.name, lang)}</p>
                                    <p className={item.recycleStatus > 0 ? 'bag-pocket-item-status-desc enable' : 'bag-pocket-item-status-desc'}>{i18n(getItemStatusTxt(item), lang)}</p>
                                </div>
                                {
                                    item.status !== 11 && <div className='bag-pocket-item-status-wrapper'>
                                        <div className={item.recycleStatus > 0 ? 'bag-pocket-item-status recycle' : 'bag-pocket-item-status'}>
                                            <i className='status-icon'></i>
                                            {i18n(item.recycleStatus > 0 ? '可回收' : '未打开', lang)}
                                        </div>
                                    </div>
                                }
                            </li>))
                        }
                    </ul>
                </div>
                <div className={ready && list.length === 0 ? 'bag-empty-wrapper pocket' : 'bag-empty-wrapper pocket hidden'}>
                    <i className='empty-icon'></i>
                    <p className='empty-txt'>{i18n('这里没有物品~', lang)}</p>
                </div>
            </div>
            <div className='bag-content-footer'>
                <button className='bag-content-footer-btn recycle' onClick={this.goToRecycle}>{i18n('物品回收', lang)}</button>
                <p className='bag-content-footer-info'>
                    {i18n('可回收物品数量', lang)}
                    <span className='highlight' style={{ marginLeft: '8px' }}>{recycleBagTotalCount}</span>
                    <span className='split'>|</span>
                    {i18n('可获得电池数量', lang)}
                    <i className='battery-icon'></i>
                    <span className='highlight'>{recycleBagTotalAmount}</span>
                    <i className='recycle-help-icon' onClick={this.showRecycleHelp}></i>
                </p>
            </div>
            {
                showPocketDetail && <PocketDetail close={this.closePocketDetail} pocketDetailId={pocketDetailId} updatePocket={this.updatePocket} goToRecycle={this.showRecycleModal}></PocketDetail>
            }
            {
                showRecycleModal && <Recycle close={this.closeRecycleModal} selectedList={selectedList} submit={this.init}></Recycle>
            }
            {
                showRecycleHelp && <RecycleHelp close={this.closeRecycleHelp}></RecycleHelp>
            }
        </div>
    }
}

BagPocket.contextType = AppContext
export default BagPocket