import React from 'react'
import Portal from '../portal'
import { AppContext } from '../../config/context'
import './search.scss'
import './index.scss'
import {
    checkUserInfoLocally,
    clearUserInfoLocally,
    getFromLocalStorage,
    setToLocalStorage,
    removeFromLocalStorage,
    hbsBoolean,
    keyboardEnter,
    transformQueryString,
    // getImgUrl,
    i18n,
    setCookie
} from '../../common/util'
import { OrderLink } from '../../config'
import { getSearchRecommend } from './service'
import { getServiceUrl } from '../service'
import ee, { EVENT_USER_LOGOUT, EVENT_LANG_CHANGE, EVENT_USER_INVALID, EVENT_RECYCLE_OVER, EVENT_USER_NOT_BOUND, EVENT_USER_NOT_CERTED } from '../../common/util/event'
import Bag from '../bag'
import Login from '../login'
import Cert from '../cert'
import Logout from '../logout'

const _search_cache_key = '_gsck'

class Header extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            showSearchRC: false,
            showLoginModal: false,
            showLangSlide: false,
            recommend: null,
            record: null,
            searchContent: '',
            navs: window._inject_navs_data_ || [],
            logout: false,
            exp_index: -1,
            showOrderOptions: false,
            showBag: false,
            bagParams: {},
            showCertModal: false
            // showRent: false
        }
    }

    componentDidMount() {
        const ui = checkUserInfoLocally()
        this.context.updateUserInfo(
            ui
                ? {
                    isLogin: 1,
                    ...ui
                }
                : {
                    isLogin: 0
                }
        )
        getSearchRecommend().then(recommend => this.setState({ recommend }))
        let record = getFromLocalStorage(_search_cache_key)
        if (record) {
            record.length > 5 && (record = record.slice(0, 5))
            this.setState({ record })
        }
        document.addEventListener('click', () => this.setState({
            showSearchRC: false,
            showLangSlide: false,
            showOrderOptions: false
        }))
        ee.addListener(EVENT_USER_INVALID, this.closeBag)
        ee.addListener(EVENT_RECYCLE_OVER, this.closeBag)
        ee.addListener(EVENT_USER_NOT_BOUND, this.showLoginModal)
        ee.addListener(EVENT_USER_NOT_CERTED, this.showCertModal)
    }

    showCertModal = () => {
        this.setState({
            showCertModal: true
        })
    }

    closeCertModal = () => {
        this.setState({
            showCertModal: false
        })
    }

    logout = () => {
        this.setState({
            logout: true
        })
        ee.emit(EVENT_USER_LOGOUT)
    }

    logoutCallback = () => {
        clearUserInfoLocally()
        location.href = '/'
    }

    showSearchDrop = e => {
        e.stopPropagation()
        this.setState({
            showSearchRC: true,
            showLangSlide: false,
            showOrderOptions: false
        })
    }

    inputSearchContent = e => {
        this.setState({ searchContent: e.target.value })
    }

    search = keyword => {
        keyword = (keyword || this.state.searchContent).trim()
        if (!keyword) {
            return
        }
        let record = (this.state.record || []).filter(r => r !== keyword)
        record.unshift(keyword)
        if (record.length > 5) {
            record = record.slice(0, 5)
        }
        setToLocalStorage(_search_cache_key, record)
        _sr('click', '顶部导航', '顶部搜索', { searchName: keyword }, 'nav-search')
        const href = '/search' + transformQueryString({ keyword })
        if (location.pathname === '/search') {
            location.href = href
        } else {
            this.setState({ record })
            window.open(href)
        }
    }

    clearRecords = () => {
        removeFromLocalStorage(_search_cache_key)
        this.setState({ record: null })
    }

    showLoginModal = () => {
        this.setState({
            showLoginModal: true
        })
    }

    closeLoginModal = () => {
        this.setState({
            showLoginModal: false
        })
    }

    focusNavExp = exp_index => {
        this.setState({
            exp_index
        })
    }

    showBag = (bagParams = {}) => {
        if (this.context.user.isLogin === 1) {
            return this.setState({
                bagParams,
                showBag: true
            })
        }
        this.showLoginModal()
    }

    closeBag = () => {
        this.setState({
            showBag: false
        })
    }

    expandOrderOptions = e => {
        e.stopPropagation()
        const { showOrderOptions } = this.state
        this.setState({
            showOrderOptions: !showOrderOptions,
            showLangSlide: false,
            showSearchRC: false
        })
    }

    toggleLangSlide = e => {
        e.stopPropagation()
        const { showLangSlide } = this.state
        this.setState({
            showLangSlide: !showLangSlide,
            showSearchRC: false,
            showOrderOptions: false
        })
    }

    selectLang = lang => {
        setCookie('_gml', lang)
        this.context.updateUserInfo({
            lang
        })
        ee.emit(EVENT_LANG_CHANGE, lang)
        function primitiveUpdate(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                node.textContent = i18n(node.textContent, lang)
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                let children = node.childNodes
                if (children.length) {
                    for (let i = 0; i < children.length; ++i) {
                        primitiveUpdate(children[i])
                    }
                }
            }
        }
        const collection = document.getElementsByClassName('p-l-w')
        for (let i = 0; i < collection.length; ++i) {
            primitiveUpdate(collection[i])
        }
        document.title = i18n(document.title, lang)
        const metaCt = document.getElementsByTagName('meta')
        for (let i = 0; i < metaCt.length; ++i) {
            let m = metaCt[i]
            if (m.name === 'description' || m.name === 'keywords') {
                m.content = i18n(m.content, lang)
            }
        }
    }

    goToOrder = () => {
        if (this.context.user.isLogin === 1) {
            return window.open(OrderLink)
        }
        this.showLoginModal()
    }

    goToBbOrder = () => {
        this.showBag({ index: 2 })
    }

    goToBag = () => {
        this.showBag({ index: 1 })
    }

    goToService = () => {
        getServiceUrl().then(href => {
            window.open(href)
        })
    }

    render() {
        const { navs, showSearchRC, showLangSlide, recommend, record, searchContent, logout, showLoginModal, showCertModal, exp_index, showOrderOptions, showBag, bagParams } = this.state
        const userInfo = this.context.user
        const lang = userInfo.lang
        return <div className="G-header-content">
            <a target="_self" href="/">
                <i className="G-header-logo"></i>
            </a>
            <div className="G-header-wrapper">
                <div className="G-header-search">
                    <div className="G-header-search-enter" onClick={() => this.search()}>
                        <i className="icon"></i>
                        <span className="text">{i18n('搜索', lang)}</span>
                    </div>
                    <input type="text" name="g-search" className="G-header-search-input" placeholder={i18n('请输入商品名或盲盒名', lang)}
                        maxlength="30" onClick={this.showSearchDrop} value={searchContent} onChange={this.inputSearchContent} onKeyDown={e => keyboardEnter(e, this.search)}></input>
                    {
                        showSearchRC && <div className="header-search-drop">
                            {
                                hbsBoolean(recommend) && <div>
                                    <div className="header-search-title">{i18n('热门推荐', lang)}</div>
                                    <ul>
                                        {
                                            recommend.map(r => (<li className="header-search-item" key={r} onClick={() => this.search(r)}>{i18n(r, lang)}</li>))
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                hbsBoolean(record) && <div>
                                    <div className="header-search-title">{i18n('历史记录', lang)}<span className="clear" onClick={this.clearRecords}>{i18n('清空', lang)}</span></div>
                                    <ul>
                                        {
                                            record.map(r => (<li className="header-search-item" key={r} onClick={() => this.search(r)}>{r}</li>))
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </div>
                <ul className="G-header-menus">
                    {
                        navs.map((nav, index) => (hbsBoolean(nav.children)
                            ? <li className={index === exp_index ? 'G-header-item exp' : 'G-header-item'} onMouseEnter={() => this.focusNavExp(index)} onMouseLeave={() => this.focusNavExp(-1)}>
                                <span className="text">{i18n(nav.name, lang)}</span>
                                <i className="triangle"></i>
                                <div className="G-header-expand">
                                    <ul>
                                        {
                                            nav.children.map(snav => (<a target={snav.target} href={snav.url} st-btn="1" st-category="顶部导航" st-label="顶部导航"
                                                st-action="click" st-value={JSON.stringify([{ navName: snav.name }])} st-element-id={`nav-${snav.id}`}>
                                                <li className="G-expand-item">{i18n(snav.name, lang)}</li>
                                            </a>))
                                        }
                                    </ul>
                                </div>
                            </li>
                            : <li className={nav.active ? 'G-header-item active' : 'G-header-item'}>
                                <a target={nav.target} href={nav.url} st-btn="1" st-category="顶部导航" st-label="顶部导航"
                                    st-action="click" st-value={JSON.stringify([{ navName: nav.name }])} st-element-id={`nav-${nav.id}`}>
                                    <span className="text">{i18n(nav.name, lang)}</span>
                                </a>
                            </li>))
                    }
                </ul>
            </div>
            <div className="G-header-bar">
                {/* <div className="G-header-bar-item">
                    <i className="icon cart"></i>
                    <span className="text">{i18n('购物车', lang)}</span>
                </div> */}
                <div className="G-header-bar-item" onClick={this.goToService}>
                    <i className="icon service"></i>
                    <span className="text">{i18n('客服', lang)}</span>
                </div>
                <div className="G-header-bar-item order" onClick={this.expandOrderOptions}>
                    <i className="icon order"></i>
                    <span className="text">{i18n('订单', lang)}</span>
                    {
                        showOrderOptions && <div className='header-order-options-wrapper'>
                            <div className='header-order-option' onClick={this.goToOrder}>{i18n('商品订单', lang)}</div>
                            <div className='header-order-option' onClick={this.goToBbOrder}>{i18n('盲盒订单', lang)}</div>
                        </div>
                    }
                </div>
                <div className="G-header-bar-item" onClick={this.goToBag}>
                    <i className="icon bag"></i>
                    <span className="text">{i18n('背包', lang)}</span>
                </div>
                {
                    userInfo.isLogin === 0 && <div className="G-header-bar-item login" onClick={this.showLoginModal}>
                        <i className="icon login"></i>
                        <span className="text">{i18n('登录', lang)}</span>
                    </div>
                }
                {
                    userInfo.isLogin === 1 && <div className="G-header-bar-item logout">
                        <span className="logout" onClick={this.logout}>{i18n('退出', lang)}</span>
                        <a target='_blank' href={OrderLink}>
                            <p className="display-name">{i18n(userInfo.displayName, lang)}</p>
                        </a>
                    </div>
                }
                <div className={showLangSlide ? 'G-header-bar-item lang exp' : 'G-header-bar-item lang'} onClick={this.toggleLangSlide}>
                    <span className="text">{i18n(lang === 'zh_HK' ? '繁体' : '简体', lang)}</span>
                    <i className="triangle"></i>
                    {
                        showLangSlide && <div className='G-header-lang-slide'>
                            <div className='G-header-lang' onClick={() => this.selectLang('zh_CN')}>{i18n('简体', lang)}</div>
                            <div className='G-header-lang' onClick={() => this.selectLang('zh_HK')}>{i18n('繁体', lang)}</div>
                        </div>
                    }
                </div>
            </div>
            {
                showLoginModal && <Login close={this.closeLoginModal}></Login>
            }
            {
                showCertModal && <Cert close={this.closeCertModal} isForce={true}></Cert>
            }
            {
                showBag && <Bag close={this.closeBag} {...bagParams}></Bag>
            }
            {
                logout && <Logout close={this.logoutCallback}></Logout>
            }
        </div>
    }
}

Header.contextType = AppContext

export default Portal(Header, 'G-header-container')