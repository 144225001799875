import { httpGet, httpPost, isNumber, getReportParams, getUidIfNeccessary } from '../common/util'
import { OrderLink } from '../config'

export const getCatesTree = () => {
    return httpGet({
        url: '/goods/commontree/1'
    })
}

export const getTagsTree = () => {
    return httpGet({
        url: '/goods/commontree/2'
    })
}

export const getProductListByType = (type, id, pageNum, pageSize) => {
    const key = { c: 'categoryId', t: 'tagId', d: 'gameDeveloperId', p: 'gamePlatformId', c1: 'gameCategoryId', p1: 'gamePublisherId' }[type]
    return httpGet({
        url: '/goods/common/list',
        data: {
            [key]: id,
            pageNum,
            pageSize
        }
    })
}

export const getPayTypes = () => {
    return httpGet({
        url: '/order/payTypes'
    })
}

export const isNotProEnv = () => {
    return httpGet({
        url: '/user/test/env'
    })
}

export const getCouponList = (params, auth = false) => {
    return httpGet({
        url: '/activity/coupon/listAvailableCoupon',
        data: {
            pageNum: 1,
            ...params
        },
        silent: true,
        auth
    })
}

export const receiveCoupon = (activityId, couponCode) => {
    return httpPost({
        url: '/activity/coupon/receiveCoupon',
        dataType: 'json',
        data: {
            activityId,
            ...couponCode && {
                couponCode
            }
        },
        auth: true
    })
}

export const getCouponDetail = couponId => {
    return httpGet({
        url: '/activity/coupon/detail',
        data: {
            couponId
        }
    })
}

// export const checkRentChannelId = () => {
//     return httpGet({
//         url: '/goods/channel/isZuhaoChannel'
//     })
// }

export const getPaymentInfo = (orderId, payType) => {
    return httpPost({
        url: '/order/pay',
        dataType: 'json',
        data: {
            ...getReportParams(),
            orderId,
            payType,
            returnUrl: OrderLink
        },
        auth: true
    })
}

export const queryPaymentStatus = (orderId) => {
    return httpGet({
        url: '/order/payResult',
        data: {
            orderId,
            _: Date.now()
        },
        auth: true
    })
}

export const getUsableCouponList = skuIds => {
    return httpPost({
        url: '/activity/coupon/getGoodsUsableCouponList',
        dataType: 'json',
        data: {
            skuIds
        },
        auth: true
    })
}

export const getAuthStatus = () => {
    return httpGet({
        url: '/user/login/isAuth',
        auth: true
    }).then(data => {
        data.age = data.age ? data.age : data.isAuth === 1 ? 18 : 0
        data.isOn = data.authStatus === 1
        return data
    })
    // .catch(e => {
    //     console.error(e)
    //     return {
    //         isOn: false,
    //         isAuth: 1,
    //         age: 18
    //     }
    // })
}

// export const getCertSwitch = () => {
//     return httpGet({
//         url: '/user/login/getAuthStatus'
//     }).then(data => {
//         return data.authStatus === 1
//     }).catch(e => {
//         console.error(e)
//         return false
//     })
// }

export const getWindowData = (ename, type, withName = false) => {
    return httpGet({
        url: '/goods/window/data',
        data: {
            ename
        }
    }).then(data => {
        const content = data.content.filter(i => i.type === type).sort((a, b) => b.sort - a.sort)
        if (type === 2 && content.length) {
            content.forEach(c => {
                c.urlType === 3 && !isNaN(Number(c.url)) && (c.url = `/p/${c.url}.html`)
            })
        }
        return withName ? { content, name: data.name || '' } : content
    })
}

export const getExchangeRate = () => {
    return httpGet({
        url: '/order/getExchangeRate'
    }).then(rate => {
        if (!isNumber(rate) || isNaN(rate)) {
            throw new Error('Rate should be a number')
        }
        return rate
    })
}

export const getSeckillList = () => {
    return httpGet({
        url: '/activity/flashSale/list'
    })
}

export const getServiceUrl = () => {
    return httpGet({
        url: '/user/im/get',
        data: {
            channelType: 0,
            ...getUidIfNeccessary()
        }
    })
}

export const preOrder = (skuId, num, activityType, activityId, ext) => {
    return httpPost({
        url: '/order/preOrder',
        dataType: 'json',
        data: {
            ...(activityType > -1 && activityId > -1) && {
                activityType,
                activityId
            },
            skuVOs: [
                {
                    skuId,
                    num,
                    ...ext && {
                        ext: JSON.stringify(ext)
                    }
                }
            ]
        },
        auth: true
    })
}

export const confirmOrder = (phone, ext) => {
    return httpPost({
        url: '/order/buy',
        dataType: 'json',
        data: {
            phone,
            ...ext
        },
        auth: true
    })
}

export const getOrderDetail = subOrderId => {
    return httpGet({
        url: '/order/subDetail',
        data: {
            subOrderId
        },
        auth: true
    })
}

export const getParentOrderDetail = (orderId, goodsType = 2) => {
    return httpGet({
        url: '/order/page',
        data: {
            orderId,
            goodsType
        },
        auth: true
    }).then(({ records }) => {
        if (Array.isArray(records) && records.length === 1) {
            return records[0]
        }
        throw new Error('Order not found')
    })
}

export const getOrderList = (pageNum, pageSize, type, goodsType = 1) => {
    return httpGet({
        url: '/order/page',
        data: {
            goodsType,
            type,
            pageNum,
            pageSize
        },
        auth: true
    })
}

export const getUserAccount = () => {
    return httpGet({
        url: '/user/account',
        auth: true,
        silent: true
    })
}

export const deliverByOrderId = (subOrderId, syn) => {
    return httpGet({
        url: '/order/deliver',
        data: {
            subOrderId,
            syn
        },
        auth: true
    })
}

export const deliverWithFormData = (subOrderId, syn, params) => {
    return httpPost({
        url: '/order/submit',
        dataType: 'json',
        data: {
            subOrderId,
            syn,
            ...params
        },
        auth: true
    })
}

export const getAgreement = contentTypeSub => {
    return httpGet({
        url: '/goods/settings/platformContent/detail',
        data: {
            contentTypeSub
        },
        headers: {
            platform: 'pc'
        }
    })
}

export const getProductDetail = goodsId => {
    return httpGet({
        url: '/goods/detail/' + goodsId
    })
}

export const getProductDetailForcedly = goodsId => {
    return httpGet({
        url: '/goods/fx/detail',
        data: {
            goodsId
        }
    })
}

export const getOrderCDK = subOrderId => {
    return httpGet({
        url: '/order/viewCdks',
        data: {
            subOrderId
        },
        auth: true
    })
}

export const getLogisticsInfo = subOrderId => {
    return httpGet({
        url: '/order/expressInfo',
        data: {
            subOrderId
        },
        auth: true
    })
}

export const getBigPlayerTemplate = goodsId => {
    return httpGet({
        url: '/stock/product/template',
        data: {
            goodsId
        }
    })
}

export const getPriceByArea = (skuId, gid, sid) => {
    return httpGet({
        url: '/stock/queryAreaServerProductPrice',
        data: {
            skuId,
            gid,
            sid
        }
    })
}