import { httpGet } from '../../../../common/util'

export const getDetailImgs = goodsId => {
    return httpGet({
        url: '/goods/detail/' + goodsId
    }).then(({ detailImgs }) => {
        return detailImgs
    })
}

export const getHomeList = (type, pageNum, pageSize, useGoodImg = false) => {
    return httpGet({
        url: '/goods/rank/list',
        data: {
            type,
            pageNum,
            pageSize
        }
    }).then(data => {
        if (useGoodImg) {
            const { records } = data
            records.forEach(r => {
                r.goodsPic && (r.imgUrl = r.goodsPic)
            })
        }
        return data
    })
}