const AreaData = [
  {
    prefix: 'C',
    data: [
      { en: 'China', zh_CN: '中国', zh_TW: '中國', code: 'CN', value: 86 }
    ]
  },
  {
    prefix: 'H',
    data: [
      { en: 'Hong Kong', zh_CN: '香港', zh_TW: '香港', code: 'HK', value: 852 }
    ]
  },
  {
    prefix: 'M',
    data: [
      {
        en: 'Macau',
        zh_CN: '澳门地区',
        zh_TW: '澳門地區',
        code: 'MO',
        value: 853,
      }
    ]
  },
  {
    prefix: 'T',
    data: [
      {
        en: 'Taiwan',
        zh_CN: '台湾地区',
        zh_TW: '臺灣地區',
        code: 'TW',
        value: 886,
      }
    ]
  }
]
// const AreaData = [
//   {
//     prefix: 'A',
//     data: [
//       {
//         en: 'Afghanistan',
//         zh_CN: '阿富汗',
//         zh_TW: '阿富汗',
//         code: 'AF',
//         value: 93,
//       },
//       {
//         en: 'Albania',
//         zh_CN: '阿尔巴尼亚',
//         zh_TW: '阿爾巴尼亞',
//         code: 'AL',
//         value: 355,
//       },
//       {
//         en: 'Algeria',
//         zh_CN: '阿尔及利亚',
//         zh_TW: '阿爾及利亞',
//         code: 'DZ',
//         value: 213,
//       },
//       {
//         en: 'American Samoa',
//         zh_CN: '萨摩亚',
//         zh_TW: '薩摩亞',
//         code: 'AS',
//         value: 684,
//       },
//       {
//         en: 'Andorra',
//         zh_CN: '安道尔共和国',
//         zh_TW: '安道爾共和國',
//         code: 'AD',
//         value: 376,
//       },
//       {
//         en: 'Angola',
//         zh_CN: '安哥拉',
//         zh_TW: '安哥拉',
//         code: 'AO',
//         value: 244,
//       },
//       {
//         en: 'Anguilla',
//         zh_CN: '安圭拉岛',
//         zh_TW: '安圭拉島',
//         code: 'AI',
//         value: 1264,
//       },
//       {
//         en: 'Antarctica',
//         zh_CN: '南极洲',
//         zh_TW: '南極洲',
//         code: 'AQ',
//         value: 672,
//       },
//       {
//         en: 'Antigua and Barbuda',
//         zh_CN: '安提瓜和巴布达',
//         zh_TW: '安提瓜和巴布達',
//         code: 'AG',
//         value: 1268,
//       },
//       {
//         en: 'Argentina',
//         zh_CN: '阿根廷',
//         zh_TW: '阿根廷',
//         code: 'AR',
//         value: 54,
//       },
//       {
//         en: 'Armenia',
//         zh_CN: '亚美尼亚',
//         zh_TW: '亞美尼亞',
//         code: 'AM',
//         value: 374,
//       },
//       { en: 'Aruba', zh_CN: '阿鲁巴', zh_TW: '阿魯巴', code: 'AW', value: 297 },
//       {
//         en: 'Australia',
//         zh_CN: '澳大利亚',
//         zh_TW: '澳大利亞',
//         code: 'AU',
//         value: 61,
//       },
//       {
//         en: 'Austria',
//         zh_CN: '奥地利',
//         zh_TW: '奧地利',
//         code: 'AT',
//         value: 43,
//       },
//       {
//         en: 'Azerbaijan',
//         zh_CN: '阿塞拜疆',
//         zh_TW: '阿塞拜疆',
//         code: 'AZ',
//         value: 994,
//       },
//     ],
//   },
//   {
//     prefix: 'B',
//     data: [
//       {
//         en: 'Bahamas',
//         zh_CN: '巴哈马',
//         zh_TW: '巴哈馬',
//         code: 'BS',
//         value: 1242,
//       },
//       { en: 'Bahrain', zh_CN: '巴林', zh_TW: '巴林', code: 'BH', value: 973 },
//       {
//         en: 'Bailiwick of Jersey',
//         zh_CN: '泽西岛',
//         zh_TW: '澤西島',
//         code: 'BJ',
//         value: 44,
//       },
//       {
//         en: 'Bangladesh',
//         zh_CN: '孟加拉国',
//         zh_TW: '孟加拉',
//         code: 'BD',
//         value: 880,
//       },
//       {
//         en: 'Barbados',
//         zh_CN: '巴巴多斯',
//         zh_TW: '巴巴多斯',
//         code: 'BB',
//         value: 1246,
//       },
//       {
//         en: 'Belarus',
//         zh_CN: '白俄罗斯',
//         zh_TW: '白俄羅斯',
//         code: 'BY',
//         value: 375,
//       },
//       {
//         en: 'Belgium',
//         zh_CN: '比利时',
//         zh_TW: '比利時',
//         code: 'BE',
//         value: 32,
//       },
//       {
//         en: 'Belize',
//         zh_CN: '伯利兹城',
//         zh_TW: '伯利茲城',
//         code: 'BZ',
//         value: 501,
//       },
//       { en: 'Benin', zh_CN: '贝宁', zh_TW: '貝寧', code: 'BJ', value: 229 },
//       {
//         en: 'Bermuda',
//         zh_CN: '百慕大',
//         zh_TW: '百慕大',
//         code: 'BM',
//         value: 1441,
//       },
//       { en: 'Bhutan', zh_CN: '不丹', zh_TW: '不丹', code: 'BT', value: 975 },
//       {
//         en: 'Bolivia',
//         zh_CN: '玻利维亚',
//         zh_TW: '玻利維亞',
//         code: 'BO',
//         value: 591,
//       },
//       {
//         en: 'Bosnia and Herzegovina',
//         zh_CN: '波斯尼亚和黑塞哥维那',
//         zh_TW: '波斯尼亞和黑塞哥維那',
//         code: 'BA',
//         value: 387,
//       },
//       {
//         en: 'Botswana',
//         zh_CN: '博茨瓦纳',
//         zh_TW: '博茨瓦納',
//         code: 'BW',
//         value: 267,
//       },
//       { en: 'Brazil', zh_CN: '巴西', zh_TW: '巴西', code: 'BR', value: 55 },
//       {
//         en: 'British Indian Ocean Territory',
//         zh_CN: '英属印度洋领地',
//         zh_TW: '英屬印度洋領地',
//         code: 'IO',
//         value: 246,
//       },
//       {
//         en: 'Brunei Darussalam',
//         zh_CN: '文莱达鲁萨兰国',
//         zh_TW: '汶萊達魯薩蘭國',
//         code: 'BN',
//         value: 673,
//       },
//       {
//         en: 'Bulgaria',
//         zh_CN: '保加利亚',
//         zh_TW: '保加利亞',
//         code: 'BG',
//         value: 359,
//       },
//       {
//         en: 'Burkina Faso',
//         zh_CN: '布基纳法索',
//         zh_TW: '布基納法索',
//         code: 'BF',
//         value: 226,
//       },
//       {
//         en: 'Burundi',
//         zh_CN: '布隆迪',
//         zh_TW: '布隆迪',
//         code: 'BI',
//         value: 257,
//       },
//     ],
//   },
//   {
//     prefix: 'C',
//     data: [
//       {
//         en: 'Cambodia',
//         zh_CN: '柬埔寨',
//         zh_TW: '柬埔寨',
//         code: 'KH',
//         value: 855,
//       },
//       {
//         en: 'Cameroon',
//         zh_CN: '喀麦隆',
//         zh_TW: '喀麥隆',
//         code: 'CM',
//         value: 237,
//       },
//       { en: 'Canada', zh_CN: '加拿大', zh_TW: '加拿大', code: 'CA', value: 1 },
//       {
//         en: 'Cape Verde',
//         zh_CN: '佛得角',
//         zh_TW: '佛得角',
//         code: 'CV',
//         value: 238,
//       },
//       {
//         en: 'Cayman Islands',
//         zh_CN: '开曼群岛',
//         zh_TW: '開曼群島',
//         code: 'KY',
//         value: 1345,
//       },
//       {
//         en: 'Central African Republic',
//         zh_CN: '中非共和国',
//         zh_TW: '中非共和國',
//         code: 'CF',
//         value: 236,
//       },
//       { en: 'Chad', zh_CN: '乍得', zh_TW: '乍得', code: 'TD', value: 235 },
//       { en: 'Chile', zh_CN: '智利', zh_TW: '智利', code: 'CL', value: 56 },
//       { en: 'China', zh_CN: '中国', zh_TW: '中國', code: 'CN', value: 86 },
//       {
//         en: 'Christmas Island',
//         zh_CN: '圣延岛',
//         zh_TW: '聖延島',
//         code: 'CX',
//         value: 61,
//       },
//       {
//         en: 'Cocos (Keeling) Islands',
//         zh_CN: '科科斯群岛',
//         zh_TW: '科科斯群島',
//         code: 'CC',
//         value: 61,
//       },
//       {
//         en: 'Colombia',
//         zh_CN: '哥伦比亚',
//         zh_TW: '哥倫比亞',
//         code: 'CO',
//         value: 57,
//       },
//       {
//         en: 'Comoros',
//         zh_CN: '科摩罗',
//         zh_TW: '科摩羅',
//         code: 'KM',
//         value: 269,
//       },
//       { en: 'Congo', zh_CN: '刚果', zh_TW: '剛果', code: 'CG', value: 242 },
//       {
//         en: 'Congo, The Democratic Republic Of The',
//         zh_CN: '刚果民主共和国',
//         zh_TW: '剛果民主共和國',
//         code: 'ZR',
//         value: 243,
//       },
//       {
//         en: 'Cook Islands',
//         zh_CN: '库克群岛',
//         zh_TW: '庫克群島',
//         code: 'CK',
//         value: 682,
//       },
//       {
//         en: 'Costa Rica',
//         zh_CN: '哥斯达黎加',
//         zh_TW: '哥斯大黎加',
//         code: 'CR',
//         value: 506,
//       },
//       {
//         en: "Cote D'Ivoire",
//         zh_CN: "Cote D'Ivoire",
//         zh_TW: "Cote D'Ivoire",
//         code: 'CI',
//         value: 225,
//       },
//       {
//         en: 'Croatia (local name: Hrvatska)',
//         zh_CN: '克罗地亚',
//         zh_TW: '克羅地亞',
//         code: 'HR',
//         value: 385,
//       },
//       { en: 'Cuba', zh_CN: '古巴', zh_TW: '古巴', code: 'CU', value: 53 },
//       {
//         en: 'Cyprus',
//         zh_CN: '塞浦路斯',
//         zh_TW: '賽普勒斯',
//         code: 'CY',
//         value: 357,
//       },
//       {
//         en: 'Czech Republic',
//         zh_CN: '捷克',
//         zh_TW: '捷克',
//         code: 'CZ',
//         value: 420,
//       },
//     ],
//   },
//   {
//     prefix: 'D',
//     data: [
//       { en: 'Denmark', zh_CN: '丹麦', zh_TW: '丹麥', code: 'DK', value: 45 },
//       {
//         en: 'Djibouti',
//         zh_CN: '吉布提',
//         zh_TW: '吉布提',
//         code: 'DJ',
//         value: 253,
//       },
//       {
//         en: 'Dominica',
//         zh_CN: '多米尼克国',
//         zh_TW: '多米尼克國',
//         code: 'DM',
//         value: 1767,
//       },
//       {
//         en: 'Dominican Republic',
//         zh_CN: '多米尼加共和国',
//         zh_TW: '多明尼加共和國',
//         code: 'DO',
//         value: 1849,
//       },
//     ],
//   },
//   {
//     prefix: 'E',
//     data: [
//       {
//         en: 'East Timor',
//         zh_CN: '东帝汶',
//         zh_TW: '東帝汶',
//         code: 'TP',
//         value: 670,
//       },
//       {
//         en: 'Ecuador',
//         zh_CN: '厄瓜多尔',
//         zh_TW: '厄瓜多爾',
//         code: 'EC',
//         value: 593,
//       },
//       { en: 'Egypt', zh_CN: '埃及', zh_TW: '埃及', code: 'EG', value: 20 },
//       {
//         en: 'El Salvador',
//         zh_CN: '萨尔瓦多',
//         zh_TW: '薩爾瓦多',
//         code: 'SV',
//         value: 503,
//       },
//       {
//         en: 'Equatorial Guinea',
//         zh_CN: '赤道几内亚',
//         zh_TW: '赤道幾內亞',
//         code: 'GQ',
//         value: 240,
//       },
//       {
//         en: 'Eritrea',
//         zh_CN: '厄立特里亚国',
//         zh_TW: '厄立特里亞國',
//         code: 'ER',
//         value: 291,
//       },
//       {
//         en: 'Estonia',
//         zh_CN: '爱沙尼亚',
//         zh_TW: '愛沙尼亞',
//         code: 'EE',
//         value: 372,
//       },
//       {
//         en: 'Ethiopia',
//         zh_CN: '埃塞俄比亚',
//         zh_TW: '埃塞俄比亞',
//         code: 'ET',
//         value: 251,
//       },
//     ],
//   },
//   {
//     prefix: 'F',
//     data: [
//       {
//         en: 'Falkland Islands (Malvinas)',
//         zh_CN: '福克兰群岛',
//         zh_TW: '福克蘭群島',
//         code: 'FK',
//         value: 500,
//       },
//       {
//         en: 'Faroe Islands',
//         zh_CN: '法罗群岛',
//         zh_TW: '法羅群島',
//         code: 'FO',
//         value: 298,
//       },
//       { en: 'Fiji', zh_CN: '斐济', zh_TW: '斐濟', code: 'FJ', value: 679 },
//       { en: 'Finland', zh_CN: '芬兰', zh_TW: '芬蘭', code: 'FI', value: 358 },
//       { en: 'France', zh_CN: '法国', zh_TW: '法國', code: 'FR', value: 33 },
//       {
//         en: 'France Metropolitan',
//         zh_CN: '法国大都会',
//         zh_TW: '法國大都會',
//         code: 'FX',
//         value: 33,
//       },
//       {
//         en: 'French Guiana',
//         zh_CN: '法属圭亚那',
//         zh_TW: '法屬圭亞那',
//         code: 'GF',
//         value: 594,
//       },
//       {
//         en: 'French Polynesia',
//         zh_CN: '法属玻里尼西亚',
//         zh_TW: '法屬玻裏尼西亞',
//         code: 'PF',
//         value: 689,
//       },
//     ],
//   },
//   {
//     prefix: 'G',
//     data: [
//       { en: 'Gabon', zh_CN: '加蓬', zh_TW: '加蓬', code: 'GA', value: 241 },
//       {
//         en: 'Gambia',
//         zh_CN: ' 冈比亚',
//         zh_TW: ' 岡比亞',
//         code: 'GM',
//         value: 220,
//       },
//       {
//         en: 'Georgia',
//         zh_CN: '格鲁吉亚',
//         zh_TW: '格魯吉亞',
//         code: 'GE',
//         value: 995,
//       },
//       { en: 'Germany', zh_CN: '德国', zh_TW: '德國', code: 'DE', value: 49 },
//       { en: 'Ghana', zh_CN: '加纳', zh_TW: '加納', code: 'GH', value: 233 },
//       {
//         en: 'Gibraltar',
//         zh_CN: '直布罗陀',
//         zh_TW: '直布羅陀',
//         code: 'GI',
//         value: 350,
//       },
//       { en: 'Greece', zh_CN: '希腊', zh_TW: '希臘', code: 'GR', value: 30 },
//       {
//         en: 'Greenland',
//         zh_CN: '格陵兰',
//         zh_TW: '格陵蘭',
//         code: 'GL',
//         value: 45,
//       },
//       {
//         en: 'Grenada',
//         zh_CN: '格林纳达',
//         zh_TW: '格林納達',
//         code: 'GD',
//         value: 1473,
//       },
//       {
//         en: 'Guadeloupe',
//         zh_CN: '瓜德罗普岛',
//         zh_TW: '瓜德羅普島',
//         code: 'GP',
//         value: 590,
//       },
//       { en: 'Guam', zh_CN: '关岛', zh_TW: '關島', code: 'GU', value: 1671 },
//       {
//         en: 'Guatemala',
//         zh_CN: '危地马拉',
//         zh_TW: '瓜地馬拉',
//         code: 'GT',
//         value: 502,
//       },
//       {
//         en: 'Guernsey',
//         zh_CN: '格恩西岛',
//         zh_TW: '格恩西島',
//         code: 'GG',
//         value: 441481,
//       },
//       {
//         en: 'Guinea',
//         zh_CN: '几内亚',
//         zh_TW: '幾內亞',
//         code: 'GN',
//         value: 224,
//       },
//       {
//         en: 'Guinea-Bissau',
//         zh_CN: '几内亚比绍',
//         zh_TW: '幾內亞比紹',
//         code: 'GW',
//         value: 245,
//       },
//       {
//         en: 'Guyana',
//         zh_CN: '圭亚那',
//         zh_TW: '圭亞那',
//         code: 'GY',
//         value: 592,
//       },
//     ],
//   },
//   {
//     prefix: 'H',
//     data: [
//       { en: 'Haiti', zh_CN: '海地', zh_TW: '海地', code: 'HT', value: 509 },
//       {
//         en: 'Honduras',
//         zh_CN: '洪都拉斯',
//         zh_TW: '洪都拉斯',
//         code: 'HN',
//         value: 504,
//       },
//       { en: 'Hong Kong', zh_CN: '香港', zh_TW: '香港', code: 'HK', value: 852 },
//       {
//         en: 'Hungary',
//         zh_CN: '匈牙利',
//         zh_TW: '匈牙利',
//         code: 'HU',
//         value: 36,
//       },
//     ],
//   },
//   {
//     prefix: 'I',
//     data: [
//       { en: 'Iceland', zh_CN: '冰岛', zh_TW: '冰島', code: 'IS', value: 354 },
//       { en: 'India', zh_CN: '印度', zh_TW: '印度', code: 'IN', value: 91 },
//       {
//         en: 'Indonesia',
//         zh_CN: '印度尼西亚',
//         zh_TW: '印尼',
//         code: 'ID',
//         value: 62,
//       },
//       {
//         en: 'Iran (Islamic Republic of)',
//         zh_CN: '伊朗（伊斯兰共和国）',
//         zh_TW: '伊朗（伊斯蘭共和國）',
//         code: 'IR',
//         value: 98,
//       },
//       { en: 'Iraq', zh_CN: '伊拉克', zh_TW: '伊拉克', code: 'IQ', value: 964 },
//       {
//         en: 'Ireland',
//         zh_CN: '爱尔兰',
//         zh_TW: '愛爾蘭',
//         code: 'IE',
//         value: 353,
//       },
//       {
//         en: 'Israel',
//         zh_CN: '以色列',
//         zh_TW: '以色列',
//         code: 'IL',
//         value: 972,
//       },
//       { en: 'Italy', zh_CN: '意大利', zh_TW: '義大利', code: 'IT', value: 39 },
//     ],
//   },
//   {
//     prefix: 'J',
//     data: [
//       {
//         en: 'Jamaica',
//         zh_CN: '牙买加',
//         zh_TW: '牙買加',
//         code: 'JM',
//         value: 1876,
//       },
//       { en: 'Japan', zh_CN: '日本', zh_TW: '日本', code: 'JP', value: 81 },
//       { en: 'Jordan', zh_CN: '约旦', zh_TW: '約旦', code: 'JO', value: 962 },
//     ],
//   },
//   {
//     prefix: 'K',
//     data: [
//       {
//         en: 'Kazakhstan',
//         zh_CN: '哈萨克',
//         zh_TW: '哈薩克',
//         code: 'KZ',
//         value: 7,
//       },
//       { en: 'Kenya', zh_CN: '肯尼亚', zh_TW: '肯雅', code: 'KE', value: 254 },
//       {
//         en: 'Kuwait',
//         zh_CN: '科威特',
//         zh_TW: '科威特',
//         code: 'KW',
//         value: 965,
//       },
//       {
//         en: 'Kyrgyzstan',
//         zh_CN: '吉尔吉斯',
//         zh_TW: '吉爾吉斯',
//         code: 'KG',
//         value: 996,
//       },
//     ],
//   },
//   {
//     prefix: 'L',
//     data: [
//       {
//         en: "Lao People's Democratic Republic",
//         zh_CN: '老挝',
//         zh_TW: '老撾',
//         code: 'LAO',
//         value: 856,
//       },
//       {
//         en: 'Latvia',
//         zh_CN: '拉脱维亚',
//         zh_TW: '拉脫維亞',
//         code: 'LV',
//         value: 371,
//       },
//       {
//         en: 'Lebanon',
//         zh_CN: '黎巴嫩',
//         zh_TW: '黎巴嫩',
//         code: 'LB',
//         value: 961,
//       },
//       {
//         en: 'Lesotho',
//         zh_CN: '莱索托',
//         zh_TW: '萊索托',
//         code: 'LS',
//         value: 266,
//       },
//       {
//         en: 'Liberia',
//         zh_CN: '利比里亚',
//         zh_TW: '利比理亞',
//         code: 'LR',
//         value: 231,
//       },
//       {
//         en: 'Libyan Arab Jamahiriya',
//         zh_CN: '利比亚',
//         zh_TW: '利比亞',
//         code: 'LY',
//         value: 218,
//       },
//       {
//         en: 'Liechtenstein',
//         zh_CN: '列支敦士登',
//         zh_TW: '列支敦士登',
//         code: 'LI',
//         value: 423,
//       },
//       {
//         en: 'Lithuania',
//         zh_CN: '立陶宛',
//         zh_TW: '立陶宛',
//         code: 'LT',
//         value: 370,
//       },
//       {
//         en: 'Luxembourg',
//         zh_CN: '卢森堡',
//         zh_TW: '盧森堡',
//         code: 'LU',
//         value: 352,
//       },
//     ],
//   },
//   {
//     prefix: 'M',
//     data: [
//       {
//         en: 'Macau',
//         zh_CN: '澳门地区',
//         zh_TW: '澳門地區',
//         code: 'MO',
//         value: 853,
//       },
//       {
//         en: 'Madagascar',
//         zh_CN: '马达加斯加',
//         zh_TW: '馬達加斯加',
//         code: 'MG',
//         value: 261,
//       },
//       {
//         en: 'Malawi',
//         zh_CN: '马拉维',
//         zh_TW: '馬拉維',
//         code: 'MW',
//         value: 265,
//       },
//       {
//         en: 'Malaysia',
//         zh_CN: '马来西亚',
//         zh_TW: '馬來西亞',
//         code: 'MY',
//         value: 60,
//       },
//       {
//         en: 'Maldives',
//         zh_CN: '马尔代夫',
//         zh_TW: '馬爾代夫',
//         code: 'MV',
//         value: 960,
//       },
//       { en: 'Mali', zh_CN: '马里', zh_TW: '馬里', code: 'ML', value: 223 },
//       { en: 'Malta', zh_CN: '马尔他', zh_TW: '馬爾他', code: 'MT', value: 356 },
//       {
//         en: 'Marshall Islands',
//         zh_CN: '马绍尔群岛',
//         zh_TW: '馬紹爾群島',
//         code: 'MH',
//         value: 692,
//       },
//       {
//         en: 'Martinique',
//         zh_CN: '马提尼克岛',
//         zh_TW: '馬提尼克島',
//         code: 'MQ',
//         value: 596,
//       },
//       {
//         en: 'Mauritania',
//         zh_CN: '毛里塔尼亚',
//         zh_TW: '茅利塔尼亞',
//         code: 'MR',
//         value: 222,
//       },
//       {
//         en: 'Mauritius',
//         zh_CN: '毛里求斯',
//         zh_TW: '毛里求斯',
//         code: 'MU',
//         value: 230,
//       },
//       {
//         en: 'Mayotte',
//         zh_CN: '马约特',
//         zh_TW: '馬約特',
//         code: 'YT',
//         value: 262,
//       },
//       { en: 'Mexico', zh_CN: '墨西哥', zh_TW: '墨西哥', code: 'MX', value: 52 },
//       {
//         en: 'Micronesia',
//         zh_CN: '密克罗尼西亚',
//         zh_TW: '密克羅尼西亞',
//         code: 'FM',
//         value: 691,
//       },
//       {
//         en: 'Moldova',
//         zh_CN: '摩尔多瓦',
//         zh_TW: '莫爾達瓦',
//         code: 'MD',
//         value: 373,
//       },
//       {
//         en: 'Monaco',
//         zh_CN: '摩纳哥',
//         zh_TW: '摩納哥',
//         code: 'MC',
//         value: 377,
//       },
//       {
//         en: 'Mongolia',
//         zh_CN: '外蒙古',
//         zh_TW: '外蒙古',
//         code: 'MN',
//         value: 976,
//       },
//       {
//         en: 'Montenegro',
//         zh_CN: '黑山共和国',
//         zh_TW: '黑山共和國',
//         code: 'MNE',
//         value: 382,
//       },
//       {
//         en: 'Montserrat',
//         zh_CN: '蒙特塞拉特',
//         zh_TW: '蒙特塞拉特',
//         code: 'MS',
//         value: 1664,
//       },
//       {
//         en: 'Morocco',
//         zh_CN: '摩洛哥',
//         zh_TW: '摩洛哥',
//         code: 'MA',
//         value: 212,
//       },
//       {
//         en: 'Mozambique',
//         zh_CN: '莫桑比克',
//         zh_TW: '莫三比克',
//         code: 'MZ',
//         value: 258,
//       },
//       { en: 'Myanmar', zh_CN: '缅甸', zh_TW: '緬甸', code: 'MM', value: 95 },
//     ],
//   },
//   {
//     prefix: 'N',
//     data: [
//       {
//         en: 'Namibia',
//         zh_CN: '那米比亚',
//         zh_TW: '那米比亞',
//         code: 'NA',
//         value: 264,
//       },
//       { en: 'Nauru', zh_CN: '瑙鲁', zh_TW: '瑙魯', code: 'NR', value: 674 },
//       { en: 'Nepal', zh_CN: '尼泊尔', zh_TW: '尼泊爾', code: 'NP', value: 977 },
//       {
//         en: 'Netherlands',
//         zh_CN: '荷兰',
//         zh_TW: '荷蘭',
//         code: 'NL',
//         value: 31,
//       },
//       {
//         en: 'Netherlands Antilles',
//         zh_CN: '荷兰安的列斯群岛',
//         zh_TW: '荷蘭安的列斯群島',
//         code: 'AN',
//         value: 599,
//       },
//       {
//         en: 'New Caledonia',
//         zh_CN: '新喀里多尼亚',
//         zh_TW: '新喀裏多尼亞',
//         code: 'NC',
//         value: 687,
//       },
//       {
//         en: 'New Zealand',
//         zh_CN: '新西兰',
//         zh_TW: '新西蘭',
//         code: 'NZ',
//         value: 64,
//       },
//       {
//         en: 'Nicaragua',
//         zh_CN: '尼加拉瓜',
//         zh_TW: '尼加拉瓜',
//         code: 'NI',
//         value: 505,
//       },
//       { en: 'Niger', zh_CN: '尼日尔', zh_TW: '尼日爾', code: 'NE', value: 227 },
//       {
//         en: 'Nigeria',
//         zh_CN: '尼日利亚',
//         zh_TW: '尼日利亞',
//         code: 'NG',
//         value: 234,
//       },
//       {
//         en: 'Norfolk Island',
//         zh_CN: '诺福克岛',
//         zh_TW: '諾福克島',
//         code: 'NF',
//         value: 6723,
//       },
//       {
//         en: 'North Korea',
//         zh_CN: '朝鲜',
//         zh_TW: '朝鮮',
//         code: 'KP',
//         value: 850,
//       },
//       {
//         en: 'Northern Mariana Islands',
//         zh_CN: '北马里亚纳群岛',
//         zh_TW: '北馬里亞納群島',
//         code: 'MP',
//         value: 1670,
//       },
//       { en: 'Norway', zh_CN: '挪威', zh_TW: '挪威', code: 'NO', value: 47 },
//     ],
//   },
//   {
//     prefix: 'O',
//     data: [
//       { en: 'Oman', zh_CN: '阿曼', zh_TW: '阿曼', code: 'OM', value: 968 },
//     ],
//   },
//   {
//     prefix: 'P',
//     data: [
//       {
//         en: 'Pakistan',
//         zh_CN: '巴基斯坦',
//         zh_TW: '巴基斯坦',
//         code: 'PK',
//         value: 92,
//       },
//       { en: 'Palau', zh_CN: '帛琉', zh_TW: '帛琉', code: 'PW', value: 680 },
//       {
//         en: 'Palestine',
//         zh_CN: '巴勒斯坦',
//         zh_TW: '巴勒斯坦',
//         code: 'PS',
//         value: 970,
//       },
//       {
//         en: 'Panama',
//         zh_CN: '巴拿马',
//         zh_TW: '巴拿馬',
//         code: 'PA',
//         value: 507,
//       },
//       {
//         en: 'Papua New Guinea',
//         zh_CN: '巴布亚新几内亚',
//         zh_TW: '巴布亞新磯內亞',
//         code: 'PG',
//         value: 675,
//       },
//       {
//         en: 'Paraguay',
//         zh_CN: '巴拉圭',
//         zh_TW: '巴拉圭',
//         code: 'PY',
//         value: 595,
//       },
//       { en: 'Peru', zh_CN: '秘鲁', zh_TW: '秘魯', code: 'PE', value: 51 },
//       {
//         en: 'Philippines',
//         zh_CN: '菲律宾共和国',
//         zh_TW: '菲律賓共和國',
//         code: 'PH',
//         value: 63,
//       },
//       {
//         en: 'Pitcairn',
//         zh_CN: '皮特凯恩岛',
//         zh_TW: '皮特凱恩島',
//         code: 'PN',
//         value: 64,
//       },
//       { en: 'Poland', zh_CN: '波兰', zh_TW: '波蘭', code: 'PL', value: 48 },
//       {
//         en: 'Portugal',
//         zh_CN: '葡萄牙',
//         zh_TW: '葡萄牙',
//         code: 'PT',
//         value: 351,
//       },
//       {
//         en: 'Puerto Rico',
//         zh_CN: '波多黎各',
//         zh_TW: '波多黎各',
//         code: 'PR',
//         value: 1787,
//       },
//     ],
//   },
//   {
//     prefix: 'Q',
//     data: [
//       { en: 'Qatar', zh_CN: '卡塔尔', zh_TW: '卡塔爾', code: 'QA', value: 974 },
//     ],
//   },
//   {
//     prefix: 'R',
//     data: [
//       {
//         en: 'Reunion',
//         zh_CN: '留尼汪岛',
//         zh_TW: '留尼汪島',
//         code: 'RE',
//         value: 262,
//       },
//       {
//         en: 'Romania',
//         zh_CN: '罗马尼亚',
//         zh_TW: '羅馬尼亞',
//         code: 'RO',
//         value: 40,
//       },
//       {
//         en: 'Russian Federation',
//         zh_CN: '俄罗斯联邦',
//         zh_TW: '俄羅斯聯邦',
//         code: 'RU',
//         value: 7,
//       },
//       {
//         en: 'Rwanda',
//         zh_CN: '卢旺达',
//         zh_TW: '盧旺達',
//         code: 'RW',
//         value: 250,
//       },
//     ],
//   },
//   {
//     prefix: 'S',
//     data: [
//       {
//         en: 'Saint Martin',
//         zh_CN: '圣马丁岛',
//         zh_TW: '聖馬丁島',
//         code: 'SX',
//         value: 590,
//       },
//       {
//         en: 'Saint Vincent and the Grenadines',
//         zh_CN: '圣文森特和格林纳丁斯',
//         zh_TW: '聖文森特和格林納丁斯',
//         code: 'VCT',
//         value: 1784,
//       },
//       {
//         en: 'Samoa',
//         zh_CN: '美属萨摩亚',
//         zh_TW: '美屬薩摩亞',
//         code: 'WS',
//         value: 685,
//       },
//       {
//         en: 'San Marino',
//         zh_CN: '圣马力诺共和国',
//         zh_TW: '聖馬力諾共和國',
//         code: 'SM',
//         value: 378,
//       },
//       {
//         en: 'Santa Luzia Island',
//         zh_CN: '圣卢西亚岛',
//         zh_TW: '聖盧西亞島',
//         code: '',
//         value: 1758,
//       },
//       {
//         en: 'Sao Tome and Principe',
//         zh_CN: '圣多美和普林西比',
//         zh_TW: '聖多美和普林西比',
//         code: 'STP',
//         value: 239,
//       },
//       {
//         en: 'Saudi Arabia',
//         zh_CN: '沙特阿拉伯',
//         zh_TW: '沙烏地阿拉伯',
//         code: 'SA',
//         value: 966,
//       },
//       {
//         en: 'Senegal',
//         zh_CN: '塞内加尔',
//         zh_TW: '塞內加爾',
//         code: 'SN',
//         value: 221,
//       },
//       {
//         en: 'Serbia',
//         zh_CN: '塞尔维亚共和国',
//         zh_TW: '塞爾維亞共和國',
//         code: 'SRB',
//         value: 381,
//       },
//       {
//         en: 'Seychelles',
//         zh_CN: '塞舌尔',
//         zh_TW: '塞舌耳',
//         code: 'SC',
//         value: 248,
//       },
//       {
//         en: 'Sierra Leone',
//         zh_CN: '塞拉利昂',
//         zh_TW: '塞拉里昂',
//         code: 'SL',
//         value: 232,
//       },
//       {
//         en: 'Singapore',
//         zh_CN: '新加坡',
//         zh_TW: '新加坡',
//         code: 'SG',
//         value: 65,
//       },
//       {
//         en: 'Slovakia (Slovak Republic)',
//         zh_CN: '斯洛伐克（斯洛伐克人的共和国）',
//         zh_TW: '斯洛伐克（斯洛伐克人的共和國）',
//         code: 'SK',
//         value: 421,
//       },
//       {
//         en: 'Slovenia',
//         zh_CN: '斯洛文尼亚',
//         zh_TW: '斯洛文尼亞',
//         code: 'SI',
//         value: 386,
//       },
//       {
//         en: 'Solomon Islands',
//         zh_CN: '索罗门群岛',
//         zh_TW: '索羅門群島',
//         code: 'SB',
//         value: 677,
//       },
//       {
//         en: 'Somalia',
//         zh_CN: '索马里',
//         zh_TW: '索馬里',
//         code: 'SO',
//         value: 252,
//       },
//       {
//         en: 'South Africa',
//         zh_CN: '南非',
//         zh_TW: '南非',
//         code: 'ZA',
//         value: 27,
//       },
//       {
//         en: 'South Korea',
//         zh_CN: '韩国',
//         zh_TW: '韓國',
//         code: 'KR',
//         value: 82,
//       },
//       { en: 'Spain', zh_CN: '西班牙', zh_TW: '西班牙', code: 'ES', value: 34 },
//       {
//         en: 'Sri Lanka',
//         zh_CN: '斯里兰卡',
//         zh_TW: '斯里蘭卡',
//         code: 'LK',
//         value: 94,
//       },
//       { en: 'Sudan', zh_CN: '苏丹', zh_TW: '蘇丹', code: 'SD', value: 249 },
//       {
//         en: 'Suriname',
//         zh_CN: '苏里南',
//         zh_TW: '蘇里南',
//         code: 'SR',
//         value: 597,
//       },
//       {
//         en: 'Swaziland',
//         zh_CN: '斯威士兰',
//         zh_TW: '斯威士蘭',
//         code: 'SZ',
//         value: 268,
//       },
//       { en: 'Sweden', zh_CN: '瑞典', zh_TW: '瑞典', code: 'SE', value: 46 },
//       {
//         en: 'Switzerland',
//         zh_CN: '瑞士',
//         zh_TW: '瑞士',
//         code: 'CH',
//         value: 41,
//       },
//       {
//         en: 'Syrian Arab Republic',
//         zh_CN: '叙利亚',
//         zh_TW: '敘利亞',
//         code: 'SY',
//         value: 963,
//       },
//     ],
//   },
//   {
//     prefix: 'T',
//     data: [
//       {
//         en: 'Taiwan',
//         zh_CN: '台湾地区',
//         zh_TW: '臺灣地區',
//         code: 'TW',
//         value: 886,
//       },
//       {
//         en: 'Tajikistan',
//         zh_CN: '塔吉克',
//         zh_TW: '塔吉克',
//         code: 'TJ',
//         value: 992,
//       },
//       {
//         en: 'Tanzania',
//         zh_CN: '坦桑尼亚',
//         zh_TW: '坦桑尼亞',
//         code: 'TZ',
//         value: 255,
//       },
//       { en: 'Thailand', zh_CN: '泰国', zh_TW: '泰國', code: 'TH', value: 66 },
//       {
//         en: 'The Federation of Saint Kitts and Nevis',
//         zh_CN: '圣基茨和尼维斯',
//         zh_TW: '聖基茨和尼維斯',
//         code: 'KNA',
//         value: 1869,
//       },
//       {
//         en: 'the Republic of Abkhazia',
//         zh_CN: '阿布哈兹',
//         zh_TW: '阿布哈茲',
//         code: 'ABH',
//         value: 7,
//       },
//       {
//         en: 'The Republic of Macedonia',
//         zh_CN: '马其顿',
//         zh_TW: '馬其頓',
//         code: 'MKD',
//         value: 389,
//       },
//       {
//         en: 'the Republic of South Ossetia',
//         zh_CN: '南奥赛梯',
//         zh_TW: '南奧賽梯',
//         code: 'SO',
//         value: 7,
//       },
//       {
//         en: 'The Republic of South Sudan',
//         zh_CN: '南苏丹共和国',
//         zh_TW: '南蘇丹共和國',
//         code: 'SSD',
//         value: 211,
//       },
//       { en: 'Togo', zh_CN: '多哥', zh_TW: '多哥', code: 'TG', value: 228 },
//       {
//         en: 'Tokelau',
//         zh_CN: '托克劳',
//         zh_TW: '托克勞',
//         code: 'TK',
//         value: 690,
//       },
//       { en: 'Tonga', zh_CN: '汤加', zh_TW: '湯加', code: 'TO', value: 676 },
//       {
//         en: 'Trinidad and Tobago',
//         zh_CN: '特立尼达和多巴哥',
//         zh_TW: '特立尼達和多巴哥',
//         code: 'TT',
//         value: 1868,
//       },
//       {
//         en: 'Tunisia',
//         zh_CN: '突尼斯',
//         zh_TW: '突尼斯',
//         code: 'TN',
//         value: 216,
//       },
//       { en: 'Turkey', zh_CN: '土耳其', zh_TW: '土耳其', code: 'TR', value: 90 },
//       {
//         en: 'Turkmenistan',
//         zh_CN: '土库曼',
//         zh_TW: '土庫曼',
//         code: 'TM',
//         value: 993,
//       },
//       {
//         en: 'Turks and Caicos Islands',
//         zh_CN: '土克斯及开科斯群岛',
//         zh_TW: '土克斯及開科斯群島',
//         code: 'TC',
//         value: 1809,
//       },
//       {
//         en: 'Tuvalu',
//         zh_CN: '图瓦卢',
//         zh_TW: '圖瓦盧',
//         code: 'TV',
//         value: 688,
//       },
//     ],
//   },
//   {
//     prefix: 'U',
//     data: [
//       {
//         en: 'Uganda',
//         zh_CN: '乌干达',
//         zh_TW: '烏干達',
//         code: 'UG',
//         value: 256,
//       },
//       {
//         en: 'Ukraine',
//         zh_CN: '乌克兰',
//         zh_TW: '烏克蘭',
//         code: 'UA',
//         value: 380,
//       },
//       {
//         en: 'United Arab Emirates',
//         zh_CN: '阿拉伯联合酋长国',
//         zh_TW: '阿拉伯聯合酋長國',
//         code: 'AE',
//         value: 971,
//       },
//       {
//         en: 'United Kingdom',
//         zh_CN: '英国',
//         zh_TW: '英國',
//         code: 'UK',
//         value: 44,
//       },
//       {
//         en: 'United States',
//         zh_CN: '美国',
//         zh_TW: '美國',
//         code: 'US',
//         value: 1,
//       },
//       {
//         en: 'Uruguay',
//         zh_CN: '乌拉圭',
//         zh_TW: '烏拉圭',
//         code: 'UY',
//         value: 598,
//       },
//       {
//         en: 'Uzbekistan',
//         zh_CN: '乌兹别克斯坦',
//         zh_TW: '烏茲別克斯坦',
//         code: 'UZ',
//         value: 998,
//       },
//     ],
//   },
//   {
//     prefix: 'V',
//     data: [
//       {
//         en: 'Vanuatu',
//         zh_CN: '瓦努阿图',
//         zh_TW: '瓦努阿圖',
//         code: 'VU',
//         value: 678,
//       },
//       {
//         en: 'Vatican City State (Holy See)',
//         zh_CN: '梵蒂冈(罗马教廷)',
//         zh_TW: '梵蒂岡(羅馬教廷)',
//         code: 'VA',
//         value: 39,
//       },
//       {
//         en: 'Venezuela',
//         zh_CN: '委内瑞拉',
//         zh_TW: '委內瑞拉',
//         code: 'VE',
//         value: 58,
//       },
//       { en: 'Vietnam', zh_CN: '越南', zh_TW: '越南', code: 'VN', value: 84 },
//       {
//         en: 'Virgin Islands (British)',
//         zh_CN: '维尔京群岛(英国)',
//         zh_TW: '維爾京群島(英國)',
//         code: 'VG',
//         value: 1284,
//       },
//       {
//         en: 'Virgin Islands (U.S.)',
//         zh_CN: '维尔京群岛(美国)',
//         zh_TW: '維爾京群島(美國)',
//         code: 'VI',
//         value: 1340,
//       },
//     ],
//   },
//   {
//     prefix: 'W',
//     data: [
//       {
//         en: 'Wallis And Futuna Islands',
//         zh_CN: '沃利斯和富图纳群岛',
//         zh_TW: '沃利斯和富圖納群島',
//         code: 'WF',
//         value: 681,
//       },
//       {
//         en: 'Western Sahara',
//         zh_CN: '西撒哈拉',
//         zh_TW: '西撒哈拉',
//         code: 'EH',
//         value: 685,
//       },
//     ],
//   },
//   {
//     prefix: 'Y',
//     data: [
//       { en: 'Yemen', zh_CN: '也门', zh_TW: '葉門', code: 'YE', value: 967 },
//       {
//         en: 'Yugoslavia',
//         zh_CN: '南斯拉夫',
//         zh_TW: '南斯拉夫',
//         code: 'YU',
//         value: 381,
//       },
//     ],
//   },
//   {
//     prefix: 'Z',
//     data: [
//       {
//         en: 'Zambia',
//         zh_CN: '赞比亚',
//         zh_TW: '尚比亞',
//         code: 'ZM',
//         value: 260,
//       },
//       {
//         en: 'Zimbabwe',
//         zh_CN: '津巴布韦',
//         zh_TW: '辛巴威',
//         code: 'ZW',
//         value: 263,
//       },
//     ],
//   },
// ]

export default AreaData
