import React from 'react';
import ReactDOM from 'react-dom'
import Header from '../../component/header'
// import RentNav from './component/rentNav'
import HomeList from './component/homeList'
// import Showcase from './component/showcase'
// import FloatAds from './component/floatAds'
// import BottomAd from './component/bottomAd'
import CouponList from '../../component/couponList'
import HomeLuckyBag from './component/homeLuckyBag'
import HomePanel from './component/homePanel'
import HomeSeckill from './component/homeSeckill'
import GTip from '../../component/gTip'
import FloatMenu from '../../component/floatMenu'
// import ModalAds from '../../component/modalAds'
import { AppContext, defaultState } from '../../config/context'
import '../../common/util/channel'
import '../../common/util/polyfill'
import initReportEnv from '../../common/util/report'

initReportEnv('首页')

class App extends React.Component {
    constructor() {
        super()
        this.updateUserInfo = user => {
            this.setState(state => ({
                user: {
                    ...state.user,
                    ...user
                }
            }))
        }
        this.state = {
            ...defaultState,
            updateUserInfo: this.updateUserInfo
        }
    }

    render() {
        return <AppContext.Provider value={this.state}>
            <Header></Header>
            <HomePanel></HomePanel>
            <HomeSeckill></HomeSeckill>
            <HomeLuckyBag></HomeLuckyBag>
            <CouponList type={'index'}></CouponList>
            <HomeList></HomeList>
            <GTip></GTip>
            <FloatMenu></FloatMenu>
        </AppContext.Provider>
    }
}

ReactDOM.render(<App />, document.getElementById('dokodemodoa'))