import React from 'react'
import { AppContext } from '../../config/context'
import { getImgUrl, i18n, getLevelClassName, getLevelDisplayName, parseCDKs, copyByTAId, hbsBoolean } from '../../common/util'
import ee, { EVENT_TIP } from '../../common/util/event'
import { deliverByOrderId, getServiceUrl, getOrderCDK } from '../service'
import { getPocketDetail, getDicByName } from './service'
import './pocketDetail.scss'
import PocketDeliver from './pocketDeliver'

const SteamLink = 'https://store.steampowered.com/account/registerkey'
const SteamLoginLink = 'https://store.steampowered.com/login'
const getTitleByProductType = productType => {
    return { ACCOUNT: '账号密码', GAMETIME: '时长', RAPIDTIME: '速进时效' }[productType] || '卡密'
}
const getTipByProductType = productType => {
    return { ACCOUNT: '打开后可查看账号密码', GAMETIME: '打开后发放', RAPIDTIME: '打开后立即生效' }[productType] || '打开后可查看卡密'
}
const CDKTAID = 'pocket-cdk-hidden-input'
const ORDERTAID = 'order-hidden-input'
const Product_recharge = 'RECHAGRE'

class PocketDetail extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            detail: null,
            showRecycleWarning: false,
            showDelivering: false,
            showDeliverFailed: false,
            showDeliverForm: false,
            descArr: []
        }
        this.deliverFlag = false
    }

    componentDidMount() {
        const { pocketDetailId } = this.props
        getPocketDetail(pocketDetailId).then(detail => {
            this.setState({
                detail
            })
            detail.status === 11 && getOrderCDK(pocketDetailId)
            getDicByName(detail.productType).then(data => {
                if (data && Array.isArray(data.contents) && data.contents.length) {
                    this.setState({
                        descArr: data.contents
                    })
                }
            })
        }).catch(e => {
            e.code !== 401 && ee.emit(EVENT_TIP, e.message)
            this.props.close()
        })
    }

    renderTitle = productType => {
        if (productType === Product_recharge) {
            return null
        }
        const { lang } = this.context.user
        return <p className='pocket-cdk-title'>{i18n(getTitleByProductType(productType) + '：', lang)}</p>
    }

    renderTip = (status, productType) => {
        const { lang } = this.context.user
        if (status === 12) {
            return <p className='pocket-cdk-tip warning'>{i18n('奖品发货失败，请稍后再试或直接联系在线客服。', lang)}</p>
        }
        if (status === 11 || productType === Product_recharge) {
            return null
        }
        return <p className='pocket-cdk-tip'>{i18n(getTipByProductType(productType), lang)}</p>
    }

    renderCDK = (cdks, productType) => {
        if (productType === Product_recharge) {
            return null
        }
        if (!cdks || (Array.isArray(cdks) && !cdks.length)) {
            return <div className='pocket-cdk-wrapper'>****_****_****_****</div>
        }
        const { showCDKContent, copyCDKContent } = parseCDKs(Array.isArray(cdks) ? cdks : [cdks])
        if (showCDKContent.length === 1 && showCDKContent[0].length === 1 && showCDKContent[0][0].header === null) {
            return <div className='pocket-cdk-wrapper'>
                {showCDKContent[0][0].content}
                <i className='copy-icon' onClick={this.copyCDK}></i>
                <textarea name={CDKTAID} className={CDKTAID} id={CDKTAID} value={copyCDKContent[0]}></textarea>
            </div>
        }
        const { lang } = this.context.user
        return <div className='pocket-multi-cdk-wrapper'>
            {
                showCDKContent.map((item, i) => (<div>
                    {
                        i > 0 && item[0].header && <div className='cdk-split'></div>
                    }
                    {
                        item.map(row => (row.header
                            ? <p><span className='cdk-header'>{i18n(row.header, lang)}：</span>{row.content}</p>
                            : <p className='cdk-content'>{row.content}</p>))
                    }
                </div>))
            }
            <i className='copy-icon' onClick={this.copyCDK}></i>
            <textarea name={CDKTAID} className={CDKTAID} id={CDKTAID} value={copyCDKContent.join('\n')}></textarea>
        </div>
    }

    renderAction = (status, productType) => {
        const { lang } = this.context.user
        if (status === 10) {
            return <button className='pocket-action-btn disable'>{i18n('发货中', lang)}</button>
        }
        if (status === 11) {
            if (productType === 'CDK') {
                return <button className='pocket-action-btn' onClick={this.goToSteam}>{i18n('去Steam激活', lang)}</button>
            }
            if (productType === 'ACCOUNT') {
                return <button className='pocket-action-btn' onClick={this.goToSteamForAccount}>{i18n('去Steam登录', lang)}</button>
            }
            if (productType === Product_recharge) {
                return <button className='pocket-action-btn disabled'>{i18n('已使用', lang)}</button>
            }
        } else if (productType === 'CDK' || productType === 'ACCOUNT') {
            return <button className='pocket-action-btn' onClick={this.deliver}>{i18n('打开商品', lang)}</button>
        } else if (productType === Product_recharge) {
            return <button className='pocket-action-btn' onClick={this.goToRecharge}>{i18n('立即使用', lang)}</button>
        }
        return null
    }

    renderHelper = () => {
        const { lang } = this.context.user
        const { descArr } = this.state
        if (!descArr.length) {
            return null
        }
        return <div className='cdk-help-wrapper'>
            <p className='help-title'>{i18n(descArr[0], lang)}</p>
            <div className='help-content'>
                {
                    descArr.slice(1, descArr.length - 1).map(d => (<p className='help-p'>{i18n(d, lang)}</p>))
                }
            </div>
            <p className='help-warn'>{i18n(descArr[descArr.length - 1], lang)}</p>
        </div>
    }

    showRecycleWarning = () => {
        this.setState({
            showRecycleWarning: true
        })
    }

    closeRecycleWarning = () => {
        this.setState({
            showRecycleWarning: false
        })
    }

    showDelivering = () => {
        this.setState({
            showDelivering: true
        })
    }

    closeDelivering = () => {
        this.setState({
            showDelivering: false
        })
    }

    showDeliverFailed = () => {
        this.setState({
            showDeliverFailed: true
        })
    }

    closeDeliverFailed = () => {
        this.setState({
            showDeliverFailed: false
        })
    }

    closeDeliverForm = () => {
        this.setState({
            showDeliverForm: false
        })
    }

    goToSteam = e => {
        window.open(SteamLink)
        _sr('去Steam激活', '奖品详情-奖品详情', '4', '', 'pocket-item-steam', e)
    }

    goToSteamForAccount = e => {
        window.open(SteamLoginLink)
        _sr('去Steam登录', '奖品详情-奖品详情', '5', '', 'pocket-item-login-steam', e)
    }

    goToRecycle = e => {
        _sr('不想要了', '奖品详情-奖品详情', '3', '', 'pocket-item-recycle', e)
        this.props.goToRecycle([this.state.detail])
    }

    goToRecharge = e => {
        _sr('立即使用', '奖品详情-奖品详情', '6', '', 'pocket-item-recharge', e)
        this.setState({
            showDeliverForm: true
        })
    }

    copyCDK = e => {
        copyByTAId(CDKTAID)
        _sr('复制', '奖品详情-奖品详情', '1', '', 'pocket-item-copy', e)
    }

    deliver = e => {
        const { recycleStatus } = this.state.detail
        if (recycleStatus > 0) {
            this.showRecycleWarning()
        } else {
            this.confirmDeliver()
        }
        _sr('打开商品', '奖品详情-奖品详情', '2', '', 'pocket-item-deliver', e)
    }

    confirmDeliverByUser = e => {
        this.confirmDeliver()
        _sr('确定打开', '奖品详情-确定打开', '弹窗', '', 'pocket-item-deliver-confirm', e)
    }

    cancelDeliver = e => {
        this.closeRecycleWarning()
        _sr('等会再来', '奖品详情-确定打开', '弹窗', '', 'pocket-item-deliver-cancel', e)
    }

    confirmDeliver = () => {
        this.closeRecycleWarning()
        if (this.deliverFlag) {
            return
        }
        this.deliverFlag = true
        deliverByOrderId(this.props.pocketDetailId, 0).finally(() => {
            setTimeout(() => {
                this.checkDeliverStatus()
            }, 1000)
        })
    }

    deliverWithForm = () => {
        this.closeDeliverForm()
        setTimeout(() => {
            this.checkDeliverStatus()
        }, 1000)
    }

    checkDeliverStatus = () => {
        const { pocketDetailId } = this.props
        getPocketDetail(pocketDetailId).then(detail => {
            const { status, recycleStatus, openDate, recycleDate, cdks } = detail
            if (status === 11) {
                ee.emit(EVENT_TIP, '打开成功', 'success')
                getOrderCDK(pocketDetailId)
            } else if (status === 12) {
                this.showDeliverFailed()
            } else {
                this.showDelivering()
            }
            this.setState({
                detail
            })
            this.props.updatePocket({ status, recycleStatus, openDate, recycleDate, cdks })
        }).catch(e => {
            e.code !== 401 && ee.emit(EVENT_TIP, e.message)
        }).finally(() => {
            this.deliverFlag = false
        })
    }

    contactService = () => {
        getServiceUrl().then(href => {
            window.open(href)
        })
    }

    render() {
        const { detail, showRecycleWarning, showDelivering, showDeliverFailed, showDeliverForm } = this.state
        const { lang } = this.context.user
        const { pocketDetailId, close } = this.props
        return <div className='pocket-detail-container'>
            <div className='pocket-detail-wrapper'>
                <i className='pocket-detail-close' onClick={close}></i>
                {
                    showDeliverForm
                        ? <div className='pocket-detail-header'>
                            <span className='back-anchor' onClick={this.closeDeliverForm}><i className='back-icon'></i>{i18n('填写账号信息', lang)}</span>
                        </div>
                        : <div className='pocket-detail-header'>{i18n('奖品详情', lang)}</div>
                }
                {
                    showDeliverForm && <PocketDeliver goodsInfo={detail} pocketDetailId={pocketDetailId} deliverWithForm={this.deliverWithForm}></PocketDeliver>
                }
                <div className={showDeliverForm ? 'pocket-detail-body hidden' : 'pocket-detail-body'}>
                    {
                        detail && <div className='pocket-detail-content'>
                            <div className='pocket-detail-info'>
                                <div className='pocket-detail-img-wrapper'>
                                    {
                                        hbsBoolean(detail.pcMhImgUrl) && <img alt='' className='pocket-detail-img' src={getImgUrl(detail.pcMhImgUrl)}></img>
                                    }
                                </div>
                                <p className='pocket-detail-name'>{i18n(detail.name, lang)}</p>
                                <div className={`pocket-detail-level ${getLevelClassName(detail.borderColor)}`}>{i18n(getLevelDisplayName(detail.borderColor), lang)}</div>
                                <p className='pocket-detail-remark'>{i18n(`方式：${detail.remark}`, lang)}</p>
                                <p className='pocket-detail-time'>{i18n(`时间：${detail.payTime}`, lang)}</p>
                            </div>
                            {
                                this.renderTitle(detail.productType)
                            }
                            {
                                this.renderTip(detail.status, detail.productType)
                            }
                            {
                                this.renderCDK(detail.cdks, detail.productType)
                            }
                            {
                                this.renderAction(detail.status, detail.productType)
                            }
                            {
                                detail.status !== 11 && detail.status !== 10 && detail.recycleStatus > 0 && <button className='pocket-recycle-btn' onClick={this.goToRecycle}>{i18n('不想要了', lang)}</button>
                            }
                            {
                                this.renderHelper()
                            }
                            {
                                !!(detail.slyYoungContent || detail.content) && <div className='pocket-detail-desc-wrapper'>
                                    <p className='pocket-detail-desc-title'>{i18n('游戏简介', lang)}</p>
                                    <div className='pocket-detail-desc-content' dangerouslySetInnerHTML={{ __html: i18n(detail.slyYoungContent || detail.content, lang) }}></div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            {
                showRecycleWarning && <div className='pocket-detail-modal-container'>
                    <div className='pocket-detail-modal'>
                        <i className='pocket-detail-modal-close' onClick={this.closeRecycleWarning}></i>
                        <i className='pocket-detail-modal-icon'></i>
                        <p className='pocket-detail-modal-title'>{i18n('提示', lang)}</p>
                        <p className='pocket-detail-modal-tip first-line'>{i18n('物品打开后将', lang)}</p>
                        <p className='pocket-detail-modal-tip'>{i18n('无法进行官方回收操作', lang)}</p>
                        <button className='pocket-detail-modal-btn' onClick={this.confirmDeliverByUser}>{i18n('确定打开', lang)}</button>
                        <button className='pocket-detail-modal-btn cancel' onClick={this.cancelDeliver}>{i18n('等会再来', lang)}</button>
                    </div>
                </div>
            }
            {
                showDelivering && <div className='pocket-detail-modal-container'>
                    <div className='pocket-detail-modal'>
                        <i className='pocket-detail-modal-close' onClick={this.closeDelivering}></i>
                        <i className='pocket-detail-modal-icon ing'></i>
                        <p className='pocket-detail-modal-title'>{i18n('发货中', lang)}</p>
                        <p className='pocket-detail-modal-tip first-line with-dot'>{i18n('商品发货一般需要5-10秒；', lang)}</p>
                        <p className='pocket-detail-modal-tip with-dot'>{i18n('若长时间未收到货，请联系在线客服', lang)}</p>
                        <button className='pocket-detail-modal-btn' onClick={this.closeDelivering}>{i18n('我知道了', lang)}</button>
                    </div>
                </div>
            }
            {
                showDeliverFailed && <div className='pocket-detail-modal-container'>
                    <div className='pocket-detail-modal'>
                        <i className='pocket-detail-modal-close' onClick={this.closeDeliverFailed}></i>
                        <i className='pocket-detail-modal-icon failed'></i>
                        <p className='pocket-detail-modal-title'>{i18n('打开失败', lang)}</p>
                        <p className='pocket-detail-modal-tip first-line long'>{i18n('商品库存不足，您可以复制下方订单号，点击“联系客服”补货', lang)}</p>
                        <div className='copy-order-wrapper'>
                            {pocketDetailId}
                            <i className='copy-icon' onClick={() => copyByTAId(ORDERTAID)}></i>
                            <textarea name={ORDERTAID} className={ORDERTAID} id={ORDERTAID} value={pocketDetailId}></textarea>
                        </div>
                        <button className='pocket-detail-modal-btn' onClick={this.contactService}>{i18n('联系客服', lang)}</button>
                        <button className='pocket-detail-modal-btn cancel' onClick={this.closeDeliverFailed}>{i18n('等会再来', lang)}</button>
                    </div>
                </div>
            }
        </div>
    }
}

PocketDetail.contextType = AppContext
export default PocketDetail