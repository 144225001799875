import React from 'react'
import { BaseUrl, ShareDomains } from '../../config'
import {
    generateGUID,
    safeParse,
    parseQueryString,
    saveUserInfoLocally,
    savePhoneLocally,
    keyboardEnter,
    getCookie,
    delCookie,
    i18n,
    getImgUrl,
    getQQLoginLink,
    getWechatLoginLink,
    checkPhoneLocally
} from '../../common/util'
import { AppContext } from '../../config/context'
import { sendMessage as sendMessageByService, loginBySms, sendMessage_v2, loginByOpenId, bindPhone_v2, geeTest } from './service'
// import { getWindowData } from '../service'
import ee, { EVENT_USER_LOGIN, EVENT_TIP, EVENT_USER_NOT_CERTED } from '../../common/util/event'
import AreaData from './area'
import './index.scss'

class Login extends React.Component {
    constructor() {
        super(...arguments)
        this.uuid = generateGUID()
        this.submitFlag = false
        this.smsFlag = false
        this.smsTimer = null
        this.ttTimer = null
        this.state = {
            captchaSrc: this.getCaptchaSrc(),
            smsTxt: '获取验证码',
            isSmsCD: false,
            phone: '',
            captcha: '',
            smsCode: '',
            error: '',
            step: this.context.user.isLogin === 1 && checkPhoneLocally() ? 2 : 1,
            agree: false,
            success: false,
            shareUrl: '',
            areacode: 86,
            showAreaDrop: false,
            phoneErr: false,
            captchaErr: false,
            smsErr: false,
            ad: (window._inject_app_data_ || {}).loginAd || null,
            showAgreeTip: false
        }
        this.isListen = false
        this.shareCount = 0
    }

    componentDidMount() {
        // getWindowData('login_ad', 2).then(content => {
        //     content && content[0] && this.setState({ ad: content[0] })
        // })
        document.addEventListener('click', this.dbListener)
    }

    componentWillUnmount() {
        this.stopListen()
        if (this.smsTimer) {
            clearInterval(this.smsTimer)
        }
        document.removeEventListener('click', this.dbListener)
        window._imp = void 0
        window._impc = void 0
    }

    onChildMessage(data) {
        this.stopListen()
        const params = parseQueryString(data)
        loginByOpenId(params).then(({ uid, token, phone, displayName, age, isAuth, ucuid }) => {
            this.loginCallback({ uid, token, phone, displayName, age, isAuth, ucuid })
        }).catch(e => {
            this.setState({
                error: e.message
            })
        })
    }

    stopListen() {
        if (this.isListen) {
            this.isListen = false
            clearTimeout(this.ttTimer)
            window.removeEventListener('message', this.messageHandler)
        }
    }

    messageHandler = message => {
        const data = safeParse(message.data)
        if (data && data.type === 'LOGIN_CALLBACK') {
            this.onChildMessage(data.data)
        }
    }

    startListen() {
        if (this.isListen) {
            return
        }
        this.isListen = true
        window.addEventListener('message', this.messageHandler)
        const checkLocalTT = () => {
            this.ttTimer = setTimeout(() => {
                const tempToken = getCookie('_gltt')
                if (tempToken) {
                    delCookie('_gltt')
                    this.onChildMessage(decodeURIComponent(tempToken))
                } else {
                    checkLocalTT()
                }
            }, 1000)
        }
        delCookie('_gltt')
        checkLocalTT()
    }

    getCaptchaSrc() {
        return `${BaseUrl}/user/login/captcha.jpg?uuid=${this.uuid}&_=${Date.now()}`
    }

    refreshCaptcha = () => {
        this.setState({
            captchaSrc: this.getCaptchaSrc()
        })
    }

    inputPhone = e => {
        this.setState({
            phone: e.target.value,
            phoneErr: false
        })
    }

    inputCaptcha = e => {
        this.setState({
            captcha: e.target.value,
            captchaErr: false
        })
    }

    inputSmsCode = e => {
        this.setState({
            smsCode: e.target.value,
            smsErr: false
        })
    }

    checkPhone() {
        const { phone } = this.state
        if (!phone) {
            this.setState({
                error: '请输入手机号',
                phoneErr: true
            })
            return false
        }
        if (!/^\d+$/.test(phone)) {
            this.setState({
                error: '手机号不正确',
                phoneErr: true
            })
            return false
        }
        return true
    }

    checkCaptcha() {
        const { captcha } = this.state
        if (!captcha) {
            this.setState({
                error: '请输入图片验证码',
                captchaErr: true
            })
            return false
        }
        return true
    }

    checkSmsCode() {
        const { smsCode } = this.state
        if (!smsCode) {
            this.setState({
                error: '请输入短信验证码',
                smsErr: true
            })
            return false
        }
        return true
    }

    checkAgree() {
        if (!this.state.agree) {
            // const tip = '请阅读并同意《服务协议》和《隐私政策》'
            // ee.emit(EVENT_TIP, tip, 'warn')
            // this.setState({
            //     error: tip
            // })
            this.setState({
                showAgreeTip: true
            })
            return false
        }
        return true
    }

    sendMessage = () => {
        if (this.checkPhone() && !this.smsFlag) {
            this.smsFlag = true
            this.setState({
                error: ''
            })
            geeTest().then(data => {
                initGeetest({
                    gt: data.gt,
                    challenge: data.challenge,
                    offline: !data.success,
                    new_captcha: true,
                    product: 'bind'
                }, captchaObj => {
                    captchaObj.onReady(() => {
                        captchaObj.verify()
                    })
                    captchaObj.onSuccess(() => {
                        let ret = captchaObj.getValidate()
                        sendMessage_v2(this.state.areacode + this.state.phone, this.uuid, ret.geetest_seccode, ret.geetest_challenge, ret.geetest_validate).then(() => {
                            let counter = 60
                            this.setState({
                                isSmsCD: true,
                                smsTxt: `已发送(${counter})`
                            })
                            this.smsTimer = setInterval(() => {
                                counter--
                                if (counter > 0) {
                                    this.setState({
                                        smsTxt: `已发送(${counter})`
                                    })
                                } else {
                                    this.setState({
                                        isSmsCD: false,
                                        smsTxt: '获取验证码'
                                    })
                                    clearInterval(this.smsTimer)
                                    this.smsTimer = null
                                }
                            }, 1000)
                            captchaObj.destroy()
                        }).catch(e => {
                            this.setState({
                                error: e.message
                            })
                            captchaObj.reset()
                        }).finally(() => {
                            this.smsFlag = false
                        })
                    }).onError(e => {
                        this.setState({
                            error: e.user_error || e.msg
                        })
                        this.smsFlag = false
                    })
                })
            }).catch(e => {
                this.setState({
                    error: e.message
                })
                this.smsFlag = false
            })
        }
    }

    submit = () => {
        const { step } = this.state
        if (this.checkPhone() && this.checkSmsCode() && (step !== 1 || this.checkAgree()) && !this.submitFlag) {
            this.submitFlag = true
            this.setState({
                error: ''
            })
            const action = step === 2
                ? bindPhone_v2(this.state.areacode + this.state.phone, this.uuid, this.state.smsCode).then(({ phone }) => {
                    this.bindCallback(phone)
                })
                : loginBySms(this.state.areacode + this.state.phone, this.uuid, this.state.smsCode).then(({ uid, token, phone, displayName, age, isAuth, ucuid }) => {
                    this.loginCallback({
                        uid,
                        token,
                        phone,
                        displayName,
                        age,
                        isAuth,
                        ucuid
                    })
                })
            action.catch(e => {
                const newState = e.code === 401
                    ? {
                        step: 1
                    }
                    : {
                        error: e.message
                    }
                this.setState(newState)
            }).finally(() => {
                this.submitFlag = false
            })
        }
    }

    loginCallback(ui) {
        ui.phone = ui.phone || ''
        ui.age = ui.age ? ui.age : ui.isAuth === 1 ? 18 : 0
        const shareUrl = '/loginCallbackForCORS.html' + saveUserInfoLocally(ui)
        delete ui.ucuid
        // if (this.props.type === 'modal') {
        ui.isLogin = 1
        this.context.updateUserInfo(ui)
        ee.emit(EVENT_USER_LOGIN)
        // }
        this.shareCount = 0
        this.setState({
            success: true,
            shareUrl
        })
    }

    bindCallback(phone) {
        const shareUrl = '/bindCallbackForCORS.html' + savePhoneLocally(phone)
        this.context.updateUserInfo({ phone })
        this.shareCount = 0
        this.setState({
            success: true,
            shareUrl
        })
    }

    shareCallback = () => {
        this.shareCount += 1
        if (this.shareCount !== ShareDomains.length) {
            return
        }
        // if (this.props.type === 'modal') {
        const { phone, age, isAuth } = this.context.user
        if (window._imp && !phone) {
            return this.setState({
                success: false,
                step: 2
            })
        }
        if (window._impc && !(isAuth === 1 && age >= 18)) {
            ee.emit(EVENT_USER_NOT_CERTED)
        }
        this.props.close()
        // }
        // const query = parseQueryString(location.search)
        // location.href = query.callbackURI || '/'
    }

    loginByWechat = () => {
        if (this.checkAgree()) {
            this.setState({
                error: ''
            })
            window.open(getWechatLoginLink())
            this.startListen()
        }
    }

    loginByQQ = () => {
        if (this.checkAgree()) {
            this.setState({
                error: ''
            })
            window.open(getQQLoginLink())
            this.startListen()
        }
    }

    toggleAgree = () => {
        const agree = !this.state.agree
        this.setState({ agree })
    }

    toggleAreaSlide = e => {
        e.stopPropagation()
        const { showAreaDrop } = this.state
        this.setState({
            showAreaDrop: !showAreaDrop
        })
    }

    chooseArea = areacode => {
        this.setState({
            areacode
        })
    }

    dbListener = () => {
        this.setState({
            showAreaDrop: false
        })
    }

    closeAgreeTip = () => {
        this.setState({
            showAgreeTip: false
        })
    }

    agreeWithTip = () => {
        this.setState({
            agree: true
        })
        this.closeAgreeTip()
    }

    render() {
        const { ad, step, showAreaDrop, areacode, phoneErr, phone, captchaErr, captcha, captchaSrc, smsErr, smsCode, isSmsCD, smsTxt, error, agree, success, shareUrl, showAgreeTip } = this.state
        const { lang } = this.context.user
        const { close } = this.props
        return <div className="g-login-modal-wrapper">
            <div className={ad && step === 1 ? 'g-login-modal with-ad' : 'g-login-modal'}>
                {
                    ad && step === 1 && <div className='ad-wrapper'>
                        <a target={ad.target} href={ad.url} st-btn="1" st-category="登录-登录弹窗" st-label="弹窗" st-action={ad.name} st-value={JSON.stringify([{ url: ad.url }])} st-element-id='login-modal-ad'>
                            <img alt='' className='ad-img' src={getImgUrl(ad.imgUrl)}></img>
                        </a>
                    </div>
                }
                <i className="g-login-close" onClick={close}></i>
                <p className="g-login-title">{i18n(step === 2 ? '请完善信息' : '短信登录/注册', lang)}</p>
                <p className="g-bind-tip">{i18n(step === 2 ? '为了保障您的账号安全！请绑定手机号' : '未注册的手机号将自动创建账号', lang)}</p>
                <div className="g-login-form-line">
                    <div className="g-login-form">
                        <div className={showAreaDrop ? 'g-login-areacode expd' : 'g-login-areacode'} onClick={this.toggleAreaSlide}>+{areacode}</div>
                        {
                            showAreaDrop && <div className='area-code-slide'>
                                {
                                    AreaData.map(area => (<ul key={area.prefix}>
                                        {
                                            area.data.map(a => (<li key={a.value} className='area-code-item' onClick={() => this.chooseArea(a.value)}>
                                                <span>{i18n(a.zh_CN, lang)}</span>
                                                <span className='right'>+{a.value}</span>
                                            </li>))
                                        }
                                    </ul>))
                                }
                            </div>
                        }
                        <input type="text" name="g-l-phone" className={phoneErr ? 'g-login-input phone with-error' : 'g-login-input phone'} placeholder={i18n('请输入手机号', lang)} value={phone} onChange={this.inputPhone}></input>
                    </div>
                </div>
                {/* <div className="g-login-form-line">
                    <div className="g-login-form g-login-form-inline">
                        <input type="text" name="g-l-captcha" className={captchaErr ? 'g-login-input with-error' : 'g-login-input'} placeholder={i18n('请输入图片验证码', lang)} value={captcha} onChange={this.inputCaptcha} autocomplete="off"></input>
                    </div>
                    <img className="g-login-form-right" src={captchaSrc} onClick={this.refreshCaptcha}></img>
                </div> */}
                <div className="g-login-form-line g-login-form-last">
                    <div className="g-login-form g-login-form-inline">
                        <input type="text" name="g-l-sms" className={smsErr ? 'g-login-input with-error' : 'g-login-input'} placeholder={i18n('请输入短信验证码', lang)} value={smsCode} onChange={this.inputSmsCode} onKeyDown={e => keyboardEnter(e, this.submit)} autocomplete="off"></input>
                    </div>
                    {
                        isSmsCD
                            ? <div className="g-login-form-right g-login-sms disabled">{i18n(smsTxt, lang)}</div>
                            : <div className="g-login-form-right g-login-sms" onClick={this.sendMessage}>{i18n(smsTxt, lang)}</div>
                    }
                </div>
                <div className={error.length ? 'g-login-error g-login-error-show' : 'g-login-error'}>{i18n(error, lang)}</div>
                <button className="g-login-submit" onClick={this.submit}>{i18n(step === 2 ? '确定' : '登录', lang)}</button>
                <ul className={step === 1 ? 'g-login-third-ul' : 'g-login-third-ul hidden'}>
                    <li className="g-login-third qq" onClick={this.loginByQQ}></li>
                    <li className="g-login-third wechat" onClick={this.loginByWechat}></li>
                </ul>
                <div className={step === 2 ? 'g-skip-blind-wrapper' : 'g-skip-blind-wrapper hidden'}>
                    <button className='g-skip-blind' onClick={close}>{i18n('稍后再填', lang)}</button>
                </div>
                <p className={step === 1 ? 'g-login-tip' : 'g-login-tip hidden'}>
                    <span className={agree ? 'checkbox checked' : 'checkbox'} onClick={this.toggleAgree}>
                    </span>
                    <span className="content" onClick={this.toggleAgree}>{i18n('已阅读并同意', lang)}</span>
                    <a target="_blank" href="/contract/user.html">
                        <span>{i18n('《用户协议》', lang)}</span>
                    </a>
                    <span className="content" onClick={this.toggleAgree}>和</span>
                    <a target="_blank" href="/contract/privacy.html">
                        <span>{i18n('《隐私政策》', lang)}</span>
                    </a>
                </p>
            </div>
            {
                showAgreeTip && <div className='g-login-agree-tip-wrapper'>
                    <div className='g-login-agree-tip'>
                        <i className='g-login-agree-tip-close' onClick={this.closeAgreeTip}></i>
                        <p className='g-login-agree-tip-content'>
                            {i18n('请勾选已阅读并同意', lang)}
                            <a target="_blank" href="/contract/user.html">
                                <span className='agree-item'>{i18n('《用户协议》', lang)}</span>
                            </a>
                            {i18n('和', lang)}
                            <a target="_blank" href="/contract/privacy.html">
                                <span className='agree-item'>{i18n('《隐私政策》', lang)}</span>
                            </a>
                        </p>
                        <div className='g-login-agree-btns'>
                            <button className='g-login-agree-btn cancel' onClick={this.closeAgreeTip}>{i18n('暂不', lang)}</button>
                            <button className='g-login-agree-btn' onClick={this.agreeWithTip}>{i18n('同意', lang)}</button>
                        </div>
                    </div>
                </div>
            }
            {
                success && <div className="g-login-share-wrapper">
                    {
                        ShareDomains.map(domain => (<iframe src={domain + shareUrl} onLoad={this.shareCallback} onError={this.shareCallback}></iframe>))
                    }
                </div>
            }
        </div>
    }
}

Login.contextType = AppContext

export default Login