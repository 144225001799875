import React from 'react'
import Portal from '../../../../component/portal'
import { getImgUrl, i18n, getLinkUrl } from '../../../../common/util'
import { AppContext } from '../../../../config/context'

const isForMatrix = window.location.pathname === '/special/matrix'

class HomeLuckyBag extends React.Component {
    constructor() {
        super(...arguments)
        const list = window._inject_app_data_.lucky
        const totalPage = Math.ceil(list.length / 2)
        this.state = {
            page: 1,
            totalPage,
            windowStyle: { left: '0' },
            list
        }
    }

    prev = () => {
        const page = this.state.page - 1
        if (page > 0) {
            this.setState({
                page,
                windowStyle: {
                    left: page > 1 ? isForMatrix ? `-${(page - 1) * 12.22}rem` : `-${(page - 1) * 1222}px` : '0'
                }
            })
        }
    }

    next = () => {
        const page = this.state.page + 1
        if (page <= this.state.totalPage) {
            this.setState({
                page,
                windowStyle: {
                    left: isForMatrix ? `-${(page - 1) * 12.22}rem` : `-${(page - 1) * 1222}px`
                }
            })
        }
    }

    render() {
        const { windowStyle, list } = this.state
        const { lang } = this.context.user
        return <div className="home-lucky-wrapper">
            <div className="home-lucky-window">
                <ul className="home-lucky-ul" style={windowStyle}>
                    {
                        list.map(l => (<a target={isForMatrix ? '_blank' : l.target} href={getLinkUrl(l.url)}>
                            <li className="home-lucky-item">
                                <img alt="" className="img" src={getImgUrl(l.imgUrl)}></img>
                                <button className="btn">{i18n('查看内容', lang)}</button>
                            </li>
                        </a>))
                    }
                </ul>
            </div>
            <i className="home-lucky-arrow left" onClick={this.prev}></i>
            <i className="home-lucky-arrow right" onClick={this.next}></i>
        </div>
    }
}

HomeLuckyBag.contextType = AppContext
export default Portal(HomeLuckyBag, 'home-lucky-container')