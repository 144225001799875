import React from 'react'
import { AppContext } from '../../config/context'
import { BlindboxOrderStatusMap } from '../../config'
import { getParentOrderDetail, getOrderCDK } from '../service'
import ee, { EVENT_TIP } from '../../common/util/event'
import { i18n, getImgUrl, hbsBoolean, getOrderForms } from '../../common/util'
import Recycle from '../recycle'
import PocketDetail from './pocketDetail'
import CDKModal from './cdkModal'
import './orderDetail.scss'

const Product_recharge = 'RECHAGRE'

class OrderDetail extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            detail: null,
            showPocketDetail: false,
            pocketDetailId: '',
            showCDKModal: false,
            cdks: [],
            showRecycleModal: false,
            selectedList: []
        }
    }

    componentDidMount() {
        const { subId, changeRealIndex } = this.props
        getParentOrderDetail(subId).then(detail => {
            detail.subs.forEach(s => {
                s.extDesc = getOrderForms(s.ext, s.extMap)
            })
            this.setState({ detail })
        }).catch(e => {
            e.code !== 401 && ee.emit(EVENT_TIP, e.message)
            changeRealIndex(2)
        })
    }

    showPocketDetail = pocketDetailId => {
        this.setState({
            showPocketDetail: true,
            pocketDetailId
        })
    }

    closePocketDetail = () => {
        this.setState({
            showPocketDetail: false
        })
    }

    showRecycleModal = selectedList => {
        this.setState({
            showRecycleModal: true,
            selectedList
        })
        this.closePocketDetail()
    }

    closeRecycleModal = () => {
        this.setState({
            showRecycleModal: false
        })
    }

    recycleSubmit = () => {
        this.updateOrderStatus({ status: 6, cdks: [] })
    }

    showCDK = (cdks, subOrderId) => {
        this.setState({
            showCDKModal: true,
            cdks
        })
        getOrderCDK(subOrderId)
    }

    closeCDK = () => {
        this.setState({
            showCDKModal: false
        })
    }

    updateOrderStatus = ({ status, cdks }) => {
        const { detail, pocketDetailId } = this.state
        const subs = detail.subs.slice()
        for (let i = 0; i < subs.length; ++i) {
            let sub = subs[i]
            if (sub.subOrderId === pocketDetailId) {
                subs[i] = { ...sub, status, cdks: Array.isArray(cdks) ? cdks : [cdks] }
                this.setState({
                    detail: {
                        ...detail,
                        subs
                    }
                })
                break
            }
        }
    }

    render() {
        const { detail, showPocketDetail, pocketDetailId, showCDKModal, cdks, showRecycleModal, selectedList } = this.state
        const { lang, phone } = this.context.user
        return <div className='bag-order-detail-container'>
            {
                detail && <div className='bag-order-detail-wrapper'>
                    <div className='bag-order-detail-window'>
                        <div className='bag-order-detail-content'>
                            <div className='bag-order-detail-info-row'>
                                <div className='bag-order-detail-info col-1'>
                                    <span className='label'>{i18n('下单时间：', lang)}</span>
                                    <span>{detail.createTime}</span>
                                </div>
                                <div className='bag-order-detail-info col-2'>
                                    <span className='label'>{i18n('订单编号：', lang)}</span>
                                    <span>{detail.orderId}</span>
                                </div>
                                <div className='bag-order-detail-info col-3'>
                                    <span className='label'>{i18n('付款时间：', lang)}</span>
                                    <span>{detail.payTime}</span>
                                </div>
                            </div>
                            <div className='bag-order-detail-info-row'>
                                <div className='bag-order-detail-info col-1'>
                                    <span className='label'>{i18n('付款方式：', lang)}</span>
                                    <span>{i18n('电池支付', lang)}</span>
                                </div>
                                <div className='bag-order-detail-info col-2'>
                                    <span className='label'>{i18n('联系人手机号：', lang)}</span>
                                    <span>{phone}</span>
                                </div>
                            </div>
                            <div className='bag-order-detail-th'>
                                <div className='th-td col-1'>{i18n('商品', lang)}</div>
                                <div className='th-td col-2'>{i18n('价格', lang)}</div>
                                <div className='th-td col-3'>{i18n('数量', lang)}</div>
                                <div className='th-td col-4'>{i18n('实付', lang)}</div>
                                <div className='th-td col-5'>{i18n('状态', lang)}</div>
                                <div className='th-td col-6'>{i18n('操作', lang)}</div>
                            </div>
                            <div className='bag-order-detail-tb main'>
                                <div className='bag-order-detail-tb-row'>
                                    <div className='tb-td col-1'>
                                        <div className='img-wrapper'>
                                            {
                                                hbsBoolean(detail.pcMhImgUrl) && <img alt='' className='img' src={getImgUrl(detail.pcMhImgUrl)}></img>
                                            }
                                        </div>
                                        <p className='name'>{detail.goodsName}</p>
                                    </div>
                                    <div className='tb-td col-2'>{detail.reductionAmount + detail.payAmount}</div>
                                    <div className='tb-td col-3'>{detail.subs.length}</div>
                                    <div className='tb-td col-4'>{detail.payAmount}</div>
                                    <div className='tb-td col-5'>-</div>
                                    <div className='tb-td col-6'>-</div>
                                </div>
                            </div>
                            <ul className='bag-order-detail-table'>
                                {
                                    detail.subs.map(item => (<li className='bag-order-detail-tb'>
                                        <div className='bag-order-detail-tb-row'>
                                            <div className='tb-td col-1'>
                                                <div className='img-wrapper'>
                                                    <img alt='' className='img' src={getImgUrl(item.imgUrl)}></img>
                                                </div>
                                                <p className={!!item.skuName ? 'name m-l' : 'name'}>{item.skuGoodsName}</p>
                                                {
                                                    !!item.skuName && <p className='sku'>{i18n(`规格：${item.skuName}`, lang)}</p>
                                                }
                                            </div>
                                            <div className='tb-td col-2'>{item.reductionAmount + item.payAmount}</div>
                                            <div className='tb-td col-3'>1</div>
                                            <div className='tb-td col-4'>{item.payAmount}</div>
                                            <div className={item.status === 2 ? 'tb-td col-5 unchecked' : item.status === 12 || item.status === 3 ? 'tb-td col-5 unusual' : 'tb-td col-5'}>{i18n(BlindboxOrderStatusMap[item.status], lang)}</div>
                                            {
                                                (item.status === 1 || item.status === 3 || item.status === 6)
                                                    ? <div className='tb-td col-6'>-</div>
                                                    : <div className={item.status === 11 ? 'tb-td col-6 btn-2' : 'tb-td col-6 btn-1'}>
                                                        <button className='btn' onClick={() => this.showPocketDetail(item.subOrderId)}>{i18n('奖品详情', lang)}</button>
                                                        {
                                                            item.status === 11 && item.skuProductType !== Product_recharge && <button className='btn check-cdk' onClick={() => this.showCDK(item.cdks, item.subOrderId)}>{i18n('查看卡密', lang)}</button>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                        {
                                            hbsBoolean(item.extDesc) && <div className='bag-order-detail-addition'>
                                                {
                                                    item.extDesc.map(d => (<span className='bag-order-detail-addition-item'>{`${i18n(d.title, lang)}：${d.value}`}</span>))
                                                }
                                            </div>
                                        }
                                    </li>))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {
                detail && <div className='bag-order-detail-footer'>
                    <p className='bag-order-detail-footer-info'>
                        <span>{i18n('共计', lang)}</span>
                        <span className='highlight'>{detail.subs.length}</span>
                        <span>{i18n('件商品，', lang)}</span>
                        {
                            !!detail.reductionAmount && <span>{i18n('优惠', lang)}</span>
                        }
                        {
                            !!detail.reductionAmount && <span className='highlight'>{detail.reductionAmount}，</span>
                        }
                        <span>{i18n('实付', lang)}</span>
                        <i className='battery-icon'></i>
                        <span className='amount'>{detail.payAmount}</span>
                    </p>
                </div>
            }
            {
                showPocketDetail && <PocketDetail close={this.closePocketDetail} pocketDetailId={pocketDetailId} updatePocket={this.updateOrderStatus} goToRecycle={this.showRecycleModal}></PocketDetail>
            }
            {
                showRecycleModal && <Recycle close={this.closeRecycleModal} selectedList={selectedList} submit={this.recycleSubmit}></Recycle>
            }
            {
                showCDKModal && <CDKModal close={this.closeCDK} cdks={cdks}></CDKModal>
            }
        </div>
    }
}

OrderDetail.contextType = AppContext
export default OrderDetail