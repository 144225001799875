import React from 'react'
import { AppContext } from '../../config/context'
import { i18n } from '../../common/util'
import { PayTypeMap } from '../../config'
import QRCode from '../qrcode'
import './qrcode.scss'

class QRCodeModal extends React.Component {
    constructor() {
        super(...arguments)
    }

    componentDidMount() {

    }

    render() {
        const { close, payType, payAmount, qrcode, qrcodeInvalid } = this.props
        const { lang } = this.context.user
        return <div className='qrcode-modal-wrapper'>
            <div className='qrcode-modal'>
                <i className='qrcode-modal-close' onClick={close}></i>
                <div className='qrcode-modal-header'>
                    <i className='header-icon'></i>
                    <span className='header-title'>{i18n('电池购买', lang)}</span>
                </div>
                <div className='qrcode-modal-body'>
                    <div className='qrcode-modal-main'>
                        <div className='qrcode-modal-body-line'></div>
                        <p className='amount-title'>{i18n('订单金额', lang)}</p>
                        <p className='amount-num'><span className='unit'>&yen;</span>{Number(payAmount).toFixed(2)}</p>
                        <div className='qrcode-wrapper'>
                            <p className={`pay-type-tip type-${payType}`}>{i18n(PayTypeMap[payType] + '扫码支付', lang)}</p>
                            <div className='qrcode-container'>
                                <QRCode src={qrcode} width={220}></QRCode>
                            </div>
                            {
                                qrcodeInvalid && <div className='invalid-wrapper' onClick={close}>
                                    <p className='invalid-tip'>{i18n('二维码已失效', lang)}</p>
                                    <p className='invalid-tip'>{i18n('请重新支付', lang)}</p>
                                </div>
                            }
                        </div>
                        <p className='order-tip'>{i18n('订单支付成功后，可在“个人中心”查看订单详情', lang)}</p>
                        <p className='pay-tip'>{i18n('支付成功前请勿关闭窗口，以防丢单', lang)}</p>
                    </div>
                </div>
            </div>
        </div>
    }
}

QRCodeModal.contextType = AppContext
export default QRCodeModal