import { httpGet } from '../../common/util'

export const getQRCodeImg = (data, width = '') => {
    return httpGet({
        url: location.protocol + '//' + location.host + '/service/qrcode',
        data: {
            data,
            width
        }
    })
}