import React from 'react'
import Portal from '../portal'
import Login from '../login'
import CouponTip from '../couponTip'
import './index.scss'
import { getCouponList, receiveCoupon, getCouponDetail } from '../service'
import {
    checkUserInfoLocally,
    clearUserInfoLocally,
    checkCouponStatus,
    getCouponDateStr,
    getCouponUrl,
    showDiscountRate,
    sortCouponList,
    i18n
} from '../../common/util'
import { AppContext, getDefaultState } from '../../config/context'
import ee, { EVENT_TIP, EVENT_USER_LOGIN } from '../../common/util/event'

class Coupon extends React.Component {
    constructor({ type }) {
        super(...arguments)
        this.state = {
            list: [],
            showLoginModal: false,
            showCouponTip: false,
            tipType: '',
            tipUrl: '',
            tipContent: '',
            isIndex: type === 'index',
            isPage: type === 'page'
        }
        this.receiveFlag = false
        this.useFlag = false
    }

    componentDidMount() {
        const ui = checkUserInfoLocally()
        if (ui) {
            this.init(true).catch(e => {
                if (e.code === 401) {
                    this.init(false)
                    this.clearLoginInfo()
                }
            })
        } else {
            this.init(false)
        }
        ee.addListener(EVENT_USER_LOGIN, () => this.init(true))
    }

    init = auth => {
        return getCouponList({ pageSize: this.state.isIndex ? 5 : 99 }, auth)
            .then(({ entities }) => this.setState({ list: sortCouponList(entities.map(e => checkCouponStatus(e, false))) }))
    }

    clearLoginInfo = () => {
        clearUserInfoLocally()
        this.context.updateUserInfo(getDefaultState())
    }

    closeLoginModal = () => {
        this.setState({
            showLoginModal: false
        })
    }

    closeCouponTip = () => {
        this.setState({
            showCouponTip: false
        })
    }

    receive = (coupon) => {
        if (coupon.statusClass === 'normal') {
            if (this.context.user.isLogin !== 1) {
                return this.setState({
                    showLoginModal: true
                })
            }
            if (this.receiveFlag) {
                return
            }
            this.receiveFlag = true
            receiveCoupon(coupon.id).then(data => {
                this.setState({
                    showCouponTip: true,
                    tipType: 'success',
                    tipUrl: getCouponUrl(data)
                })
                this.init(true)
            }).catch(e => {
                if (e.code === 401) {
                    this.init(false)
                    this.clearLoginInfo()
                    this.setState({
                        showLoginModal: true
                    })
                } else if (e.code === 400) {
                    this.init(true)
                    this.setState({
                        showCouponTip: true,
                        tipType: 'warning',
                        tipContent: e.message || '优惠券已被领完'
                    })
                } else {
                    this.setState({
                        showCouponTip: true,
                        tipType: 'error'
                    })
                    ee.emit(EVENT_TIP, e.message)
                }
            }).finally(() => {
                this.receiveFlag = false
            })
        }
        if (coupon.statusClass === 'use') {
            if (this.useFlag) {
                return
            }
            this.useFlag = true
            getCouponDetail(coupon.id).then(data => {
                window.open(getCouponUrl(data))
            }).catch(e => {
                ee.emit(EVENT_TIP, e.message)
            }).finally(() => {
                this.useFlag = false
            })
        }
    }

    renderCouponValue = (coupon) => {
        const { lang } = this.context.user
        return <div className="coupon-value">
            {
                coupon.amountDiscountType === 0 && <span className="unit">&yen;</span>
            }
            <span className="value">{coupon.amountDiscountType === 1 ? showDiscountRate(coupon.discountsPrice) : coupon.discountsPrice}</span>
            {
                coupon.amountDiscountType === 1 && <span className="unit">折</span>
            }
            <span className="tag">{i18n(coupon.amountLimitation === 0 ? '无门槛' : `满${coupon.amountLimitation}元可用`, lang)}</span>
        </div>
    }

    renderCouponDate = (coupon) => {
        const { lang } = this.context.user
        return <p className="coupon-date">{this.state.isPage ? i18n('有效期：', lang) : ''}{i18n(getCouponDateStr(coupon), lang)}</p>
    }

    renderCouponActionTxt = (coupon) => {
        return coupon.statusClass === 'disabled' ? '已领完' : coupon.statusClass === 'use' ? '立即使用' : '立即领取'
    }

    render() {
        const { list, showLoginModal, showCouponTip, tipType, tipContent, tipUrl, isIndex, isPage } = this.state
        if (!list.length) {
            return null
        }
        const { lang } = this.context.user
        if (isIndex) {
            return <div>
                <h2 className="home-module-title">
                    <i className="home-module-icon coupon"></i>{i18n('领券中心', lang)}
                    <a target="_blank" href="/coupon/couponList.html"><span class="more">{i18n('更多>', lang)}</span></a>
                </h2>
                <ul className="home-coupon-ul">
                    {
                        list.map(c => (<li className={`home-coupon ${c.statusClass}`} key={c.id}>
                            <div className="coupon-action" onClick={() => this.receive(c)}>{i18n(this.renderCouponActionTxt(c), lang)}</div>
                            {this.renderCouponValue(c)}
                            <p className="coupon-title">{i18n(c.couponName, lang)}</p>
                        </li>))
                    }
                </ul>
                {showLoginModal && <Login close={this.closeLoginModal}></Login>}
                {showCouponTip && <CouponTip type={tipType} tip={tipContent} url={tipUrl} close={this.closeCouponTip}></CouponTip>}
            </div>
        }
        if (isPage) {
            return <div>
                <ul className="coupon-item-ul">
                    {
                        list.map(c => (<li className={`coupon-item ${c.statusClass}`} key={c.id}>
                            <p className="coupon-title">{i18n(c.couponName, lang)}</p>
                            {this.renderCouponValue(c)}
                            {this.renderCouponDate(c)}
                            <p className="coupon-desc">{i18n(c.effectiveRangeType === 0 ? '全场通用优惠券(不包含福袋)' : '商品优惠券', lang)}</p>
                            <p className="coupon-desc" title={i18n(c.description, lang)}>{i18n('使用说明：' + c.description, lang)}</p>
                            <button className="coupon-action" onClick={() => this.receive(c)}>{i18n(this.renderCouponActionTxt(c), lang)}</button>
                        </li>))
                    }
                </ul>
                {showLoginModal && <Login close={this.closeLoginModal}></Login>}
                {showCouponTip && <CouponTip type={tipType} tip={tipContent} url={tipUrl} close={this.closeCouponTip}></CouponTip>}
            </div>
        }
        return null
    }
}

Coupon.contextType = AppContext
export default Portal(Coupon, 'g-coupon-container')