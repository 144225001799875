import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, throttle } from '../../common/util'
import { getPocketRecord } from './service'
import ee, { EVENT_TIP } from '../../common/util/event'
import './pocketRecord.scss'

const Tabs = [{ name: '全部', value: 0 }, { name: '获得记录', value: 1 }, { name: '失去记录', value: 2 }]
const PageSize = 24
const FormWindowELId = 'pocket-record-scroller'

class PocketRecord extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tabIndex: 0,
            page: 1,
            list: [],
            ready: false
        }
        this.hasMore = false
        this.loadFlag = false
    }

    componentDidMount() {
        this.initTab(this.state.tabIndex)
    }

    initTab = tabIndex => {
        getPocketRecord(1, PageSize, Tabs[tabIndex].value).then(({ pages, records }) => {
            const dataExist = Array.isArray(records) && records.length
            this.setState({
                page: 1,
                list: dataExist ? records : [],
                ready: true
            }, () => {
                this.hasMoreEffect(pages > 1)
                dataExist && (document.getElementById(FormWindowELId).scrollTop = 0)
            })
        }).catch(e => {
            if (e.code !== 401) {
                this.setState({
                    page: 1,
                    list: [],
                    ready: true
                })
                this.hasMoreEffect(false)
                ee.emit(EVENT_TIP, e.message)
            }
        })
    }

    hasMoreEffect = hasMore => {
        if (hasMore !== this.hasMore) {
            this.hasMore = hasMore
            const el = document.getElementById(FormWindowELId)
            hasMore
                ? el.addEventListener('scroll', this.scrollListener)
                : el.removeEventListener('scroll', this.scrollListener)
        }
    }

    loadMoreData = () => {
        if (!this.hasMore || this.loadFlag) {
            return
        }
        this.loadFlag = true
        const { tabIndex, page } = this.state
        getPocketRecord(page + 1, PageSize, Tabs[tabIndex].value).then(({ pages, records }) => {
            this.setState({
                page: page + 1,
                list: this.state.list.concat(records)
            }, () => {
                this.loadFlag = false
            })
            this.hasMoreEffect(pages > page + 1)
        })
    }

    scrollListener = throttle(() => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById(FormWindowELId)
        if (scrollTop + clientHeight >= scrollHeight) {
            this.loadMoreData()
        }
    })

    chooseTab = tabIndex => {
        if (tabIndex !== this.state.tabIndex) {
            this.setState({
                tabIndex
            })
            this.initTab(tabIndex)
        }
    }

    render() {
        const { tabIndex, ready, list } = this.state
        const { lang } = this.context.user
        return <div className='pocket-record-container'>
            <div className='pocket-record-form-wrapper'>
                <ul className='bag-content-tabs'>
                    {
                        Tabs.map((tab, index) => (<li className={tabIndex === index ? 'bag-content-tab active' : 'bag-content-tab'} onClick={() => this.chooseTab(index)}>{i18n(tab.name, lang)}</li>))
                    }
                </ul>
                <div className='pocket-record-form-header'>
                    <div className='pocket-record-form-th index-1'>{i18n('时间', lang)}</div>
                    <div className='pocket-record-form-th index-2'>{i18n('订单号', lang)}</div>
                    <div className='pocket-record-form-th index-3'>{i18n('物品名称', lang)}</div>
                    <div className='pocket-record-form-th index-4'>{i18n('描述', lang)}</div>
                    <div className='pocket-record-form-th index-5'>{i18n('物品价值（元）', lang)}</div>
                    <div className='pocket-record-form-th index-6'>{i18n('类型', lang)}</div>
                </div>
                <div className={ready && list.length > 0 ? 'pocket-record-form-window' : 'pocket-record-form-window hidden'} id={FormWindowELId}>
                    <ul className='pocket-record-form-ul'>
                        {
                            list.map(item => (<li className='pocket-record-form-row'>
                                <div className='form-row-td index-1'>{item.createTime}</div>
                                <div className='form-row-td index-2'>{item.type === 2 ? item.originOrderId : item.orderId}</div>
                                <div className='form-row-td index-3'>{i18n(item.name, lang)}</div>
                                <div className='form-row-td index-4'>{i18n(item.remark, lang)}</div>
                                <div className='form-row-td index-5'>{item.primePrice}</div>
                                {
                                    item.type === 1
                                        ? <div className='form-row-td index-6'>{i18n('获得', lang)}</div>
                                        : <div className='form-row-td index-6 lose'>{i18n('失去', lang)}</div>
                                }
                            </li>))
                        }
                    </ul>
                </div>
                <div className={ready && list.length === 0 ? 'bag-empty-wrapper record' : 'bag-empty-wrapper record hidden'}>
                    <i className='empty-icon'></i>
                    <p className='empty-txt'>{i18n('暂无记录~', lang)}</p>
                </div>
            </div>
        </div>
    }
}

PocketRecord.contextType = AppContext
export default PocketRecord