import React from 'react'
import './index.scss'
import { AppContext } from '../../config/context'
import Login from '../login'
import { isIE, i18n, hbsBoolean, getImgUrl } from '../../common/util'
import { ServiceFor9891dc, OrderLink } from '../../config'
import { getServiceUrl, getWindowData } from '../service'

const FirstTimeCacheKey = 'fmftck'

class FloatMenu extends React.Component {
    constructor() {
        super(...arguments)
        let loaded = localStorage.getItem(FirstTimeCacheKey)
        let now = Date.now()
        let showDownload = false
        if (!loaded) {
            showDownload = true
            localStorage.setItem(FirstTimeCacheKey, now + '')
        } else {
            loaded = Number(loaded)
            if (now - loaded > 1000 * 60 * 60 * 3) {
                showDownload = true
                localStorage.setItem(FirstTimeCacheKey, now + '')
            }
        }
        this.state = {
            showLoginModal: false,
            downloadImgUrl: '',
            showDownload
        }
    }

    componentDidMount() {
        this.loadDownloadImgUrl()
    }

    closeLoginModal = () => {
        this.setState({
            showLoginModal: false
        })
    }

    loadDownloadImgUrl = () => {
        getWindowData('float_menu_link', 2).then(content => {
            content[0] && this.setState({
                downloadImgUrl: content[0].imgUrl
            })
        })
    }

    mouseEnter = () => {
        this.setState({
            showDownload: true
        })
    }

    mouseLeave = () => {
        this.setState({
            showDownload: false
        })
    }

    // isRent = () => {
    //     return location.pathname.indexOf('/zuhao2/') > -1
    // }

    // isBlindbox = () => {
    //     return location.pathname.indexOf('/pubgbox') > -1 || location.pathname.indexOf('/steambox') > -1
    // }

    isDc = () => {
        return location.pathname.indexOf('/dc/') > -1
    }

    service = () => {
        // if (this.isRent()) {
        //     return window.open(ServiceFor9891Url)
        // }
        if (this.isDc()) {
            return window.open(ServiceFor9891dc)
        }
        getServiceUrl().then(href => {
            window.open(href)
        })
    }

    order = () => {
        if (this.context.user.isLogin === 1) {
            // if (this.isRent()) {
            //     return window.open('/zuhao2/orderList.html')
            // }
            if (this.isDc()) {
                return window.open('/order/dc/list.html')
            }
            // if (this.isBlindbox()) {
            //     return window.open('/order/blindboxList.html')
            // }
            return window.open(OrderLink)
        }
        this.setState({ showLoginModal: true })
    }

    top = () => {
        if (isIE()) {
            window.scrollTo(0, 0)
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    render() {
        const { lang } = this.context.user
        const { showLoginModal, downloadImgUrl, showDownload } = this.state
        return <div>
            <div className="float-menu-wrapper">
                <div className="float-menu welfare" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                    <i className="icon welfare"></i>
                    <p className="name">{i18n('APP下载', lang)}</p>
                    {
                        showDownload && hbsBoolean(downloadImgUrl) && <div className='welfare-pop'>
                            <img alt='' className='welfare-pop-img' src={getImgUrl(downloadImgUrl)}></img>
                        </div>
                    }
                </div>
                <div className="float-menu" onClick={this.service}>
                    <i className="icon service"></i>
                    <p className="name">{i18n('客服', lang)}</p>
                </div>
                <div className="float-menu" onClick={this.order}>
                    <i className="icon order"></i>
                    <p className="name">{i18n('订单', lang)}</p>
                </div>
                <div className="float-menu" onClick={this.top}>
                    <i className="icon top"></i>
                    <p className="name">{i18n('顶部', lang)}</p>
                </div>
            </div>
            {showLoginModal && <Login close={this.closeLoginModal}></Login>}
        </div>
    }
}

FloatMenu.contextType = AppContext
export default FloatMenu