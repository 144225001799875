import React from 'react'
import { ShareDomains } from '../../config'
import './index.scss'

class Logout extends React.Component {
    constructor() {
        super(...arguments)
        this.shareCount = 0
    }

    shareCallback = () => {
        this.shareCount += 1
        if (this.shareCount === ShareDomains.length) {
            this.props.close && this.props.close()
        }
    }

    render() {
        return <div className="g-logout-share-wrapper">
            {
                ShareDomains.map(domain => (<iframe src={domain + '/logoutCallbackForCORS.html'} onLoad={this.shareCallback} onError={this.shareCallback}></iframe>))
            }
        </div>
    }
}

export default Logout