import React from 'react'
import { getCookie } from '../common/util'

export const defaultState = {
    user: {
        isLogin: 2,
        uid: 0,
        phone: '',
        token: '',
        displayName: '',
        age: 0,
        isAuth: 0,
        battery: 0,
        lang: getCookie('_gml')
    },
    updateUserInfo() { }
}

export const getDefaultState = () => ({ isLogin: 0, uid: 0, phone: '', token: '', displayName: '', age: 0, isAuth: 0, battery: 0 })

export const AppContext = React.createContext(defaultState)