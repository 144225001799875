import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, parseCDKs, copyByTAId } from '../../common/util'
import './cdkModal.scss'

const CDKSTAInput = 'bag-cdks-hidden-ta'

class CDKModal extends React.Component {
    constructor() {
        super(...arguments)
        const { showCDKContent, copyCDKContent } = parseCDKs(this.props.cdks)
        this.state = {
            showCDKContent,
            copyCDKContent
        }
    }

    render() {
        const { showCDKContent, copyCDKContent } = this.state
        const { lang } = this.context.user
        return <div className='bag-cdks-modal-wrapper'>
            <div className='bag-cdks-modal'>
                <i className='bag-cdks-close' onClick={this.props.close}></i>
                <div className='bag-cdks-header'>
                    <p className='cdks-header-title'>{i18n('查看卡密', lang)}</p>
                </div>
                <div className='bag-cdks-content-window'>
                    {
                        showCDKContent.map(cdks => (<div>
                            {
                                cdks.map(cdk => (<div className='bag-cdks-content'>
                                    <div className='bag-cdk-label'>{i18n(cdk.header || '激活码', lang)}：</div>
                                    <div className='bag-cdk-content'>{cdk.content}</div>
                                </div>))
                            }
                        </div>))
                    }
                    <textarea name={CDKSTAInput} className={CDKSTAInput} id={CDKSTAInput} value={copyCDKContent.join('\n')}></textarea>
                </div>
                <button className='bag-cdks-copy-btn' onClick={() => copyByTAId(CDKSTAInput)}>{i18n('复制卡密', lang)}</button>
            </div>
        </div>
    }
}

CDKModal.contextType = AppContext
export default CDKModal