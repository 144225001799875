import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, getImgUrl, safeParse, bptvBoolean } from '../../common/util'
import { getBigPlayerTemplate, preOrder, deliverWithFormData, getPriceByArea } from '../service'
import './pocketDeliver.scss'

const TextControl = 'TextControl'
const NumericControl = 'NumericControl'
const PasswordControl = 'PasswordControl'
const SingleSelectControl = 'SingleSelectControl'
const DropDownSingleSelectControl = 'DropDownSingleSelectControl'
const MultiSelectControl = 'MultiSelectControl'
const DropDownMultiSelectControl = 'DropDownMultiSelectControl'

class PocketDeliver extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            // phone: this.context.user.phone,
            // phoneError: false,
            controlList: [],
            controlHelperList: [],
            controlValueList: [],
            controlErrorList: [],
            json: '',
            errTip: '',
            customForm: [],
            customValue: [],
            customError: [],
            showGroup: false,
            groupList: [],
            showServer: false,
            serverMap: {},
            serverList: [],
            groupCode: null,
            groupName: '',
            serverCode: null,
            serverName: '',
            groupError: false,
            serverError: false,
            gid: '',
            tGoodsId: '',
            showGroupSelect: false,
            showServerSelect: false
        }
        this.errTipTimer = null
        this.readyState = 0
        this.submitFlag = false
    }

    componentDidMount() {
        getBigPlayerTemplate(this.props.goodsInfo.goodsId).then(data => {
            this.readyState++
            if (!data) {
                return
            }
            if (Array.isArray(data.sections) && data.sections.length) {
                const controlList = []
                const controlHelperList = []
                const controlValueList = []
                const controlErrorList = []
                data.sections.forEach(section => {
                    let { tables } = section
                    if (Array.isArray(tables) && tables.length) {
                        tables.forEach(table => {
                            let { controls, headers } = table
                            controls = (controls || [])[0]
                            if (Array.isArray(controls) && Array.isArray(headers) && controls.length === headers.length && controls.length) {
                                controls.forEach((control, i) => {
                                    controlList.push({
                                        ...control,
                                        title: headers[i].title,
                                        disabled: control.configurationForResponder.editable === false,
                                        required: control.configurationForResponder.required !== false
                                    })
                                    controlHelperList.push(false)
                                    controlValueList.push(
                                        control.type === MultiSelectControl || control.type === DropDownMultiSelectControl
                                            ? Array.isArray(control.values)
                                                ? control.values
                                                : []
                                            : control.value || "")
                                    controlErrorList.push(false)
                                })
                            }
                        })
                    }
                })
                if (controlList.length) {
                    this.setState({
                        controlList,
                        controlHelperList,
                        controlValueList,
                        controlErrorList,
                        json: JSON.stringify(data)
                    })
                }
            } else if (Array.isArray(data.regionList) && data.regionList.length) {
                const newState = {
                    showGroup: true,
                    showServer: Array.isArray(data.regionList[0].serverList) && data.regionList[0].serverList.length > 0,
                    gid: (data.gameInfo || { gid: '' }).gid
                }
                const groupList = []
                const serverMap = {}
                for (let i = 0; i < data.regionList.length; ++i) {
                    let { code, name, serverList } = data.regionList[i]
                    groupList.push({ code, name })
                    newState.showServer && (serverMap[code] = serverList)
                }
                newState.groupList = groupList
                newState.serverMap = serverMap
                this.setState(newState)
            }
        })
        preOrder(this.props.goodsInfo.skuId, 1, -1, -1, false).then(data => {
            this.readyState++
            const { extDesc } = data.subOrders[0]
            const customForm = safeParse(extDesc)
            if (Array.isArray(customForm) && customForm.length) {
                this.setState({
                    customForm,
                    customValue: customForm.map(() => ''),
                    customError: customForm.map(() => false)
                })
            }
        })
        document.addEventListener('click', this.clickHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickHandler)
        if (this.errTipTimer) {
            clearTimeout(this.errTipTimer)
        }
    }

    clickHandler = () => {
        const { controlList, controlHelperList } = this.state
        const helpers = controlHelperList.slice()
        let flag = false
        for (let i = 0; i < controlList.length; ++i) {
            if (/select/i.test(controlList[i].type) && helpers[i]) {
                helpers[i] = false
                flag = true
            }
        }
        if (flag) {
            this.setState({
                controlHelperList: helpers
            })
        }
        this.setState({
            showGroupSelect: false,
            showServerSelect: false
        })
    }

    inputCustomField = (e, index) => {
        const values = this.state.customValue.slice()
        const errors = this.state.customError.slice()
        const value = e.target.value
        values[index] = value
        errors[index] = !((value || '').trim())
        this.setState({
            customValue: values,
            customError: errors
        })
    }

    renderCustomControl = (control, index) => {
        const { lang } = this.context.user
        const value = this.state.customValue[index]
        const error = this.state.customError[index]
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n(control.value, lang)}</div>
            </div>
            <div className='form-widget-wrapper'>
                <input type='text' name={`cf_${index}`} className={error ? 'form-input with-error' : 'form-input'} value={value} maxLength={30} placeholder={i18n(`请填写${control.value}`, lang)} onChange={e => this.inputCustomField(e, index)} autocomplete="off"></input>
            </div>
        </div>
    }

    renderTextControl = (control, index, value, error) => {
        const { lang } = this.context.user
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n(control.title, lang)}</div>
            </div>
            <div className={control.disabled ? 'form-widget-wrapper disabled' : 'form-widget-wrapper'}>
                <input type='text' name={`bpf_${index}`} className={error ? 'form-input with-error' : 'form-input'} value={value} maxLength={control.maxLength || 20} placeholder={i18n(control.hint || '请填写', lang)} onChange={e => this.inputText(e, control, index)} autocomplete="off" disabled={control.disabled}></input>
            </div>
        </div>
    }

    renderPasswordControl = (control, index, helper, value, error) => {
        const { lang } = this.context.user
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n(control.title, lang)}</div>
            </div>
            <div className={control.disabled ? 'form-widget-wrapper with-control disabled' : 'form-widget-wrapper with-control'}>
                <input type={helper ? 'text' : 'password'} name={`bpf_${index}`} className={error ? 'form-input with-error' : 'form-input'} value={value} maxLength={control.maxLength || 20} placeholder={i18n(control.hint || '请填写', lang)} onChange={e => this.inputText(e, control, index)} autocomplete="off" disabled={control.disabled}></input>
                <i className={helper ? 'form-control password-hide' : 'form-control password-show'} onClick={() => this.toggleHelper(index)}></i>
            </div>
        </div>
    }

    toggleGroupSelect = e => {
        e.stopPropagation()
        this.setState({
            showGroupSelect: !this.state.showGroupSelect,
            showServerSelect: false
        })
    }

    toggleServerSelect = e => {
        e.stopPropagation()
        this.setState({
            showGroupSelect: false,
            showServerSelect: !this.state.showServerSelect
        })
    }

    chooseGroup = (groupCode, groupName) => {
        if (groupCode === this.state.groupCode) {
            return
        }
        const newState = {
            groupCode,
            groupName,
            groupError: false
        }
        if (this.state.showServer) {
            newState.serverList = this.state.serverMap[groupCode] || []
            newState.serverCode = null
            newState.serverName = ''
        }
        this.setState(newState)
    }

    chooseServer = (serverCode, serverName) => {
        this.setState({
            serverCode,
            serverName
        })
        this.getTGoodsId(this.props.goodsInfo.skuId, serverCode)
    }

    renderGroupSelect = () => {
        const { lang } = this.context.user
        const { groupList, groupName, groupError, showGroupSelect } = this.state
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n('大区', lang)}</div>
            </div>
            <div className='form-widget-wrapper with-control' onClick={this.toggleGroupSelect}>
                {
                    groupName
                        ? <p className='form-value select'>{groupName}</p>
                        : <p className={groupError ? 'form-unset with-error select' : 'form-unset select'}>{i18n('请选择大区', lang)}</p>
                }
                <i className={showGroupSelect ? 'form-control drop-up' : 'form-control drop-down'}></i>
                {
                    showGroupSelect && <div className='form-drop-down-wrapper'>
                        <div className='form-drop-down-window'>
                            <ul>
                                {
                                    groupList.map(g => (<li className='drop-item' onClick={() => this.chooseGroup(g.code, g.name)}>{g.name}</li>))
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    renderServerSelect = () => {
        const { lang } = this.context.user
        const { serverList, serverName, serverError, showServerSelect } = this.state
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n('服务器', lang)}</div>
            </div>
            <div className='form-widget-wrapper with-control' onClick={this.toggleServerSelect}>
                {
                    serverName
                        ? <p className='form-value select'>{serverName}</p>
                        : <p className={serverError ? 'form-unset with-error select' : 'form-unset select'}>{i18n('请选择服务器', lang)}</p>
                }
                <i className={showServerSelect ? 'form-control drop-up' : 'form-control drop-down'}></i>
                {
                    showServerSelect && <div className='form-drop-down-wrapper'>
                        <div className='form-drop-down-window'>
                            <ul>
                                {
                                    serverList.map(s => (<li className='drop-item' onClick={() => this.chooseServer(s.code, s.name)}>{s.name}</li>))
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    renderSingleSelect = (control, index, helper, value, error) => {
        const { lang } = this.context.user
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n(control.title, lang)}</div>
            </div>
            <div className={control.disabled ? 'form-widget-wrapper with-control disabled' : 'form-widget-wrapper with-control'} onClick={e => this.toggleHelperWithEvent(e, control, index)}>
                {
                    value ? <p className='form-value select'>{value}</p> : <p className={error ? 'form-unset with-error select' : 'form-unset select'}>{i18n(control.hint || '请选择', lang)}</p>
                }
                <i className={helper ? 'form-control drop-up' : 'form-control drop-down'}></i>
                {
                    helper && <div className='form-drop-down-wrapper'>
                        <div className='form-drop-down-window'>
                            <ul>
                                {
                                    control.options.map(option => (<li className='drop-item' onClick={() => this.selectOption(control, option, index)}>{option}</li>))
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    renderMultiSelect = (control, index, helper, value, error) => {
        const { lang } = this.context.user
        return <div className='form-item-wrapper'>
            <div className='form-label-wrapper'>
                <div className='form-label'>{i18n(control.title, lang)}</div>
            </div>
            <div className={control.disabled ? 'form-widget-wrapper with-control disabled' : 'form-widget-wrapper with-control'} onClick={e => this.toggleHelperWithEvent(e, control, index)}>
                {
                    value.length
                        ? <p className='form-value select'>
                            {
                                value.map(item => (<span className='form-value-item'>{item}</span>))
                            }
                        </p>
                        : <p className={error ? 'form-unset with-error select' : 'form-unset select'}>{i18n(control.hint || '请选择', lang)}</p>
                }
                <i className={helper ? 'form-control drop-up' : 'form-control drop-down'}></i>
                {
                    helper && <div className='form-drop-down-wrapper'>
                        <div className='form-drop-down-window'>
                            <ul>
                                {
                                    control.options.map(option => (<li className={value.includes(option) ? 'drop-item active' : 'drop-item'} onClick={e => this.toggleOptionWithEvent(e, control, option, index)}>{option}</li>))
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    renderControl = (control, index) => {
        const { controlHelperList, controlValueList, controlErrorList } = this.state
        const helper = controlHelperList[index]
        const value = controlValueList[index]
        const error = controlErrorList[index]
        switch (control.type) {
            case TextControl:
            case NumericControl:
                return this.renderTextControl(control, index, value, error)
            case PasswordControl:
                return this.renderPasswordControl(control, index, helper, value, error)
            case SingleSelectControl:
            case DropDownSingleSelectControl:
                return this.renderSingleSelect(control, index, helper, value, error)
            case MultiSelectControl:
            case DropDownMultiSelectControl:
                return this.renderMultiSelect(control, index, helper, value, error)
            default:
                return null
        }
    }

    toggleHelper = index => {
        const helpers = this.state.controlHelperList.slice()
        helpers[index] = !helpers[index]
        this.setState({
            controlHelperList: helpers
        })
    }

    toggleHelperWithEvent = (e, control, index) => {
        e.stopPropagation()
        const { controlList, controlHelperList } = this.state
        const helpers = controlHelperList.slice()
        for (let i = 0; i < controlList.length; ++i) {
            if (i === index) {
                !control.disabled && (helpers[i] = !helpers[i])
            } else if (/select/i.test(controlList[i].type)) {
                helpers[i] = false
            }
        }
        this.setState({
            controlHelperList: helpers
        })
    }

    // inputPhone = e => {
    //     const phone = e.target.value
    //     const phoneError = !/^\d+$/.test(phone)
    //     this.setState({
    //         phone,
    //         phoneError
    //     })
    // }

    // resetPhone = () => {
    //     this.setState({
    //         phone: '',
    //         phoneError: false
    //     })
    // }

    inputText = (e, control, index) => {
        const values = this.state.controlValueList.slice()
        const errors = this.state.controlErrorList.slice()
        let value = e.target.value
        values[index] = value
        if (control.type === NumericControl) {
            value = Number(value)
            if (
                isNaN(value)
                || (typeof control.minValue === 'number' && value < control.minValue)
                || (typeof control.maxValue === 'number' && value > control.maxValue)
            ) {
                errors[index] = true
            } else {
                errors[index] = false
            }
        } else {
            errors[index] = !((value || '').trim())
        }
        this.setState({
            controlValueList: values,
            controlErrorList: errors
        })
    }

    selectOption = (control, value, index) => {
        if (control.disabled) {
            return
        }
        const values = this.state.controlValueList.slice()
        const errors = this.state.controlErrorList.slice()
        values[index] = value
        errors[index] = false
        this.setState({
            controlValueList: values,
            controlErrorList: errors
        })
    }

    toggleOption = (control, value, index) => {
        if (control.disabled) {
            return
        }
        const values = this.state.controlValueList.slice()
        const errors = this.state.controlErrorList.slice()
        const tmp = values[index]
        const newValues = []
        let flag = false
        for (let i = 0; i < tmp.length; ++i) {
            if (tmp[i] === value) {
                flag = true
            } else {
                newValues.push(tmp[i])
            }
        }
        if (!flag) {
            newValues.push(value)
        }
        values[index] = newValues
        errors[index] = !newValues.length && control.required
        this.setState({
            controlValueList: values,
            controlErrorList: errors
        })
    }

    toggleOptionWithEvent = (e, control, value, index) => {
        e.stopPropagation()
        this.toggleOption(control, value, index)
    }

    checkAreaForm = () => {
        const { showGroup, showServer, groupCode, serverCode } = this.state
        let valid = true
        let errTip = ''
        if (showGroup) {
            if (!groupCode) {
                valid = false
                errTip = '请选择大区'
                this.setState({
                    groupError: true
                })
            }
            if (showServer && !serverCode) {
                valid = false
                !errTip && (errTip = '请选择服务器')
                this.setState({
                    serverError: true
                })
            }
        }
        this.tipError(errTip)
        return valid
    }

    checkCustomForm = () => {
        const { customForm, customValue, customError } = this.state
        let valid = true
        let errTip = ''
        const errors = customError.slice()
        for (let i = 0; i < customForm.length; ++i) {
            let value = customValue[i]
            if (value && value.trim()) {
                errors[i] = false
            } else {
                errors[i] = true
                valid = false
                !errTip && (errTip = `请填写${customForm[i].value}`)
            }
        }
        this.setState({
            customError: errors
        })
        this.tipError(errTip)
        return valid
    }

    checkTemplateForm = () => {
        const { controlList, controlValueList, controlErrorList } = this.state
        let valid = true
        let errTip = ''
        // if (!/^\d+$/.test(phone)) {
        //     valid = false
        //     errTip = '请填写正确的手机号'
        //     this.setState({
        //         phoneError: true
        //     })
        // }
        const errors = controlErrorList.slice()
        for (let i = 0; i < controlList.length; ++i) {
            let { type, minValue, maxValue, required, disabled } = controlList[i]
            if (disabled) {
                continue
            }
            let value = controlValueList[i]
            if (type === TextControl || type === PasswordControl || type === SingleSelectControl || type === DropDownSingleSelectControl) {
                if (value && value.trim()) {
                    errors[i] = false
                } else {
                    errors[i] = true
                    valid = false
                    !errTip && (errTip = this.getErrorInfo(controlList[i]))
                }
            } else if (type === NumericControl) {
                let num = Number(value)
                if (
                    isNaN(num)
                    || (typeof minValue === 'number' && num < minValue)
                    || (typeof maxValue === 'number' && num > maxValue)
                ) {
                    errors[i] = true
                    valid = false
                    !errTip && (errTip = this.getErrorInfo(controlList[i]))
                } else {
                    errors[i] = false
                }
            } else if (type === MultiSelectControl || type === DropDownMultiSelectControl) {
                if (!value.length && required) {
                    errors[i] = true
                    valid = false
                    !errTip && (errTip = this.getErrorInfo(controlList[i]))
                } else {
                    errors[i] = false
                }
            }
        }
        this.setState({
            controlErrorList: errors
        })
        this.tipError(errTip)
        return valid
    }

    getTemplateFormData = () => {
        const { controlValueList, json } = this.state
        if (!json) {
            return null
        }
        const dataObject = JSON.parse(json)
        let index = 0
        dataObject.sections.forEach(section => {
            let { tables } = section
            if (Array.isArray(tables) && tables.length) {
                tables.forEach(table => {
                    let { controls, headers } = table
                    controls = (controls || [])[0]
                    if (Array.isArray(controls) && Array.isArray(headers) && controls.length === headers.length && controls.length) {
                        controls.forEach(control => {
                            let value = controlValueList[index++]
                            if (bptvBoolean(value)) {
                                if (control.type === MultiSelectControl || control.type === DropDownMultiSelectControl) {
                                    control.values = value
                                } else {
                                    control.value = value
                                }
                            }
                        })
                    }
                })
            }
        })
        return dataObject
    }

    getCustomFormData = () => {
        const { customForm, customValue } = this.state
        if (!customForm.length) {
            return null
        }
        const dataObject = {}
        for (let i = 0; i < customForm.length; ++i) {
            dataObject[customForm[i].data] = customValue[i]
        }
        return dataObject
    }

    getAreaFormData = () => {
        const { showGroup, showServer, groupCode, groupName, serverCode, serverName, tGoodsId } = this.state
        if (!showGroup) {
            return null
        }
        const dataObject = {
            goodsId: tGoodsId,
            region: groupCode,
            groupName,
            buyNum: 1
        }
        if (showServer) {
            dataObject.server = serverCode
            dataObject.serverName = serverName
        }
        return dataObject
    }

    getTGoodsId = (skuId, serverCode) => {
        const { gid } = this.state
        gid && getPriceByArea(skuId, gid, serverCode).then(({ goods_id: tGoodsId }) => {
            this.setState({
                tGoodsId
            })
        })
    }

    getErrorInfo = control => {
        let hint = /select/i.test(control.type) ? '请选择' : '请填写'
        hint += control.title
        if (control.type === NumericControl) {
            hint += '，必须为'
            if (typeof control.minValue === 'number') {
                hint += typeof control.maxValue === 'number' ? `${control.minValue}-${control.maxValue}的` : `不小于${control.minValue}的`
            } else if (typeof control.maxValue === 'number') {
                hint += `不大于${control.maxValue}的`
            }
            hint += '数字'
        }
        return hint
    }

    tipError = errTip => {
        if (!errTip) {
            return
        }
        if (this.errTipTimer) {
            clearTimeout(this.errTipTimer)
        }
        this.errTipTimer = setTimeout(() => {
            this.errTipTimer = null
            this.setState({
                errTip: ''
            })
        }, 3500)
        this.setState({
            errTip
        })
    }

    submit = () => {
        if (this.readyState < 2) {
            return this.tipError(`服务繁忙，请稍后重试`)
        }
        const customFormCheckResult = this.checkCustomForm()
        const areaFormCheckResult = this.checkAreaForm()
        const templateCheckResult = this.checkTemplateForm()
        if (templateCheckResult && areaFormCheckResult && customFormCheckResult && !this.submitFlag) {
            this.submitFlag = true
            const params = {}
            const thirdExt = this.getTemplateFormData() || this.getAreaFormData()
            const ext = this.getCustomFormData()
            thirdExt && (params.thirdExt = JSON.stringify(thirdExt))
            ext && (params.ext = JSON.stringify(ext))
            deliverWithFormData(this.props.pocketDetailId, 0, params).then(() => {
                this.props.deliverWithForm()
            }).catch(e => {
                this.submitFlag = false
                this.tipError(e.message)
            })
        }
    }

    render() {
        const { controlList, errTip, customForm, showGroup, showServer } = this.state
        const { goodsInfo } = this.props
        const { lang } = this.context.user
        return <div className='pocket-deliver-container'>
            <div className='pocket-deliver-window'>
                <div className='pocket-deliver-content'>
                    <div className='goods-info'>
                        <p className='recharge-title'>{i18n('直充', lang)}</p>
                        <div className='info-wrapper'>
                            <div className='info-img-wrapper'>
                                <img alt='' className='info-img' src={getImgUrl(goodsInfo.imgUrl)}></img>
                            </div>
                            <div className='info-name'>
                                <span className='info-value'>
                                    <span className='yen'>&yen;</span>
                                    {goodsInfo.primePrice}
                                </span>
                                <p className='name-wrapper'>{i18n(goodsInfo.name, lang)}</p>
                            </div>
                            <p className='info-addition'><span className='info-num'>x1</span>{i18n(goodsInfo.skuName, lang)}</p>
                        </div>
                        <input type='text' name='h-a-a' className='hook-autofill'></input>
                        <input type='password' name='h-a-p' className='hook-autofill'></input>
                    </div>
                    {/* <div className='phone-info'>
                        <div className='form-item-wrapper'>
                            <div className='form-label-wrapper'>
                                <div className='form-label'>{i18n('手机号', lang)}</div>
                            </div>
                            <div className='form-widget-wrapper with-control'>
                                <input type='text' name='pocket-deliver-phone' className={phoneError ? 'form-input with-error' : 'form-input'} value={phone} placeholder='请输入手机号' onChange={this.inputPhone}></input>
                                <i className='form-control cancel' onClick={this.resetPhone}></i>
                            </div>
                        </div>
                    </div> */}
                    <div className='form-container'>
                        {
                            controlList.map((c, i) => this.renderControl(c, i))
                        }
                        {
                            showGroup && this.renderGroupSelect()
                        }
                        {
                            showServer && this.renderServerSelect()
                        }
                        {
                            customForm.map((c, i) => this.renderCustomControl(c, i))
                        }
                    </div>
                </div>
            </div>
            <div className='pocket-submit-wrapper'>
                <button className='submit-btn' onClick={this.submit}>{i18n('提交', lang)}</button>
            </div>
            {
                !!errTip && <div className='mobile-tip-container'>{i18n(errTip, lang)}</div>
            }
        </div>
    }
}

PocketDeliver.contextType = AppContext
export default PocketDeliver