import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, getImgUrl, getLevelClassName, getLevelDisplayName, hbsBoolean } from '../../common/util'
import ee, { EVENT_TIP, EVENT_RECYCLE_OVER } from '../../common/util/event'
import { recyclePocketItems } from './service'
import './index.scss'

class Recyle extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            totalBattery: this.props.selectedList.reduce((total, item) => total + item.recycleBattery, 0).toFixed(2),
            showResultModal: false,
            resultBattery: '',
            page: 1,
            pages: Math.ceil(this.props.selectedList.length / 4),
            ulStyle: {
                transform: 'translateX(0)'
            }
        }
        this.recycleFlag = false
    }

    confirmRecycle = e => {
        _sr('立即回收', '物品回收-确认回收', '弹窗', '', 'recycle-submit', e)
        if (this.recycleFlag) {
            return
        }
        this.recycleFlag = true
        const { selectedList, submit } = this.props
        recyclePocketItems(selectedList.map(i => i.subOrderId).join(',')).then(({ recycleBattery, batteryAmount }) => {
            this.setState({
                resultBattery: recycleBattery.toFixed(2),
                showResultModal: true
            })
            this.context.updateUserInfo({
                battery: batteryAmount
            })
            submit && submit()
        }).catch(e => {
            e.code !== 401 && ee.emit(EVENT_TIP, e.message)
        }).finally(() => {
            this.recycleFlag = false
        })
    }

    prevPage = () => {
        const page = this.state.page - 1
        if (page > 0) {
            this.setState({
                page,
                ulStyle: {
                    transform: page === 1 ? 'translateX(0)' : `translateX(-${(page - 1) * 344}px)`
                }
            })
        }
    }

    nextPage = () => {
        const { page, pages } = this.state
        if (page < pages) {
            this.setState({
                page: page + 1,
                ulStyle: {
                    transform: `translateX(-${page * 344}px)`
                }
            })
        }
    }

    resultAction = e => {
        _sr('去开盲盒', '物品回收-回收成功', '弹窗', '', 'recycle-result-btn', e)
        ee.emit(EVENT_RECYCLE_OVER)
    }

    render() {
        const { totalBattery, showResultModal, resultBattery, page, pages, ulStyle } = this.state
        const { close, selectedList } = this.props
        const { lang } = this.context.user
        return showResultModal
            ? <div className='pocket-detail-modal-container'>
                <div className='pocket-detail-modal'>
                    <i className='pocket-detail-modal-close' onClick={close}></i>
                    <i className='pocket-detail-modal-icon recycle'></i>
                    <p className='pocket-detail-modal-title legend'>{i18n('回收成功', lang)}</p>
                    <p className='pocket-detail-modal-tip mt24'>{i18n('恭喜获得', lang)}</p>
                    <p className='pocket-detail-modal-title battery'>x{resultBattery}</p>
                    <button className='pocket-detail-modal-btn over' onClick={this.resultAction}>{i18n('去开盲盒', lang)}</button>
                </div>
            </div>
            : <div className='pocket-recycle-modal-container'>
                <div className='pocket-recycle-modal'>
                    <i className='pocket-recycle-close' onClick={close}></i>
                    <div className='pocket-recycle-header'>{i18n('确认回收', lang)}</div>
                    <div className='pocket-recycle-body'>
                        <p className='pocket-recycle-scan'>{i18n('待回收物品：', lang)}<span className='count'>{selectedList.length}</span>{i18n('个', lang)}</p>
                        <div className={pages > 1 ? 'recycle-items-window-wrapper m-page' : 'recycle-items-window-wrapper'}>
                            <div className='recycle-items-window'>
                                <ul className='recycle-items-ul' style={ulStyle}>
                                    {
                                        selectedList.map(item => (<li className={`recycle-item ${getLevelClassName(item.borderColor)}`}>
                                            {
                                                hbsBoolean(item.pcMhImgUrl) && <img alt='' className='recycle-item-img' src={getImgUrl(item.pcMhImgUrl)}></img>
                                            }
                                            <div className='recycle-item-level'>
                                                <div className='level-txt'>{i18n(getLevelDisplayName(item.borderColor), lang)}</div>
                                            </div>
                                        </li>))
                                    }
                                </ul>
                            </div>
                            {
                                pages > 1 && <button className={page > 1 ? 'items-arrow left' : 'items-arrow left disable'} onClick={this.prevPage}></button>
                            }
                            {
                                pages > 1 && <button className={page < pages ? 'items-arrow right' : 'items-arrow right disable'} onClick={this.nextPage}></button>
                            }
                        </div>
                        <p className='recycle-battery-tip'>{i18n('可获得电池数量', lang)}</p>
                        <p className='recycle-battery-count'>{totalBattery}</p>
                        <p className='recycle-tip-title'>{i18n('提示：', lang)}</p>
                        <p className='recycle-tip-content first-line'>{i18n('1.回收操作不可撤销；', lang)}</p>
                        <p className='recycle-tip-content'>{i18n('2.可获得电池数=购买盲盒时消耗的电池数（赠送电池不支持回收）*70%', lang)}</p>
                        <p className='recycle-tip-content'>{i18n('3.电池仅可用于盲盒抽奖，', lang)}<span className='highlight'>{i18n('无法提现', lang)}；</span></p>
                        <button className='recycle-submit-btn' onClick={this.confirmRecycle}>{i18n('立即回收', lang)}</button>
                    </div>
                </div>
            </div>
    }
}

Recyle.contextType = AppContext
export default Recyle