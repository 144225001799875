import React from 'react'
import './index.scss'
import ee, { EVENT_TIP, EVENT_USER_CERT } from '../../common/util/event'
import { commitAuth } from './service'
import { updateCertInfoLocally, i18n } from '../../common/util'
import { getAuthStatus } from '../service'
import { AppContext } from '../../config/context'
import { ShareDomains } from '../../config'

class Cert extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            realname: '',
            icno: '',
            certTip: '',
            isAuth: 2,
            share: false,
            shareUrl: ''
        }
        this.shareCount = 0
        this.shareFinalCallback = () => { }
        this.certFlag = false
    }

    componentDidMount() {
        const { isAuth: oIsAuth, age: oAge } = this.context.user
        const { isForce = false } = this.props
        if (isForce && oIsAuth === 1 && oAge < 18) {
            return this.setState({ isAuth: 1 })
        }
        getAuthStatus().then(data => {
            const { isOn, isAuth, age } = data
            this.shareFinalCallback = () => {
                if ((!isForce && !isOn) || (isAuth === 1 && age >= 18)) {
                    return this.submit()
                }
                if (isAuth !== 1) {
                    ee.emit(EVENT_TIP, '请先完善实名认证', 'warn')
                }
                this.setState({ isAuth: isAuth !== 1 ? 0 : 1 })
            }
            if (oIsAuth !== isAuth || oAge !== age) {
                this.context.updateUserInfo({ isAuth, age })
                const shareUrl = '/certCallbackForCORS.html' + updateCertInfoLocally(isAuth, age)
                this.setState({
                    share: true,
                    shareUrl
                })
            } else {
                this.shareFinalCallback()
            }
        }).catch(e => {
            e.code !== 401 && ee.emit(EVENT_TIP, e.message)
            this.close()
        })
    }

    shareCallback = () => {
        this.shareCount += 1
        if (this.shareCount === ShareDomains.length) {
            this.shareFinalCallback()
        }
    }

    submit = () => {
        ee.emit(EVENT_USER_CERT)
        this.close()
    }

    close = () => {
        this.props.close && this.props.close()
    }

    inputRealName = e => {
        this.setState({
            realname: e.target.value
        })
    }

    inputICNO = e => {
        this.setState({
            icno: e.target.value
        })
    }

    cert = () => {
        if (this.certFlag) {
            return
        }
        const { realname, icno } = this.state
        if (!realname) {
            return this.setState({
                certTip: '请输入您的真实姓名'
            })
        }
        if (!icno) {
            return this.setState({
                certTip: '请输入您的身份证号'
            })
        }
        if (!/^\d{17}[0-9xX]$/.test(icno) && !/^\d{15}$/.test(icno)) {
            return this.setState({
                certTip: '身份证号格式错误'
            })
        }
        this.certFlag = true
        this.setState({
            certTip: '',
            share: false
        })
        commitAuth(realname, icno).then(({ age }) => {
            this.shareCount = 0
            this.shareFinalCallback = () => {
                if (age >= 18) {
                    ee.emit(EVENT_TIP, '恭喜您，认证成功', 'success')
                    return this.submit()
                }
                this.setState({ isAuth: 1 })
            }
            this.context.updateUserInfo({ isAuth: 1, age })
            const shareUrl = '/certCallbackForCORS.html' + updateCertInfoLocally(1, age)
            this.setState({
                share: true,
                shareUrl
            })
        }).catch(e => {
            e.code === 401 ? this.close() : ee.emit(EVENT_TIP, e.message)
        }).finally(() => {
            this.certFlag = false
        })
    }

    goToDetail = () => {
        // window.open('https://steamdoge.com/help/question/14')
        // this.close()
        location.href = '/'
    }

    render() {
        const { realname, icno, certTip, isAuth, share, shareUrl } = this.state
        const { lang } = this.context.user
        return <div className={isAuth < 2 ? 'account-certification-wrapper isModal' : 'account-certification-wrapper'}>
            {
                isAuth === 1 && <div className="account-limit-wrapper">
                    <i className="certification-close" onClick={this.close}></i>
                    <i className="limit-icon"></i>
                    <p className="limit-title">{i18n('保护未成年人', lang)}</p>
                    <p className="limit-tip">{i18n('响应国家政策，未成年人不可参与网络游戏交易', lang)}</p>
                    <button className="limit-btn" onClick={this.goToDetail}>{i18n('点击查看详情', lang)}</button>
                </div>
            }
            {
                isAuth === 0 && <div className="account-certification">
                    <i className="certification-close" onClick={this.close}></i>
                    <h3 className="certification-title">{i18n('实名认证', lang)}</h3>
                    <div className="certification-tip">
                        <p>{i18n('按照国家政策要求，游戏玩家需要先进行实名认证，才可进行相关游戏交易。', lang)}</p>
                        <p>{i18n('本平台会对您的个人信息进行严格保密。', lang)}</p>
                    </div>
                    <div className="certification-form">
                        <div className="certification-input-wrapper">
                            <input type="text" name="r-realname" className="certification-input" placeholder={i18n('请输入真实姓名，需和您绑定的手机号一致', lang)} value={realname} onChange={this.inputRealName}></input>
                        </div>
                        <div className={certTip.length > 0 ? 'certification-input-wrapper icwe' : 'certification-input-wrapper icno'}>
                            <input type="text" name="r-icno" className="certification-input" placeholder={i18n('请输入身份证号', lang)} maxLength="30" value={icno} onChange={this.inputICNO}></input>
                        </div>
                        <div className={certTip.length > 0 ? 'certification-error show' : 'certification-error'}><i className="error-icon"></i>{i18n(certTip, lang)}</div>
                        <button className="certification-submit" onClick={this.cert}>{i18n('提交认证', lang)}</button>
                        <button className="certification-cancel" onClick={this.close}>{i18n('稍后再填', lang)}</button>
                    </div>
                </div>
            }
            {
                share && <div className='account-certification-share-wrapper'>
                    {
                        ShareDomains.map(domain => (<iframe src={domain + shareUrl} onLoad={this.shareCallback} onError={this.shareCallback}></iframe>))
                    }
                </div>
            }
        </div>
    }
}

Cert.contextType = AppContext
export default Cert