import { Promise as ES6Promise } from 'es6-promise'
import 'fetch-ie8'

if (!('Promise' in window)) {
    window.Promise = ES6Promise
} else if (!('finally' in window.Promise.prototype)) {
    window.Promise.prototype.finally = ES6Promise.prototype.finally
}

if (!Array.prototype.find) {
    Array.prototype.find = function find(predicate) {
        if (this == null) {
            throw new TypeError('"this" is null or not defined')
        }
        var o = Object(this)
        var len = o.length >>> 0
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function')
        }
        var thisArg = arguments[1]
        var k = 0
        while (k < len) {
            var kValue = o[k]
            if (predicate.call(thisArg, kValue, k, o)) {
                return kValue
            }
            k++
        }
        return undefined
    }
}

if (!Array.prototype.includes) {
    Array.prototype.includes = function includes(valueToFind, fromIndex) {
        if (this == null) {
            throw new TypeError('"this" is null or not defined')
        }
        var o = Object(this)
        var len = o.length >>> 0
        if (len === 0) {
            return false
        }
        var n = fromIndex | 0
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)
        function sameValueZero(x, y) {
            return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
        }
        while (k < len) {
            if (sameValueZero(o[k], valueToFind)) {
                return true
            }
            k++
        }
        return false
    }
}

if (!window.console) {
    window.console = {
        log: function log() { },
        info: function info() { },
        warn: function warn() { },
        error: function error() { }
    }
}