import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, throttle } from '../../common/util'
import { getBagRecord } from './service'
import ee, { EVENT_TIP, EVENT_USER_NOT_BOUND } from '../../common/util/event'
import BatteryRecharge from '../batteryRecharge'
import './wallet.scss'

const PageSize = 24
const FormWindowELId = 'bag-wallet-scroller'

class BagWallet extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tabIndex: 2,
            page: 1,
            ready: false,
            list: [],
            showRechargeModal: false
        }
        this.hasMore = false
        this.loadFlag = false
    }

    initTab = tabIndex => {
        getBagRecord(tabIndex, 1, PageSize).then(({ pages, records }) => {
            const dataExist = Array.isArray(records) && records.length
            this.setState({
                page: 1,
                list: dataExist ? records : [],
                ready: true
            }, () => {
                this.hasMoreEffect(pages > 1)
                dataExist && (document.getElementById(FormWindowELId).scrollTop = 0)
            })
        }).catch(e => {
            if (e.code !== 401) {
                this.setState({
                    page: 1,
                    list: [],
                    ready: true
                })
                this.hasMoreEffect(false)
                ee.emit(EVENT_TIP, e.message)
            }
        })
    }

    componentDidMount() {
        this.initTab(this.state.tabIndex)
    }

    chooseTab = (tabIndex, action, e) => {
        if (tabIndex !== this.state.tabIndex) {
            this.setState({
                tabIndex
            })
            this.initTab(tabIndex)
        }
        _sr(action, '个人中心-我的钱包', '顶部', '', `bag-wallet-tab-${tabIndex + 1}`, e)
    }

    hasMoreEffect = hasMore => {
        if (hasMore !== this.hasMore) {
            this.hasMore = hasMore
            const el = document.getElementById(FormWindowELId)
            hasMore
                ? el.addEventListener('scroll', this.scrollListener)
                : el.removeEventListener('scroll', this.scrollListener)
        }
    }

    loadMoreData = () => {
        if (!this.hasMore || this.loadFlag) {
            return
        }
        this.loadFlag = true
        const { tabIndex, page } = this.state
        getBagRecord(tabIndex, page + 1, PageSize).then(({ pages, records }) => {
            this.setState({
                page: page + 1,
                list: this.state.list.concat(records)
            }, () => {
                this.loadFlag = false
            })
            this.hasMoreEffect(pages > page + 1)
        })
    }

    scrollListener = throttle(() => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById(FormWindowELId)
        if (scrollTop + clientHeight >= scrollHeight) {
            this.loadMoreData()
        }
    })

    showRechargeModal = e => {
        _sr('充值', '个人中心-我的钱包', '底部', '', 'bag-wallet-recharge', e)
        if (!this.context.user.phone) {
            window._imp = true
            window._impc = true
            return ee.emit(EVENT_USER_NOT_BOUND)
        }
        this.setState({
            showRechargeModal: true
        })
    }

    closeRechargeModal = () => {
        this.setState({
            showRechargeModal: false
        })
    }

    render() {
        const { tabIndex, ready, list, showRechargeModal } = this.state
        const { lang, battery } = this.context.user
        return <div className='bag-wallet-container'>
            <div className='bag-wallet-form-wrapper'>
                <ul className='bag-content-tabs'>
                    <li className={tabIndex === 0 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(0, '全部明细', e)}>{i18n('全部明细', lang)}</li>
                    <li className={tabIndex === 1 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(1, '煤炭明细', e)}>{i18n('煤炭明细', lang)}</li>
                    <li className={tabIndex === 2 ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(2, '电池明细', e)}>{i18n('电池明细', lang)}</li>
                </ul>
                <div className='bag-wallet-form-header'>
                    <div className='bag-wallet-form-th index-1'>{i18n('时间', lang)}</div>
                    <div className='bag-wallet-form-th index-2'>{i18n('订单号', lang)}</div>
                    {
                        tabIndex === 0 && <div className='bag-wallet-form-th index-5'>{i18n('分类', lang)}</div>
                    }
                    <div className={tabIndex === 0 ? 'bag-wallet-form-th index-6' : 'bag-wallet-form-th index-3'}>{i18n('描述', lang)}</div>
                    <div className='bag-wallet-form-th index-4'>{i18n('数量', lang)}</div>
                    <div className='bag-wallet-form-th index-5'>{i18n('类型', lang)}</div>
                </div>
                <div className={ready && list.length > 0 ? 'bag-wallet-form-window' : 'bag-wallet-form-window hidden'} id={FormWindowELId}>
                    <ul className='bag-wallet-form-ul'>
                        {
                            list.map(item => (<li className='bag-wallet-form-row'>
                                <div className='form-row-td index-1'>{item.transTime.substring(5)}</div>
                                <div className='form-row-td index-2'>{item.transSeq}</div>
                                {
                                    tabIndex === 0 && <div className='form-row-td index-5'>{i18n(item.currency === 'BATTERY' ? '电池' : '煤炭', lang)}</div>
                                }
                                <div className={tabIndex === 0 ? 'form-row-td index-6' : 'form-row-td index-3'}>{i18n(item.transPurpose, lang)}</div>
                                {
                                    item.transType === 'INCOME'
                                        ? <div className='form-row-td index-4 income'>+{item.transAmount}</div>
                                        : <div className='form-row-td index-4'>-{item.transAmount}</div>
                                }
                                {
                                    item.transType === 'INCOME'
                                        ? <div className='form-row-td index-5 income'>{i18n('收入', lang)}</div>
                                        : <div className='form-row-td index-5'>{i18n('支出', lang)}</div>
                                }
                            </li>))
                        }
                    </ul>
                </div>
                <div className={ready && list.length === 0 ? 'bag-empty-wrapper' : 'bag-empty-wrapper hidden'}>
                    <i className='empty-icon'></i>
                    <p className='empty-txt'>{i18n('暂无数据~', lang)}</p>
                </div>
            </div>
            <div className='bag-content-footer'>
                <button className='bag-content-footer-btn' onClick={this.showRechargeModal}>{i18n('充值', lang)}</button>
                <p className='bag-content-footer-info'>{i18n('电池余额', lang)}<i className='battery-icon'></i><span className='highlight'>{battery}</span></p>
            </div>
            {
                showRechargeModal && <BatteryRecharge close={this.closeRechargeModal}></BatteryRecharge>
            }
        </div>
    }
}

BagWallet.contextType = AppContext
export default BagWallet