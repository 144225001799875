import React from 'react'
import './index.scss'
import { goToHref, i18n } from '../../common/util'
import { AppContext } from '../../config/context'

const SHOW_TIME = 5

class CouponTip extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            seconds: SHOW_TIME
        }
        this.timer = null
    }

    componentDidMount() {
        let seconds = SHOW_TIME
        this.timer = setInterval(() => {
            --seconds
            if (seconds > 0) {
                this.setState({ seconds })
            } else {
                this.close()
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    close = () => {
        this.props.close && this.props.close()
    }

    seeOther = () => {
        const target = '/coupon/couponList.html'
        if (location.href.indexOf(target) > 0) {
            return this.close()
        }
        window.open(target)
    }

    render() {
        const { seconds } = this.state
        const { type, tip, url, dontmove = false } = this.props
        const content = type === 'success' ? '恭喜您，领取成功' : type === 'error' ? '领取失败' : tip
        const { lang } = this.context.user
        return <div className="coupon-tip-modal">
            <div className="coupon-tip-wrapper">
                <i className="close" onClick={this.close}></i>
                <i className={`coupon-tip-icon ${type}`}></i>
                <p className={`coupon-tip-content ${type}`}>{i18n(content, lang)}</p>
                <p className="coupon-tip-thx">{i18n('感谢您的参与，祝您购物愉快', lang)}</p>
                <div className="coupon-tip-btns">
                    {
                        !dontmove && type === 'success' && <button className="coupon-tip-btn use-btn" onClick={() => goToHref(url, '_blank')}>{i18n('立即使用', lang)}</button>
                    }
                    {
                        !dontmove && type === 'warning' && <button className="coupon-tip-btn use-btn huge" onClick={this.seeOther}>{i18n('看看其他优惠券', lang)}</button>
                    }
                    <button className="coupon-tip-btn close-btn" onClick={this.close}>{i18n('关闭', lang)}</button>
                </div>
                <p className="coupon-tip-timer">{i18n(seconds + '秒后自动关闭', lang)}</p>
            </div>
        </div>
    }
}

CouponTip.contextType = AppContext
export default CouponTip