import { httpPost } from '../../common/util'

export const commitAuth = (name, idCard) => {
    return httpPost({
        url: '/user/login/auth',
        data: {
            name,
            idCard
        },
        auth: true
    })
}