import React from 'react'
import { AppContext } from '../../config/context'
import { i18n, getImgUrl } from '../../common/util'
import { PayTypeMap } from '../../config'
import { getPayTypes, getWindowData } from '../service'
import { getRechargeOptions, submitRecharge, queryRechargeResult } from './service'
import ee, { EVENT_TIP, EVENT_BLINDBOX_PRELOAD } from '../../common/util/event'
import QRCodeModal from './qrcodeModal'
import Cert from '../cert'
import './index.scss'

const CustomAmountOption = 99
const MaxQueryTime = 90
const QueryInterval = 2000

class BatteryRecharge extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            payTypes: [],
            payType: 0,
            payOptions: [],
            payOption: -1,
            payAmount: '',
            payAmountInput: '',
            showQRCodeModal: false,
            qrcode: '',
            qrcodeInvalid: false,
            showCertModal: false,
            ad: null
        }
        this.tempInput = ''
        this.submitFlag = false
        this.timer = null
    }

    componentDidMount() {
        getWindowData('blindbox_ad_recharge', 2).then(ads => {
            if (ads.length) {
                this.setState({
                    ad: ads[0]
                })
            }
        })
        getPayTypes().then(payTypes => {
            if (Array.isArray(payTypes) && payTypes.length) {
                const validPayTypes = payTypes.filter(t => !!PayTypeMap[t])
                validPayTypes.length && this.setState({
                    payTypes: validPayTypes,
                    payType: validPayTypes[0]
                })
            }
        })
        getRechargeOptions().then(payOptions => {
            if (Array.isArray(payOptions) && payOptions.length) {
                this.setState({
                    payOptions
                }, () => {
                    this.initRechargeOption()
                })
            } else {
                this.initRechargeOption()
            }
        }).catch(e => {
            console.error(e)
            this.initRechargeOption()
        })
    }

    initRechargeOption = () => {
        const { rechargeWarning = 0 } = this.props
        const { payOptions } = this.state
        if (rechargeWarning > 0) {
            let payOption = -1
            let amount = Infinity
            for (let i = 0; i < payOptions.length; ++i) {
                let o = payOptions[i]
                if (o.exchangeAmount >= rechargeWarning && o.amount < amount) {
                    amount = o.amount
                    payOption = i
                }
            }
            if (payOption > -1) {
                this.setState({
                    payOption,
                    payAmount: payOptions[payOption].amount + ''
                })
            } else {
                this.setState({
                    payOption: CustomAmountOption,
                    payAmount: rechargeWarning + '',
                    payAmountInput: rechargeWarning + ''
                })
            }
        } else if (payOptions.length) {
            this.setState({
                payOption: 0,
                payAmount: payOptions[0].amount + ''
            })
        }
    }

    componentWillUnmount() {
        this.cleanTimer()
    }

    choosePayType = (payType, i, e) => {
        this.setState({
            payType
        })
        _sr('立即支付', '电池购买-充值区域', `1-${i + 1}`, { payType: PayTypeMap[payType], payAmount: this.state.payAmount }, `recharge-pay-type-${i + 1}`, e)
    }

    choosePayOption = (payOption, amount, e) => {
        const index = payOption === CustomAmountOption ? this.state.payOptions.length : payOption
        const payAmount = payOption === CustomAmountOption ? this.state.payAmountInput : amount + ''
        _sr('立即支付', '电池购买-充值区域', `${Math.ceil((index + 1) / 4)}-${(index % 4) + 1}`, { payType: PayTypeMap[this.state.payType], payAmount }, `recharge-pay-option-${index + 1}`, e)
        if (payOption === this.state.payOption) {
            return
        }
        this.setState({
            payOption,
            payAmount
        })
    }

    close = () => {
        this.props.close()
    }

    inputRechargeAmount = e => {
        let value = e.target.value
        while (value.indexOf('0') === 0) {
            value = value.substring(1)
        }
        let validInput = value
        if (!value || /^\d+$/.test(value)) {
            this.tempInput = value
        } else {
            validInput = this.tempInput
        }
        const newState = {
            payAmountInput: validInput
        }
        if (this.state.payOption === CustomAmountOption) {
            newState.payAmount = validInput
        }
        this.setState(newState)
    }

    submit = e => {
        const { payType, payAmount } = this.state
        _sr('立即支付', '电池购买-充值区域', '4-1', { payType: PayTypeMap[payType], payAmount }, 'recharge-pay-submit', e)
        const { age, isAuth } = this.context.user
        if (!(isAuth === 1 && age >= 18)) {
            return this.showCertModal()
        }
        if (!payType) {
            return ee.emit(EVENT_TIP, '请选择支付方式')
        }
        const rechargeAmount = Number(payAmount)
        if (!(rechargeAmount > 0)) {
            return ee.emit(EVENT_TIP, '请输入充值金额')
        }
        if (this.submitFlag) {
            return
        }
        this.submitFlag = true
        submitRecharge(payType, rechargeAmount.toFixed(2)).then(({ body, orderId }) => {
            this.setState({
                showQRCodeModal: true,
                qrcode: body
            }, () => {
                this.submitFlag = false
            })
            this.startTimer(orderId)
        }).catch(e => {
            if (e.code !== 401) {
                ee.emit(EVENT_TIP, e.message)
            }
            this.submitFlag = false
        })
    }

    closeQRCodeModal = () => {
        this.cleanTimer()
        this.setState({
            showQRCodeModal: false,
            qrcodeInvalid: false
        })
    }

    startTimer = orderId => {
        let queryTime = 0
        this.timer = setInterval(() => {
            ++queryTime
            queryRechargeResult(orderId).then(({ status, batteryAmount }) => {
                if (status === 2) {
                    this.onSuccess(batteryAmount)
                }
            }).catch(() => { })
            if (queryTime >= MaxQueryTime) {
                this.cleanTimer()
                this.setState({
                    qrcodeInvalid: true
                })
            }
        }, QueryInterval)
    }

    cleanTimer = () => {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }

    onSuccess = batteryAmount => {
        this.cleanTimer()
        this.context.updateUserInfo({
            battery: batteryAmount || 0
        })
        ee.emit(EVENT_BLINDBOX_PRELOAD)
        ee.emit(EVENT_TIP, '恭喜您，充值成功！', 'success')
        this.close()
    }

    showCertModal = () => {
        this.setState({
            showCertModal: true
        })
    }

    closeCertModal = () => {
        this.setState({
            showCertModal: false
        })
    }

    // submitCert = () => {
    //     this.closeCertModal()
    //     this.submit()
    // }

    render() {
        const { payTypes, payType, payOptions, payOption, payAmount, payAmountInput, showQRCodeModal, qrcode, qrcodeInvalid, showCertModal, ad } = this.state
        const { rechargeWarning = 0 } = this.props
        const { lang, battery } = this.context.user
        return <div className='battery-recharge-modal-wrapper'>
            <div className='battery-recharge-modal'>
                <i className='battery-recharge-close' onClick={this.close}></i>
                <div className='battery-recharge-header'>
                    <i className='header-icon'></i>
                    <span className='header-title'>{i18n('电池购买', lang)}</span>
                </div>
                <div className='battery-recharge-body'>
                    {
                        ad && <div className='battery-recharge-ad-wrapper'>
                            <a target={ad.target} href={ad.url} st-btn="1" st-category="电池购买-顶部广告位" st-label="顶部" st-action={ad.name} st-value={JSON.stringify([{ url: ad.url }])} st-element-id='recharge-modal-ad'>
                                <img alt='' className='battery-recharge-ad-img' src={getImgUrl(ad.imgUrl)}></img>
                            </a>
                        </div>
                    }
                    <div className='battery-recharge-balance'>
                        <div className='battery-balance'>{battery}</div>
                        {
                            rechargeWarning > 0
                                ? <p className='balance-title warn'>{i18n('电池余额不足，请先购买电池', lang)}</p>
                                : <p className='balance-title'>{i18n('电池余额', lang)}</p>
                        }
                        <p className='balance-tip'>{i18n('1元=1电池', lang)}&nbsp;&nbsp;|&nbsp;&nbsp;{i18n('电池用于购买盲盒', lang)}</p>
                    </div>
                    <div className='battery-recharge-pay-types'>
                        <div className='pay-types-label'>{i18n('支付方式', lang)}</div>
                        {
                            payTypes.map((item, i) => (<div className={item === payType ? `pay-type type-${item} active` : `pay-type type-${item}`} onClick={e => this.choosePayType(item, i, e)}>
                                <span>{i18n(PayTypeMap[item], lang)}</span>
                            </div>))
                        }
                    </div>
                    <div className='battery-recharge-options'>
                        <div className='recharge-options-label'>{i18n('充值金额', lang)}</div>
                        {
                            payOptions.map((item, index) => (<div className={index === payOption ? 'recharge-option active' : 'recharge-option'} onClick={e => this.choosePayOption(index, item.amount, e)}>
                                <p className='battery-num'>{item.exchangeAmount}</p>
                                <p className='pay-num'><span className='unit'>&yen;</span>{item.amount}</p>
                            </div>))
                        }
                        <div className={CustomAmountOption === payOption ? 'recharge-option input active' : 'recharge-option input'} onClick={e => this.choosePayOption(CustomAmountOption, 0, e)}>
                            <p className='battery-num'>{i18n(payAmountInput || '其他数量', lang)}</p>
                            <div className='pay-num-input-wrapper'>
                                <span className='label'>&yen;</span>
                                <input type='text' name='battery-amount-input' className='input' value={payAmountInput} onChange={this.inputRechargeAmount} placeholder={i18n('请输入金额', lang)} maxLength="9"></input>
                            </div>
                        </div>
                    </div>
                    <div className='recharge-btn-line'>
                        <button className='recharge-btn' onClick={this.submit}>{i18n('立即支付', lang)}</button>
                    </div>
                    <div className='recharge-desc-wrapper'>
                        <p className='recharge-desc-title'>{i18n('消费说明', lang)}</p>
                        <div className='recharge-desc-detail-wrapper'>
                            <div className='recharge-desc-detail-block'>
                                <p className='recharge-desc-detail'>{i18n('1.盲盒商品抽取存在概率性，付费请谨慎；', lang)}</p>
                                <p className='recharge-desc-detail'>{i18n('3.仅限18周岁以上玩家购买使用；', lang)}</p>
                                <p className='recharge-desc-detail'>{i18n('5.盲盒商品购买后不支持退款；', lang)}</p>
                            </div>
                            <div className='recharge-desc-detail-block'>
                                <p className='recharge-desc-detail'>{i18n('2.盲盒商品支持退单，在打开前可按实际支付电池数的70%退单；', lang)}</p>
                                <p className='recharge-desc-detail'>{i18n('4.商品抽取概率已公示在盲盒抽奖页面中；', lang)}</p>
                                <p className='recharge-desc-detail'>{i18n('6.所有类型的余额均不支持提现。', lang)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showQRCodeModal && <QRCodeModal close={this.closeQRCodeModal} payType={payType} payAmount={payAmount} qrcode={qrcode} qrcodeInvalid={qrcodeInvalid}></QRCodeModal>
            }
            {
                showCertModal && <Cert close={this.closeCertModal} isForce={true}></Cert>
            }
        </div>
    }
}

BatteryRecharge.contextType = AppContext
export default BatteryRecharge