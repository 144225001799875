export const SiteType = 'CN'
export const DomainPostfix = 'asia'
export const DomainPostfixWithDot = '.' + DomainPostfix
export const LOGIN_PARAMS_DEV = {
    url: {
        WECHAT_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/wechat/bind/login.do',
        QQ_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/qq/bind/login.do'
    },
    params: {
        companyId: 'e620ebdbcdbf47afa303d8a032d45d02',
        appKey: '57545e05d4af492b9aee2fdf8821a9d4',
        appSecret: '',
        appType: 1,
        appChannel: ''
    }
}
export const LOGIN_PARAMS_PRO = {
    url: {
        WECHAT_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/wechat/bind/login.do',
        QQ_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/qq/bind/login.do'
    },
    params: {
        companyId: '024ce752b4c747f0b9eb9f65e709416d',
        appKey: '9f9c4acf78414086992ea28e60bac3a3',
        appSecret: '',
        appType: 1,
        appChannel: ''
    }
}
const _url = location.protocol + '//' + location.host
export const BaseUrl = process.env.BUILD_ENV === 'pro'
    ? _url + '/api'
    : 'https://dev-api.youxicdk.com' //`http://test-${SiteType.toLowerCase()}.steamcdk.com/api`
export const ImgUrl = process.env.BUILD_ENV === 'pro'
    ? (function getImgUrlByLocation() {
        const la = location.host.split('.')
        let sub
        if (la.length === 3) {
            const ss = la[0].replace(/www-?/, '').replace('2', '')
            sub = ss === 'dev' || ss === 'pre' ? ss + '-img' : 'img'
        } else {
            sub = 'img'
        }
        return location.protocol + '//' + sub + '.youxicdk.com'
    })()
    : 'https://dev-img.youxicdk.com'
export const ShareDomains = process.env.BUILD_ENV === 'pro'
    ? (function getShareDomainsByLocation() {
        const la = location.host.split('.')
        const isWWW = la[0] === 'www'
        const isHK = la[la.length - 1] === DomainPostfix
        const ret = [
            isHK ? _url.replace(DomainPostfixWithDot, '.com') : _url.replace('.com', DomainPostfixWithDot)
        ]
        if (la.length > 2 && !isWWW) {
            return ret
        }
        ret.push(isWWW ? _url.replace('www.', '') : _url.replace('//', '//www.'))
        ret.push(isWWW ? ret[0].replace('www.', '') : ret[0].replace('//', '//www.'))
        return ret
        // const domains = [location.protocol + '//' + la.join('.')]
        // la.pop()
        // if (la.length === 2) {
        //     if (la[0] === 'www') {
        //         la.shift()
        //     } else if (la[0].indexOf('www-') > -1) {
        //         la[0] = la[0].replace('www-', '')
        //     } else {
        //         la[0] = 'www-' + la[0]
        //     }
        // } else if (la.length === 1) {
        //     la.unshift('www')
        // }
        // domains.push(location.protocol + '//' + la.join('.') + '.cn')
        // domains.push(location.protocol + '//' + la.join('.') + '.asia')
        // return domains
    })()
    : _url.indexOf('-cn') > 0
        ? [_url.replace('-cn', '-hk')]
        : [_url.replace('-hk', '-cn')]
// export const platformId = process.env.NODE_ENV === 'development' ? 30 : 30
export const platformName = window._platform_ || 'pc'
export const PayTypeMap = { 1: '支付宝', 12: '支付宝', 2: '微信', 20: 'QQ', 23: 'PayerMax' }
export const PayerMaxPayType = 23
export const PayTime = 120
export const OrderStatusMap = { 0: '已取消', 1: '待支付', 2: '发货中', 12: '发货中', 3: '已退款', 5: '退款中', 11: '订单完成' }
export const MaterialObjectOrderStatusMap = { 1: '待支付', 2: '待发货', 12: '待发货', 3: '已退款', 5: '退款中', 11: '已发货' }
export const BBOrderStatusMap = { 1: '待支付', 2: '待领取CDK', 12: '待领取CDK', 3: '已退款', 5: '退款中', 11: '发货成功', 99: '发货中' }
export const BlindboxOrderStatusMap = { 1: '待支付', 11: '已打开', 12: '打开失败', 2: '未打开', 3: '退款成功', 6: '已回收' }
// export const ServiceUrl = 'https://cs.numcsc.com/chat/pc/index.html?sysNum=20dce2c6fa909a5cd62526615f27e88a&visitEntrance=www.steamcdk.cn&robotFlag=1&remark=pc'
export const ServiceFor9891Url = 'http://q.url.cn/CDOGXS?_type=wpa&qidian=true'
export const ServiceFor9891dc = 'https://tb.53kf.com/code/client/dd103f871a8e574386c10db4989acfe28/1'
export const RentWayMap = { 10: '小时', 20: '天', 30: '晚', 40: '周' }
export const RentOrderStatusMap = { 10: '待支付', 30: '待发货', 40: '租赁中', 50: '订单完成', 52: '订单完成', 60: '已退款', 70: '维权中' }
export const DefaultChannel = 'PIDTtdeOX9bG'
export const ShowHKD = SiteType === 'HK'
export const MName = '游特卖'
export const OSType = 'web'
export const OrderLink = _url + '/order/list.html'
export const OSSUrl = 'https://steamdoge-35146195688.hz.s3down.com/blindbox-PC/blindbox'
export const QQ_APPID = '102029196'
export const QQ_AuthorizeLink = 'https://youxicdk.com/service/qq/authorize'
export const WECHAT_APPID = 'wxdb7c561590f48219'
export const WECHAT_AuthorizeLink = 'https://youxicdk.com/service/wechat/authorize'
export const DistributionType = 14
export const OrderRetainTime = 15 * 60