import React from 'react'
import { AppContext } from '../../config/context'
import { BlindboxOrderStatusMap } from '../../config'
import { i18n, throttle, getImgUrl, hbsBoolean } from '../../common/util'
import ee, { EVENT_TIP } from '../../common/util/event'
import { getOrderList, getServiceUrl } from '../service'
import Recycle from '../recycle'
import PocketDetail from './pocketDetail'
import CDKModal from './cdkModal'
import './order.scss'

const Tabs = [
    { name: '全部', value: 1 },
    { name: '未打开', value: 2 },
    { name: '已打开', value: 3 },
    { name: '已回收', value: 6 },
    { name: '打开失败', value: 8 }
]
const PageSize = 40
const ScrollerELId = 'bag-order-scroller'
const Product_recharge = 'RECHAGRE'

class BagOrder extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tabIndex: 0,
            page: 1,
            list: [],
            ready: false,
            showPocketDetail: false,
            pocketDetailId: '',
            showCDKModal: false,
            cdks: [],
            expanded: {},
            showRecycleModal: false,
            selectedList: []
        }
        this.hasMore = false
        this.loadFlag = false
    }

    componentDidMount() {
        this.initOrder(this.state.tabIndex)
    }

    initOrder = tabIndex => {
        getOrderList(1, PageSize, Tabs[tabIndex].value, 2).then(({ pages, records }) => {
            const dataExist = Array.isArray(records) && records.length
            this.setState({
                page: 1,
                list: dataExist ? records : [],
                ready: true,
                expanded: {}
            }, () => {
                this.hasMoreEffect(pages > 1)
                dataExist && (document.getElementById(ScrollerELId).scrollTop = 0)
            })
        }).catch(e => {
            if (e.code !== 401) {
                this.setState({
                    page: 1,
                    list: [],
                    ready: true,
                    expanded: {}
                })
                this.hasMoreEffect(false)
                ee.emit(EVENT_TIP, e.message)
            }
        })
    }

    chooseTab = (tabIndex, action, e) => {
        if (tabIndex !== this.state.tabIndex) {
            this.setState({
                tabIndex
            })
            this.initOrder(tabIndex)
        }
        _sr(action, '个人中心-盲盒订单', `1-${tabIndex + 1}`, '', `order-tab-${tabIndex + 1}`, e)
    }

    hasMoreEffect = hasMore => {
        if (hasMore !== this.hasMore) {
            this.hasMore = hasMore
            const el = document.getElementById(ScrollerELId)
            hasMore
                ? el.addEventListener('scroll', this.scrollListener)
                : el.removeEventListener('scroll', this.scrollListener)
        }
    }

    mergeSubOrders = (list, order) => {
        for (let i = 0; i < list.length; ++i) {
            let current = list[i]
            if (current.orderId === order.orderId) {
                let subs = current.subs.slice()
                let existSubs = subs.map(s => s.subOrderId)
                for (let j = 0; j < order.subs.length; ++j) {
                    let sub = order.subs[j]
                    if (!existSubs.includes(sub.subOrderId)) {
                        subs.push(sub)
                    }
                }
                list[i] = {
                    ...current,
                    subs
                }
                break
            }
        }
    }

    loadMoreData = () => {
        if (!this.hasMore || this.loadFlag) {
            return
        }
        this.loadFlag = true
        const { tabIndex, page, list } = this.state
        getOrderList(page + 1, PageSize, Tabs[tabIndex].value, 2).then(({ pages, records }) => {
            const copyList = list.slice()
            const existOrders = copyList.map(order => order.orderId)
            const brokenOrders = copyList.filter(order => order.openNum !== order.subs.length).map(order => order.orderId)
            for (let i = 0; i < records.length; ++i) {
                let order = records[i]
                if (existOrders.includes(order.orderId)) {
                    if (brokenOrders.includes(order.orderId)) {
                        this.mergeSubOrders(copyList, order)
                    }
                } else {
                    copyList.push(order)
                }
            }
            this.setState({
                page: page + 1,
                list: copyList
            }, () => {
                this.loadFlag = false
            })
            this.hasMoreEffect(pages > page + 1)
        })
    }

    scrollListener = throttle(() => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById(ScrollerELId)
        if (scrollTop + clientHeight >= scrollHeight) {
            this.loadMoreData()
        }
    })

    toggleOrderExpand = orderId => {
        const expanded = { ...this.state.expanded }
        expanded[orderId] = !expanded[orderId]
        this.setState({
            expanded
        })
    }

    showPocketDetail = (pocketDetail, blindboxName, orderIndex, index, e) => {
        const { subOrderId: pocketDetailId, skuGoodsId: goodsId, skuGoodsName: goodsName, skuProductType: productType } = pocketDetail
        this.setState({
            showPocketDetail: true,
            pocketDetailId
        })
        _sr('奖品详情', '个人中心-盲盒订单', `${orderIndex + 1}-${index + 1}`, { blindboxName, goodsId, goodsName, productType }, 'order-check-prize', e)
    }

    closePocketDetail = () => {
        this.setState({
            showPocketDetail: false
        })
    }

    showRecycleModal = selectedList => {
        this.setState({
            showRecycleModal: true,
            selectedList
        })
        this.closePocketDetail()
    }

    closeRecycleModal = () => {
        this.setState({
            showRecycleModal: false
        })
    }

    recycleSubmit = () => {
        this.updateOrderStatus({ status: 6, cdks: [] })
    }

    showCDK = (item, blindboxName, orderIndex, index, e) => {
        const { cdks, skuGoodsId: goodsId, skuGoodsName: goodsName, skuProductType: productType } = item
        this.setState({
            showCDKModal: true,
            cdks
        })
        _sr('查看卡密', '个人中心-盲盒订单', `${orderIndex + 1}-${index + 1}`, { blindboxName, goodsId, goodsName, productType }, 'order-check-cdk', e)
    }

    closeCDK = () => {
        this.setState({
            showCDKModal: false
        })
    }

    updateOrderStatus = ({ status, cdks }) => {
        const { list, pocketDetailId } = this.state
        const copyList = list.slice()
        for (let i = 0; i < copyList.length; ++i) {
            let { subs } = copyList[i]
            for (let j = 0; j < subs.length; ++j) {
                let sub = subs[j]
                if (sub.subOrderId === pocketDetailId) {
                    subs = subs.slice()
                    subs[j] = {
                        ...sub,
                        status,
                        cdks: Array.isArray(cdks) ? cdks : [cdks]
                    }
                    copyList[i] = {
                        ...copyList[i],
                        subs
                    }
                    return this.setState({
                        list: copyList
                    })
                }
            }
        }
    }

    goToOrderDetail = (order, index, e) => {
        const { orderId, goodsId, goodsName } = order
        _sr('订单详情', '个人中心-盲盒订单', `2-${index + 1}`, { goodsId, goodsName }, 'order-detail-btn', e)
        this.props.changeRealIndex(5, orderId)
    }

    contactService = e => {
        getServiceUrl().then(href => {
            window.open(href)
        })
        _sr('客服', '个人中心-盲盒订单', `1-${Tabs.length + 1}`, '', 'order-tab-service', e)
    }

    renderSubOrder = (item, parentName, orderIndex, index) => {
        const { lang } = this.context.user
        return <li className='bag-order-sub'>
            <div className='bag-order-sub-img-wrapper'>
                <img alt='' className='bag-order-sub-img' src={getImgUrl(item.imgUrl)}></img>
            </div>
            <p className='bag-order-product-name'>
                {i18n(item.skuGoodsName, lang)}
                <span className={item.status === 2 ? 'status unchecked' : item.status === 12 || item.status === 3 ? 'status unusual' : 'status'}>{i18n(BlindboxOrderStatusMap[item.status], lang)}</span>
            </p>
            <div className='bag-order-product-sku'>
                {i18n(item.skuName, lang)}
                <span className='num'>x1</span>
            </div>
            <div className='bag-order-sub-footer'>
                {
                    item.status === 11 && item.skuProductType !== Product_recharge && <button className='bag-order-sub-btn cdk' onClick={e => this.showCDK(item, parentName, orderIndex, index, e)}>{i18n('查看卡密', lang)}</button>
                }
                {
                    item.status !== 1 && item.status !== 3 && item.status !== 6 && <button className='bag-order-sub-btn' onClick={e => this.showPocketDetail(item, parentName, orderIndex, index, e)}>{i18n('奖品详情', lang)}</button>
                }
                <p className='bag-order-sub-pay-info'>
                    <span className='battery-label'>{i18n('实付：', lang)}</span>
                    <i className='battery-icon'></i>
                    <span className='battery-amount'>{item.payAmount}</span>
                </p>
            </div>
        </li>
    }

    render() {
        const { tabIndex, list, ready, showPocketDetail, pocketDetailId, expanded, showCDKModal, cdks, showRecycleModal, selectedList } = this.state
        const { lang } = this.context.user
        return <div className='bag-order-container'>
            <div className='bag-order-wrapper'>
                <ul className='bag-content-tabs'>
                    {
                        Tabs.map((tab, index) => (<li className={tabIndex === index ? 'bag-content-tab active' : 'bag-content-tab'} onClick={e => this.chooseTab(index, tab.name, e)}>{i18n(tab.name, lang)}</li>))
                    }
                    <li className='contact-service' onClick={this.contactService}>{i18n('客服', lang)}</li>
                </ul>
                <div className={ready && list.length > 0 ? 'bag-order-window' : 'bag-order-window hidden'} id={ScrollerELId}>
                    <ul className='bag-order-ul'>
                        {
                            list.map((item, index) => (<li className='bag-order-item'>
                                <div className='bag-order-item-header'>
                                    <span>{i18n('订单编号：', lang)}{item.orderId}</span>
                                    <span className='create-time'>{i18n('创建时间：', lang)}{item.createTime}</span>
                                    <button className='detail-btn' onClick={e => this.goToOrderDetail(item, index, e)}>{i18n('订单详情', lang)}</button>
                                </div>
                                <div className='bag-order-parent'>
                                    <div className='bag-order-parent-img-wrapper'>
                                        {
                                            hbsBoolean(item.pcMhImgUrl) && <img alt='' className='bag-order-parent-img' src={getImgUrl(item.pcMhImgUrl)}></img>
                                        }
                                    </div>
                                    <p className='bag-order-product-name'>{i18n(item.goodsName, lang)}</p>
                                    <div className='bag-order-product-sku'>
                                        <span className='num'>x{item.openNum}</span>
                                    </div>
                                    <p className='bag-order-parent-info'>
                                        <span className='align-top'>{i18n('合计：', lang)}{item.payAmount + item.reductionAmount}</span>
                                        <span className='align-top pay-label'>{i18n('实付：', lang)}</span>
                                        <i className='battery-icon'></i>
                                        <span className='pay-amount'>{item.payAmount}</span>
                                    </p>
                                </div>
                                <ul className='bag-order-subs-wrapper'>
                                    {
                                        (expanded[item.orderId] ? item.subs : item.subs.slice(0, 1)).map((s, i) => this.renderSubOrder(s, item.goodsName, index, i))
                                    }
                                </ul>
                                {
                                    item.subs.length > 1 && <div className='bag-order-expand' onClick={() => this.toggleOrderExpand(item.orderId)}>
                                        {
                                            expanded[item.orderId]
                                                ? <span className='expand-status expanded'>{i18n('收起', lang)}</span>
                                                : <span className='expand-status'>{i18n('展开', lang)}</span>
                                        }
                                        <span>{i18n(`共${tabIndex === 0 ? item.openNum : item.subs.length}个商品`, lang)}</span>
                                    </div>
                                }
                            </li>))
                        }
                    </ul>
                </div>
                <div className={ready && list.length === 0 ? 'bag-empty-wrapper pocket' : 'bag-empty-wrapper pocket hidden'}>
                    <i className='empty-icon'></i>
                    <p className='empty-txt'>{i18n('暂无订单~', lang)}</p>
                </div>
            </div>
            {
                showPocketDetail && <PocketDetail close={this.closePocketDetail} pocketDetailId={pocketDetailId} updatePocket={this.updateOrderStatus} goToRecycle={this.showRecycleModal}></PocketDetail>
            }
            {
                showRecycleModal && <Recycle close={this.closeRecycleModal} selectedList={selectedList} submit={this.recycleSubmit}></Recycle>
            }
            {
                showCDKModal && <CDKModal close={this.closeCDK} cdks={cdks}></CDKModal>
            }
        </div>
    }
}

BagOrder.contextType = AppContext
export default BagOrder