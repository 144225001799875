import React from 'react'
import ee, { EVENT_TIP } from '../../common/util/event'
import './index.scss'
import { i18n } from '../../common/util'
import { AppContext } from '../../config/context'

class GTip extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tip: null,
            type: 'error'
        }
        this.timer = null
    }

    componentDidMount() {
        ee.addListener(EVENT_TIP, (message, type = 'error') => {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.timer = null
                this.setState({ tip: null })
            }, 4000)
            this.setState({ tip: message || '系统繁忙，请稍后重试', type })
        })
    }

    render() {
        const { tip, type } = this.state
        const { lang } = this.context.user
        return tip && <div className="g-tip-container">
            <i className={`icon ${type}`}></i>{i18n(tip, lang)}
        </div>
    }
}

GTip.contextType = AppContext
export default GTip