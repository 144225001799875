import { httpPost, getReportParams, httpGet } from '../../common/util'

export const sendMessage = (phone, uuid, captchaCode) => {
    return httpPost({
        url: '/user/login/sendCode',
        data: {
            phone,
            uuid,
            captchaCode
        }
    })
}

export const sendMessage_v2 = (phone, uuid, seccode, challenge, validate) => {
    return httpPost({
        url: '/user/login/sendSmsCode',
        data: {
            phone,
            uuid,
            seccode,
            challenge,
            validate
        }
    })
}

export const loginBySms = (phone, uuid, smsCode) => {
    return httpPost({
        url: '/user/login/smsCodeLogin',
        data: {
            ...getReportParams(),
            phone,
            uuid,
            smsCode
        }
    })
}

export const loginByThird = (token, thirdType) => {
    return httpPost({
        url: '/user/login/thirdLogin',
        data: {
            ...getReportParams(),
            token,
            thirdType
        }
    })
}

export const bindPhone = (phone, code, ucToken, thirdType) => {
    return httpPost({
        url: '/user/login/bindPhone',
        data: {
            ...getReportParams(),
            phone,
            code,
            token: ucToken,
            thirdType
        }
    })
}

export const loginByOpenId = params => {
    return httpPost({
        url: '/user/login/thirdOpenIdLogin',
        dataType: 'json',
        data: {
            ...getReportParams(),
            ...params
        }
    })
}

export const bindPhone_v2 = (phone, uuid, smsCode) => {
    return httpPost({
        url: '/user/bindPhone',
        dataType: 'json',
        data: {
            phone,
            uuid,
            smsCode
        },
        auth: true
    })
}

export const geeTest = () => {
    return httpGet({
        url: '/user/login/gee/register',
        dataType: 'json'
    })
}