import { httpGet } from '../../common/util'

export const getSearchRecommend = () => {
    return httpGet({
        url: '/goods/window/data',
        data: {
            ename: 'search_hot_recommend'
        }
    }).then(data => {
        return (data.content || []).map(i => i.name)
    })
}
