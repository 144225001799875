import React from 'react'
import { AppContext } from '../../config/context'
import { i18n } from '../../common/util'
import { getUserAccount } from '../service'
import BagWallet from './wallet'
import BagPocket from './pocket'
import BagOrder from './order'
import PocketRecycle from './pocketRecycle'
import PocketRecord from './pocketRecord'
import OrderDetail from './orderDetail'
import './index.scss'

const BagNavName = ['我的钱包', '我的背包', '盲盒订单']
const BagRouteMap = {
    0: 0,
    1: 1,
    2: 2,
    3: 1,
    4: 1,
    5: 2
}

class Bag extends React.Component {
    constructor() {
        super(...arguments)
        const index = this.props.index || 0
        const subId = this.props.subId || ''
        this.state = {
            index,
            showIndex: BagRouteMap[index],
            subId
        }
    }

    componentDidMount() {
        getUserAccount().then(ua => {
            this.context.updateUserInfo({
                battery: ua.batteryAmount || 0
            })
        })
    }

    close = () => {
        this.props.close()
    }

    changeIndex = index => {
        this.setState({
            index,
            showIndex: index
        })
    }

    changeIndexFromNav = (index, action, e) => {
        this.changeIndex(index)
        _sr(action, '个人中心-左侧导航', `${index + 1}`, '', `bag-nav-${index + 1}`, e)
    }

    changeIndexFromPocket = (index, action, i, e) => {
        this.changeRealIndex(index)
        _sr(action, '个人中心-我的背包', `1-${i}`, '', `pocket-nav-${i}`, e)
    }

    changeRealIndex = (index, subId = '') => {
        this.setState({
            index,
            showIndex: BagRouteMap[index],
            subId
        })
    }

    render() {
        const { index, showIndex, subId } = this.state
        const { uid, displayName, battery, lang } = this.context.user
        return <div className='bag-modal-wrapper'>
            <div className='bag-modal-container'>
                <div className='bag-modal-nav-wrapper'>
                    <div className='user-info'>
                        <div className='user-avatar-wrapper'></div>
                        <p className='user-nickname'>{displayName}</p>
                        <p className='user-battery'>{battery}</p>
                        <p className='user-id'>ID：{uid}</p>
                    </div>
                    <div className='bag-modal-nav-split'></div>
                    <ul className='bag-nav-ul'>
                        {
                            BagNavName.map((n, i) => (<li className={showIndex === i ? 'bag-nav-item wallet active' : 'bag-nav-item wallet'} onClick={e => this.changeIndexFromNav(i, n, e)}>
                                <p className='bag-nav-name'>{i18n(n, lang)}</p>
                            </li>))
                        }
                    </ul>
                </div>
                <div className='bag-modal-main-wrapper'>
                    <div className='bag-modal-main-header'>
                        {
                            (index === 0 || index === 2) && <p className='bag-modal-main-title'>{i18n(BagNavName[index], lang)}</p>
                        }
                        {
                            (index === 1 || index === 3) && <ul className='bag-pocket-tab-ul'>
                                <li className={index === 1 ? 'bag-pocket-tab active' : 'bag-pocket-tab'} onClick={e => this.changeIndexFromPocket(1, '我的背包', 1, e)}>{i18n('我的背包', lang)}</li>
                                <li className={index === 3 ? 'bag-pocket-tab active' : 'bag-pocket-tab'} onClick={e => this.changeIndexFromPocket(3, '背包明细', 2, e)}>{i18n('背包明细', lang)}</li>
                            </ul>
                        }
                        {
                            index === 4 && <div className='bag-modal-back-wrapper'>
                                <span className='bag-modal-back' onClick={() => this.changeIndex(1)}>{i18n('物品回收', lang)}</span>
                            </div>
                        }
                        {
                            index === 5 && <div className='bag-modal-back-wrapper'>
                                <span className='bag-modal-back' onClick={() => this.changeIndex(2)}>{i18n('订单详情', lang)}</span>
                            </div>
                        }
                        <i className='bag-modal-main-close' onClick={this.close}></i>
                    </div>
                    <div className='bag-modal-content'>
                        {
                            index === 0 && <BagWallet></BagWallet>
                        }
                        {
                            index === 1 && <BagPocket changeRealIndex={this.changeRealIndex}></BagPocket>
                        }
                        {
                            index === 2 && <BagOrder changeRealIndex={this.changeRealIndex}></BagOrder>
                        }
                        {
                            index === 3 && <PocketRecord></PocketRecord>
                        }
                        {
                            index === 4 && <PocketRecycle></PocketRecycle>
                        }
                        {
                            index === 5 && <OrderDetail subId={subId} changeRealIndex={this.changeRealIndex}></OrderDetail>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

Bag.contextType = AppContext

export default Bag